import httpClient from '@api/httpClient'
import type { Order, OrderInfo } from '@models/orders'
import { ordersUrls } from './constants'

const getOrders = async () => {
  const {
    data: { orders },
  } = await httpClient.get<{ orders: Order[] }>(ordersUrls.all)
  return orders
}

const getOrder = async (id: string) => {
  const { data } = await httpClient.get<OrderInfo>(ordersUrls.order(id))
  return data
}

export default { getOrders, getOrder }
