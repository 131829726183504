import { classnames } from '@tools/common'
import { Spinner } from '@uikit/atoms'
import { useRef } from 'react'

export interface PrimaryButtonProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'title' | 'size'
  > {
  title?: string | JSX.Element
  Icon?: JSX.Element
  iconPosition?: 'left' | 'right'
  size?: 'small' | 'middle' | 'large'
  secondary?: boolean
  loading?: boolean
  titleClassName?: string
  className?: string
}

const PrimaryButton = ({
  title,
  Icon,
  iconPosition = 'left',
  size = 'middle',
  secondary,
  loading,
  titleClassName,
  className,
  ...props
}: PrimaryButtonProps) => {
  const btnRef = useRef<HTMLButtonElement>(null)

  return (
    <button
      {...props}
      ref={btnRef}
      disabled={props.disabled || loading}
      className={classnames(
        'relative rounded-medium bg-pink hover:bg-dark-pink active:bg-ultra-dark-pink disabled:bg-grey',
        {
          'py-2.5 px-5': !Icon && size === 'small',
          'px-7 py-4': !Icon && size === 'middle',
          'py-5 px-10': !Icon && size === 'large',
          'p-2.5': !!Icon && size === 'small',
          'p-4': !!Icon && size === 'middle',
          'p-5': !!Icon && size === 'large',
          'bg-ultra-light-grey hover:bg-light-grey active:bg-light-grey disabled:bg-light-grey':
            secondary,
        },
        className,
      )}
    >
      <span className="flex items-center justify-center">
        {!!Icon && iconPosition === 'left' && Icon}

        {title && typeof title === 'string' ? (
          <p
            className={classnames(
              'break-word text-left font-medium text-white',
              {
                'pl-2.5': !!Icon && iconPosition === 'left',
                'pr-2.5': !!Icon && iconPosition === 'right',
                'text-ultra-dark-grey': secondary,
              },
              titleClassName,
            )}
          >
            {title}
          </p>
        ) : (
          <>{title}</>
        )}

        {!!Icon && iconPosition === 'right' && Icon}

        {loading && (
          <Spinner
            mode={secondary ? 'default' : 'light'}
            className={classnames('absolute', {
              'right-5': iconPosition === 'left',
              'left-5': iconPosition === 'right',
            })}
          />
        )}
      </span>
    </button>
  )
}

export default PrimaryButton
