import { useOrder } from '@api'
import { classnames } from '@tools/common'
import { EmojiStatus } from '@uikit/molecules'
import { useModals } from '@uikit/organisms/modals'

export interface ActiveOrderFlightButtonProps {
  orderId: string
  className?: string
}

const ActiveOrderFlightButton = ({
  orderId,
  className,
}: ActiveOrderFlightButtonProps) => {
  const { setModal } = useModals()

  const orderQuery = useOrder(orderId)

  const onClickFlightNotify = () => {
    if (orderQuery.data!.order_id) {
      setModal({ name: 'order', props: { orderId: orderQuery.data!.order_id } })
    }
  }

  if (!orderQuery.isSuccess || !orderQuery.data) {
    return null
  }

  if (orderQuery.data.order_id) {
    return (
      <EmojiStatus
        emojiType={orderQuery.data.status}
        type="status"
        animated
        hideTitle
        className={classnames(
          'fixed bottom-5 left-5 z-30 rounded-full transition duration-700 lg:bottom-10 lg:left-10',
          className,
        )}
        onClick={onClickFlightNotify}
      />
    )
  }

  return (
    <EmojiStatus
      emojiType={orderQuery.data.status}
      type="status"
      animated
      hideTitle
      className="fixed bottom-5 left-5 z-30 rounded-full lg:bottom-10 lg:left-10"
      onClick={onClickFlightNotify}
    />
  )
}

export default ActiveOrderFlightButton
