import { useMapPath } from '@api'
import {
  GoogleMap,
  Marker,
  OverlayView,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api'
import { classnames } from '@tools/common'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'

export interface CourierMapViewProps {
  orderId: string
  mapHeight?: string | number
  mapWith?: string | number
}

const getPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -(height / 2),
})

const CourierMapView = ({
  orderId,
  mapHeight,
  mapWith,
}: CourierMapViewProps) => {
  const { t } = useTranslation()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  })

  const { config, step, direction } = useMapPath(orderId)

  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null)
  const [checkMapBounds, setCheckMapBounds] = useState<boolean>(true)
  const onLoad = useCallback((map: google.maps.Map) => setMapRef(map), [])
  const onUnmount = useCallback(() => {
    setMapRef(null)
  }, [])

  if (!isLoaded || !config) {
    return null
  }

  if (mapRef && checkMapBounds) {
    const mapBounds = google.maps.event.addDomListenerOnce(
      mapRef,
      'tilesloaded',
      () => {
        mapRef?.fitBounds(config.bounds)
        setCheckMapBounds(false)
      },
    )
    if (!checkMapBounds) {
      google.maps.event.removeListener(mapBounds)
    }
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={{
          width: mapWith || '100%',
          height: mapHeight || '70%',
        }}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        center={config.destination}
        clickableIcons={false}
        options={{
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false,
          rotateControl: false,
          streetViewControl: false,
        }}
      >
        <Marker
          icon={{
            path: 'M16 31.6308C16 30.0927 17.1781 28.8365 18.652 28.3968C24.6368 26.6113 29 21.0652 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 21.0652 4.36318 26.6113 10.348 28.3968C11.8219 28.8365 13 30.0927 13 31.6308V58.5C13 59.3284 13.6716 60 14.5 60C15.3284 60 16 59.3284 16 58.5V31.6308Z',
            fillColor: '#FE036A',
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: 0.85,
            anchor: new google.maps.Point(15, 60),
          }}
          position={config.destination}
        />

        <OverlayView
          getPixelPositionOffset={getPixelPositionOffset}
          position={config.path[step]}
          mapPaneName="floatPane"
        >
          <p
            className={classnames('text-2xl', {
              'scale-x-[-1]': direction,
            })}
          >
            🚴
          </p>
        </OverlayView>

        <Polyline
          path={config.path.slice(step, config.stepsCount)}
          options={{
            strokeColor: '#FE036A',
            strokeOpacity: 0.75,
            strokeWeight: 4,
          }}
        />

        <div className="absolute bottom-5 z-10 h-[10%] w-full bg-gradient-to-t from-white" />

        <div className="absolute bottom-0 z-10 h-5 w-full bg-white" />

        <div className="flex justify-center">
          <p className="absolute top-5 z-10 rounded-md bg-white px-3 py-2">
            {t('ui.modals.orders.info.map.delivery_time', {
              time: config.formattedEstimatedTime,
            })}
          </p>
        </div>
      </GoogleMap>
    </>
  )
}

export default CourierMapView
