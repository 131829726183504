import initRemoteConfig from '@api/firebase/remoteConfigClient'
import type { RemoteConfig } from '@models/remoteConfig'
import { fetchAndActivate, getBoolean, getString } from 'firebase/remote-config'
import { remoteConfigKeys } from './constants'

const getRemoteConfigFieldsEnabled = async (): Promise<RemoteConfig> => {
  const remoteConfig = initRemoteConfig()

  await fetchAndActivate(remoteConfig)

  return {
    recommendationsEnabled: getBoolean(
      remoteConfig,
      remoteConfigKeys.recommendationsEnabled,
    ),
    werahousesWithPaymentOnDelivery: JSON.parse(
      getString(remoteConfig, remoteConfigKeys.werahousesWithPaymentOnDelivery),
    ),
    chatSupportEnabled: getBoolean(
      remoteConfig,
      remoteConfigKeys.chatSupportEnabled,
    ),
  }
}

export default { getRemoteConfigFieldsEnabled }
