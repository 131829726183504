import { useCart, useWarehouses } from '@api'
import { useOrder } from '@api/resources/orders'
import { ArrowRightIcon } from '@assets/icons'
import { useAnalytics, useIntercom } from '@hooks'
import { classnames } from '@tools/common'
import { dateFormatter } from '@tools/date'
import {
  Alert,
  Label,
  LabelProps,
  Price,
  PrimaryButton,
  SubtitledButton,
} from '@uikit/molecules'
import { HistoryListProductCard, showToast } from '@uikit/organisms'
import { useModals } from '@uikit/organisms/modals'
import { useTranslation } from 'next-i18next'
import CourierMapView from '../CourierMapView'
import DeliveryStatus from '../DeliveryStatus'

export interface OrderInfoProps {
  orderId: string
}

const InfoSection = ({ orderId }: OrderInfoProps) => {
  const { t } = useTranslation()

  const analytics = useAnalytics()

  const { setModal } = useModals()

  const orderQuery = useOrder(orderId)
  const cartQuery = useCart()
  const warehousesQuery = useWarehouses()

  const {
    enabled: intercomEnabled,
    available: intercomAvailable,
    actions,
  } = useIntercom()

  const onClickBack = () => setModal({ name: 'orders', props: {} })

  const onClickCourier = () => {
    setModal({
      name: 'contactUs',
      props: { courier: orderQuery.data!.courier },
    })
  }

  const onClickContactUs = () => {
    setModal({
      name: 'contactUs',
      props: { warehouseId: orderQuery.data!.warehouse_id },
    })
  }

  const onClickReorder = () => {
    cartQuery.mutations.updateCart.mutate(
      {
        products: orderQuery.data!.products.map(product => ({
          id: product.id,
          count: product.count,
        })),
      },
      {
        onSuccess: () => {
          showToast(t('ui.messages.reorder'), 'success')
          setModal(null)
        },
      },
    )

    analytics.reorderClick()
  }

  const onClickChatWithUs = () => actions.show()

  const orderInfo: LabelProps[] = [
    {
      title: t('ui.modals.orders.info.form.labels.order_number'),
      subtitle: `${orderQuery.data!.ordernumber}`,
    },
    {
      title: t('ui.modals.orders.info.form.labels.time_of_order'),
      subtitle: `${dateFormatter.date(orderQuery.data!.date)} ${t(
        'ui.date.at',
      )} ${dateFormatter.time(orderQuery.data!.date)}`,
    },
    {
      title: t('ui.modals.orders.info.form.labels.payment'),
      subtitle: orderQuery.data!.payment_info
        ? `${orderQuery.data!.payment_info.type} ${
            orderQuery.data!.payment_info.label
          }`
        : t('ui.modals.orders.info.form.labels.on_delivery'),
    },
    {
      title: t('ui.modals.orders.info.form.labels.promocode'),
      subtitle: orderQuery.data!.promocode,
    },
    {
      title: t('ui.modals.orders.info.form.labels.comment'),
      subtitle: orderQuery.data!.comment,
    },
    {
      title: t('ui.modals.orders.info.form.labels.nif'),
      subtitle: orderQuery.data!.nif,
    },
    {
      title: t('ui.modals.orders.info.form.labels.delivery_address'),
      subtitle: orderQuery.data!.address.name,
    },
    {
      title: t('ui.modals.orders.info.form.labels.apt'),
      subtitle: orderQuery.data!.address.apartment,
    },
    {
      title: t('ui.modals.orders.info.form.labels.floor'),
      subtitle: orderQuery.data!.address.floor,
    },
    {
      title: t('ui.modals.orders.info.form.labels.ent'),
      subtitle: orderQuery.data!.address.entrance,
    },
  ]

  return (
    <div>
      <span
        onClick={onClickBack}
        className="mx-5 mb-5 mt-5 flex w-fit cursor-pointer select-none items-center"
      >
        <ArrowRightIcon className="h-3 w-1.5 rotate-180 stroke-ultra-dark-grey stroke-[1.5px]" />

        <p className="ml-3.5">{t('ui.modals.orders.title')}</p>
      </span>

      {orderQuery.data!.status === 'DELIVERING' && (
        <CourierMapView mapHeight="300px" orderId={orderId} />
      )}

      <DeliveryStatus type={orderQuery.data!.status} className="mt-5" />

      {warehousesQuery.data?.warehouseClosed &&
        orderQuery.data?.status !== 'DELIVERED' &&
        orderQuery.data?.status !== 'CANCELED' && (
          <Alert
            title={t('ui.modals.orders.alert.title')}
            description={t('ui.modals.orders.alert.description')}
            className="mx-5"
          />
        )}

      {orderQuery.data!.payment_info?.type === 'MBWAY' &&
      orderQuery.data!.status === 'WAITING_PAYMENT' ? (
        <p className="mt-2.5 text-center text-base font-medium text-pink">
          {t('ui.modals.orders.info.form.labels.accept_mbway')}
        </p>
      ) : null}

      <div className="flex flex-col p-5">
        {orderQuery.data!.status === 'DELIVERING' && (
          <PrimaryButton
            onClick={onClickCourier}
            title={t('ui.modals.orders.info.form.buttons.contact_courier')}
            secondary
          />
        )}

        {(!intercomAvailable || !intercomEnabled) && (
          <PrimaryButton
            onClick={onClickContactUs}
            title={t('ui.modals.orders.info.form.buttons.contact_us')}
            secondary
            className="mt-2.5"
          />
        )}

        <SubtitledButton
          title={t('ui.modals.contact_us.buttons.chat_with_support')}
          subtitle={
            intercomAvailable
              ? undefined
              : t('ui.modals.contact_us.buttons.not_available')
          }
          secondary
          onClick={onClickChatWithUs}
          titleClassName={classnames('font-medium text-center', {
            'text-grey': !intercomAvailable,
          })}
          className="mt-2.5"
        />

        <PrimaryButton
          onClick={onClickReorder}
          title={t('ui.modals.orders.info.form.buttons.repeat_order')}
          secondary
          loading={cartQuery.mutations.updateCart.isLoading}
          className="mt-2.5"
        />
      </div>

      <div className="flex flex-col">
        {orderQuery.data!.products.map(product => (
          <div
            key={product.id}
            className="flex flex-1 border-t border-ultra-light-grey p-5"
          >
            <HistoryListProductCard {...product} />
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center border-y border-ultra-light-grey p-5">
        <p className="mb-1 text-sm font-medium text-ultra-dark-grey">
          {t('ui.modals.orders.info.form.labels.total_with_sale')}
        </p>

        <Price
          size="large"
          value={orderQuery.data!.price.with_sale}
          sale={
            orderQuery.data!.isSale ? orderQuery.data!.price.full : undefined
          }
          valueClassName="text-ultra-dark-grey"
        />
      </div>

      <div className="my-2.5 flex flex-1 flex-col px-5">
        {orderInfo
          .filter(info => info.subtitle)
          .map((info, index, { length }) => (
            <Label
              key={index}
              title={info.title}
              subtitle={info.subtitle}
              titleClassName="text-sm font-normal text-grey"
              subtitleClassName="text-base font-medium text-ultra-dark-grey"
              className={classnames(
                'items-start border-ultra-light-grey py-2.5',
                { 'border-b': index !== length - 1 },
              )}
            />
          ))}
      </div>
    </div>
  )
}

export default InfoSection
