import { classnames } from '@tools/common'

export interface LabelProps {
  title?: string
  secondaryTitle?: string
  subtitle?: string
  onClick?: () => void
  titleClassName?: string
  subtitleClassName?: string
  secondaryTitleClassName?: string
  className?: string
}

const Label = ({
  title,
  secondaryTitle,
  subtitle,
  onClick,
  titleClassName,
  subtitleClassName,
  secondaryTitleClassName,
  className,
}: LabelProps) => {
  return (
    <div
      onClick={onClick}
      className={classnames(
        'flex flex-col',
        { 'cursor-pointer': onClick },
        className,
      )}
    >
      {secondaryTitle ? (
        <span className="flex items-center justify-center">
          <h3
            className={classnames(
              'mr-[5px] font-semibold text-ultra-dark-grey',
              titleClassName,
            )}
          >
            {title}
          </h3>

          <p
            className={classnames(
              'mr-[5px] font-semibold text-grey',
              secondaryTitleClassName,
            )}
          >
            {secondaryTitle}
          </p>
        </span>
      ) : (
        <h3
          className={classnames(
            'font-semibold text-ultra-dark-grey',
            titleClassName,
          )}
        >
          {title}
        </h3>
      )}

      {!!subtitle && (
        <p
          className={classnames(
            'mb-[5px] text-sm font-medium text-grey',
            subtitleClassName,
          )}
        >
          {subtitle}
        </p>
      )}
    </div>
  )
}

export default Label
