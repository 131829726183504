import type { CategorySection } from '@models/categories'
import { classnames } from '@tools/common'
import Image from 'next/image'
import Link from 'next/link'

export interface CategoryCardProps {
  category: CategorySection
  className?: string
}

const CategoryCard = ({
  category: { id, media, name },
  className,
}: CategoryCardProps) => (
  <Link href={`/categories?categoryId=${id}`} shallow>
    <a
      className={classnames(
        'relative overflow-hidden rounded-medium',
        className,
      )}
    >
      <Image
        className="rounded-medium"
        width={250}
        height={250}
        objectFit="contain"
        layout="responsive"
        src={media.medium}
        alt={name}
        loader={({ src }) => src}
        priority
      />

      <div className="absolute inset-2.5 lg:inset-5">
        <h3 className="break-words text-sm font-semibold text-ultra-dark-grey line-clamp-3 lg:text-base lg:line-clamp-2">
          {name}
        </h3>
      </div>
    </a>
  </Link>
)

export default CategoryCard
