import { useCart, useCategories } from '@api'
import type { UseSearchData } from '@api/resources/search/useSearch'
import { ArrowRightIcon } from '@assets/icons'
import { classnames } from '@tools/common'
import { TextIconRow } from '@uikit/molecules'
import { ListProductCard, Loading, ProductCard } from '@uikit/organisms'
import { ProductListSkeleton } from '@uikit/organisms/lists/ProductsList'
import { useModals } from '@uikit/organisms/modals'
import { range } from 'lodash'
import { useContextualRouting } from 'next-use-contextual-routing'
import { useRouter } from 'next/router'
import Skeleton from 'react-loading-skeleton'
import type { UseQueryResult } from 'react-query'
import EmptySearch from '../EmptySearch'

export interface SearchResultProps extends Partial<UseSearchData> {
  type: 'page' | 'modal'
  query: UseQueryResult<UseSearchData>
  onOutsideClick?: () => void
  className?: string
}

const SearchResult = ({
  type,
  query,
  onOutsideClick,
  className,
}: SearchResultProps) => {
  const router = useRouter()

  const { returnHref } = useContextualRouting()

  const { setModal } = useModals()

  const cartQuery = useCart()
  const categoriesQuery = useCategories()

  const onOpenProduct = (productId: string) => {
    setModal({
      name: 'product',
      props: { id: productId },
    })
  }

  const onAdd = (productId: string) => {
    cartQuery.mutations.updateProduct.mutate({
      id: productId,
      action: 'add',
      source: returnHref,
    })
  }

  const onSub = (productId: string) => {
    cartQuery.mutations.updateProduct.mutate({
      id: productId,
      action: 'sub',
    })
  }

  if (query.data?.empty) {
    return (
      <div
        className={classnames({
          'mx-5 mt-10 mb-14': type === 'modal',
          'lg:px-36': type === 'page',
        })}
      >
        <EmptySearch onSuccess={onOutsideClick} />
      </div>
    )
  }

  if (query.isIdle && type === 'modal') {
    return null
  }

  const onClickCategory = (categoryId: string) => {
    if (onOutsideClick) {
      onOutsideClick()
    }

    router.push(
      categoriesQuery.data?.categoriesMap.get(categoryId)?.parent_id
        ? `/categories?categoryId=${
            categoriesQuery.data?.categoriesMap.get(categoryId)?.parent_id
          }&subcategoryId=${categoryId}`
        : `/categories?categoryId=${categoryId}`,
      undefined,
      { shallow: true },
    )
  }

  return (
    <div className={className}>
      <ul className={classnames({ 'm-5': type !== 'page' })}>
        {type === 'modal' ? (
          <li>
            {query.data?.categories.map(category => (
              <TextIconRow
                onClick={() => onClickCategory(category.id)}
                title={category.name}
                icon={
                  <ArrowRightIcon className="h-4 w-4 fill-transparent stroke-ultra-dark-grey" />
                }
                className={classnames(
                  'mb-1 rounded-lg p-2 last:mb-0 hover:bg-light-grey',
                )}
              />
            ))}
          </li>
        ) : (
          <>
            {query.data?.categories.map(category => (
              <TextIconRow
                onClick={() => onClickCategory(category.id)}
                title={category.name}
                icon={
                  <ArrowRightIcon className="h-4 w-4 fill-transparent stroke-ultra-dark-grey" />
                }
                className="mb-1 rounded-lg p-2 last:mb-5 hover:bg-light-grey"
              />
            ))}
          </>
        )}
      </ul>

      {type === 'modal' && (
        <Loading
          type="query"
          query={cartQuery}
          loader="skeleton"
          Skeleton={<SearchResultsPageSkeleton />}
        >
          {cartQuery => (
            <Loading
              type="query"
              query={query}
              loader="skeleton"
              Skeleton={<SearchResultsModalSkeleton />}
            >
              {query => (
                <div
                  className={classnames({
                    'mx-5 my-5': query.data.products.length! > 0,
                  })}
                >
                  {query.data.products?.map((product, index, { length }) => (
                    <ListProductCard
                      key={product.id}
                      product={product}
                      cartProduct={cartQuery.data.productsMap.get(product.id)}
                      lastProduct={index === length - 1}
                      onAdd={onAdd}
                      onSub={onSub}
                      className="mb-3"
                    />
                  ))}
                </div>
              )}
            </Loading>
          )}
        </Loading>
      )}

      {type === 'page' && (
        <Loading
          type="query"
          query={cartQuery}
          loader="skeleton"
          Skeleton={<SearchResultsPageSkeleton />}
        >
          {cartQuery => (
            <Loading
              type="query"
              query={query}
              loader="skeleton"
              Skeleton={<SearchResultsPageSkeleton />}
            >
              {query => (
                <div className="grid gap-2.5 pb-2.5 xs:grid-cols-2 xm:grid-cols-3 sm:grid-cols-4 sm:gap-5 sm:pb-5 lg:grid-cols-5">
                  {query.data.products.map(product => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      cartProduct={cartQuery.data.productsMap.get(product.id)}
                      onAdd={onAdd}
                      onSub={onSub}
                      onOpenProduct={onOpenProduct}
                    />
                  ))}
                </div>
              )}
            </Loading>
          )}
        </Loading>
      )}
    </div>
  )
}

export default SearchResult

export const SearchResultsPageSkeleton = () => {
  return (
    <>
      {range(3).map((n, _, { length }) => (
        <Skeleton
          key={n}
          inline
          className={classnames('mb-2.5 h-5 rounded-medium lg:mb-4', {
            'mb-0 lg:mb-0': n === length - 1,
          })}
        />
      ))}

      <ProductListSkeleton
        type="page"
        numOfProductsRows={3}
        className="mt-5"
        titleClassName="hidden"
      />
    </>
  )
}

export const SearchResultsModalSkeleton = () => {
  return (
    <div className="px-5">
      <Skeleton inline className="mb-3 h-5 w-full rounded-medium " count={3} />

      <div className="mb-5 flex h-16 w-full">
        <Skeleton inline className="mr-5 h-16 w-16 rounded-medium" />

        <div className="flex w-full flex-col justify-between">
          <Skeleton inline className="h-5 w-full rounded-medium" />

          <div className="flex justify-between">
            <Skeleton inline className="h-5 w-[60px] rounded-medium" />

            <Skeleton inline className="h-5 w-[80px] rounded-medium" />
          </div>
        </div>
      </div>

      <div className="mb-5 flex h-16 w-full">
        <Skeleton inline className="mr-5 h-16 w-16 rounded-medium" />

        <div className="flex w-full flex-col justify-between">
          <Skeleton inline className="h-5 w-full rounded-medium" />

          <div className="flex justify-between">
            <Skeleton inline className="h-5 w-[60px] rounded-medium" />

            <Skeleton inline className="h-5 w-[80px] rounded-medium" />
          </div>
        </div>
      </div>
    </div>
  )
}
