import { useUser } from '@api'
import { useModals } from '@uikit/organisms/modals'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const usePrivateRoute = (from: string) => {
  const { setModal } = useModals()

  const router = useRouter()

  const userQuery = useUser()

  const onCloseModal = () => {
    router.replace('/')
  }

  const onSuccessModal = () => {
    router.push(from)
  }

  useEffect(() => {
    if (userQuery.isSuccess && userQuery.data!.anonymous) {
      setModal({
        name: 'login',
        props: {
          onClose: onCloseModal,
          onSuccess: onSuccessModal,
        },
      })
    }
  }, [userQuery.data])
}

export default usePrivateRoute
