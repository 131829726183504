import { OutOfWorkIllustration } from '@assets/illustrations'
import { useTranslation } from 'next-i18next'
import type { BaseModalProps } from '../components/BaseModal'
import BaseModal from '../components/BaseModal'

export interface OutOfWorkModalProps extends BaseModalProps {}

const OutOfWorkModal = ({ header, visible, onClose }: OutOfWorkModalProps) => {
  const { t } = useTranslation()

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.out_of_work.title'),
          description: t('ui.modals.out_of_work.description'),
        },
      }}
    >
      <div className="flex flex-col items-center p-10">
        <OutOfWorkIllustration />

        <p className="pt-10 text-base font-semibold text-ultra-dark-grey">
          {t('ui.modals.out_of_work.header')}
        </p>

        <p className="pt-2.5 pb-5 text-xs font-medium text-ultra-dark-grey">
          {t('ui.modals.out_of_work.header_description')}
        </p>

        <p className="text-base font-bold text-pink">
          {t('ui.modals.out_of_work.link')}
        </p>
      </div>
    </BaseModal>
  )
}

export default OutOfWorkModal
