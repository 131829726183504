import { useCart } from '@api'
import { classnames } from '@tools/common'
import { Loading } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Skeleton from 'react-loading-skeleton'

export interface CartTitleProps {
  hideTitle?: boolean
}

const CartTitle = ({ hideTitle }: CartTitleProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const cartQuery = useCart()

  if (hideTitle) {
    return null
  }

  return (
    <Loading
      type="query"
      loader="skeleton"
      query={cartQuery}
      Skeleton={<TitleSkeleton />}
    >
      <div className="bg-white py-2.5 text-lg font-semibold text-ultra-dark-grey lg:py-5 lg:text-2xl lg:font-bold">
        <h2 className="hidden lg:block">{t('pages.cart.title')}</h2>

        <h2 className="lg:hidden">
          {router.pathname.includes('checkout')
            ? t('pages.cart.checkout')
            : t('pages.cart.title')}
        </h2>
      </div>
    </Loading>
  )
}

interface TitleSkeletonProps {
  className?: string
}

export const TitleSkeleton = ({ className }: TitleSkeletonProps) => (
  <Skeleton
    inline
    className={classnames('my-5 h-7 w-1/3 rounded-medium', className)}
  />
)

export default CartTitle
