import { ArrowDownIcon } from '@assets/icons'
import { Disclosure as BaseDisclosure, Transition } from '@headlessui/react'
import { classnames } from '@tools/common'
import type { FC } from 'react'

const DisclosureAnimation: FC<{ open: boolean }> = ({ children, open }) => {
  return (
    <Transition
      show={open}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      {children}
    </Transition>
  )
}

interface DisclosureProps {
  title: string
  subtitle: string
  className?: string
}

const Disclosure = ({ title, subtitle, className }: DisclosureProps) => {
  return (
    <BaseDisclosure>
      {({ open }) => (
        <div className={className}>
          <BaseDisclosure.Button className="flex w-full justify-between p-5">
            <p className="text-left text-base font-normal text-ultra-dark-grey">
              {title}
            </p>

            <ArrowDownIcon
              className={classnames(
                'ml-4 h-4 w-4 transform stroke-ultra-dark-grey stroke-[1.5px] transition duration-300 lg:h-5 lg:w-5',
                { 'rotate-180': open },
              )}
            />
          </BaseDisclosure.Button>

          <DisclosureAnimation open={open}>
            <BaseDisclosure.Panel className="p-5">
              <p className="text-sm font-normal text-grey">{subtitle}</p>
            </BaseDisclosure.Panel>
          </DisclosureAnimation>
        </div>
      )}
    </BaseDisclosure>
  )
}

export default Disclosure
