import type { CategorySection } from '@models/categories'
import { classnames } from '@tools/common'
import { useRouter } from 'next/router'

interface CategoryItemProps {
  category: CategorySection
  active?: boolean
  itemId: string
}

const CategoryItem = ({
  category: { name, id },
  active,
}: CategoryItemProps) => {
  const router = useRouter()

  const onCategoryClick = () => {
    router.push(`/categories?categoryId=${id}`, undefined, {
      shallow: true,
    })
  }

  return (
    <div
      role="button"
      className={classnames(
        'truncate border-b-[2px] border-b-transparent py-3',
        { 'border-b-pink': active },
      )}
      onClick={onCategoryClick}
    >
      <p className="text-sm font-medium text-ultra-dark-grey">{name}</p>
    </div>
  )
}

export default CategoryItem
