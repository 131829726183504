import type { TFunction } from 'next-i18next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import type { QueryObserverSuccessResult, UseQueryResult } from 'react-query'

export const safeMetadata = <TData, TError>(
  query: UseQueryResult<TData, TError>,
  props: (query: QueryObserverSuccessResult<TData, TError>) => MetadataProps,
  t: TFunction,
) => {
  if (query.isSuccess) {
    return props(query)
  }

  const defaultMetadataLoading: MetadataProps = {
    title: t('ui.messages.loading'),
    description: t('ui.messages.loading'),
  }

  return defaultMetadataLoading
}

export interface MetadataProps {
  title?: string
  description?: string
  image?: string
  alt?: string
  url?: string
}

const Metadata = ({ title, description, image, url, alt }: MetadataProps) => {
  const router = useRouter()

  return (
    <Head>
      <title>{title}</title>

      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:site_name" content="Bairro" />
      <meta property="og:locale" content={router.locale} />
      {url && <meta property="og:url" content={url} />}

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {image && (
        <>
          <meta property="og:image" content={image || '/bairro-logo'} />
          <meta property="og:image:alt" content={alt || 'Bairro logo'} />

          <meta content="500" property="og:image:width" />
          <meta content="500" property="og:image:height" />
        </>
      )}

      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />

      {image && (
        <>
          <meta property="twitter:card" content={image || '/bairro-logo'} />
          <meta property="twitter:image" content={image || '/bairro-logo'} />
        </>
      )}
    </Head>
  )
}

export default Metadata
