import { productsKeys } from '@api'
import httpClient from '@api/httpClient'
import { queryClient } from '@api/queryClient'
import type { Coordinates } from '@models/map'
import type { SearchResult } from '@models/search'
import { searchUrls } from './constants'

const getSearchResults = async (text: string, coords?: Coordinates) => {
  const {
    data: { data },
  } = await httpClient.get<{ data: SearchResult }>(searchUrls.root(), {
    params: { text, ...coords },
  })

  for (const product of data.products) {
    queryClient.setQueryData(productsKeys.root(product.id, coords), product)
  }

  return data
}

export default { getSearchResults }
