import { MOBILE_HEADER_HEIGHT } from '@constants/layout'
import type { Category } from '@models/categories'
import { useContext } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Link as ScrollLink } from 'react-scroll'

interface CategoryItemProps {
  category: Category
  itemId: string
}

const SubcategoryItem = ({ category: { id, name } }: CategoryItemProps) => {
  const item = useContext(VisibilityContext)

  const onSetActive = () => {
    item.scrollToItem(item.getItemById(id), 'smooth', 'center')
  }

  return (
    <ScrollLink
      to={id}
      duration={1000}
      spy
      offset={-MOBILE_HEADER_HEIGHT}
      activeClass="active-mob"
      onSetActive={onSetActive}
      className="block rounded-3xl"
    >
      <div className="cursor-pointer truncate rounded-3xl py-2.5 px-2.5 text-sm font-medium">
        <p>{name}</p>
      </div>
    </ScrollLink>
  )
}

export default SubcategoryItem
