import { useSearch } from '@api'
import { CloseIcon, SearchIcon } from '@assets/icons'
import { useAnalytics } from '@hooks'
import { classnames } from '@tools/common'
import { useModals } from '@uikit/organisms/modals'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import type { Dispatch, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDebounce } from 'use-debounce'
import SearchResult from '../SearchResult'

export interface SearchInputProps {
  searchableOpen: boolean
  setSearchableOpen: Dispatch<SetStateAction<boolean>>
  className?: string
  containerClassName?: string
}

export interface SearchQueryField {
  query: string
}

const SearchInput = ({
  searchableOpen,
  setSearchableOpen,
  className,
}: SearchInputProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const { modal } = useModals()

  const { register, watch, handleSubmit, resetField, setFocus } =
    useForm<SearchQueryField>({ defaultValues: { query: '' } })

  const query = watch('query')

  const [debouncedSearch] = useDebounce(query, 500)

  const searchQuery = useSearch(debouncedSearch)

  useAnalytics([searchQuery], [], analytics =>
    analytics.searchAvailableResults({ query: debouncedSearch }),
  )

  const onClickSearch = handleSubmit(({ query }) => {
    setSearchableOpen(true)

    setFocus('query')

    if (!searchQuery.isIdle && searchableOpen) {
      resetField('query')

      router.push(`/search?query=${query}`, undefined, { shallow: true })
    }
  })

  const onFocus = () => setSearchableOpen(true)

  const onOutsideClick = () => {
    if (modal?.name !== 'product') {
      setSearchableOpen(false)
    }
  }

  const onClickRemove = () => {
    resetField('query')

    setFocus('query')
  }

  const onClickRemoveMobile = () => {
    resetField('query')

    onOutsideClick()
  }

  return (
    <div
      className={classnames('w-[58px] lg:w-[30%]', {
        'w-full': searchableOpen,
        'w-[58px]': !searchableOpen,
      })}
    >
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <div className={classnames('relative', className)}>
          <div
            className={classnames(
              {
                'rounded-medium border-transparent lg:bg-ultra-light-grey lg:hover:border-light-grey':
                  !searchableOpen,
                'rounded-medium': searchableOpen && debouncedSearch.length < 3,
                'rounded-t-medium':
                  searchableOpen && debouncedSearch.length > 2,
              },
              'active:border-ultra-light-grey active:bg-white lg:focus-within:shadow-2xl',
              'text-description leading-description relative w-full cursor-default overflow-hidden border border-ultra-light-grey',
            )}
          >
            <div className="flex flex-1 items-center">
              <span
                className="mx-0 rounded-medium p-5 lg:mx-5 lg:mr-0 lg:p-0"
                onClick={onClickSearch}
              >
                <SearchIcon className="h-4 w-4 cursor-pointer fill-ultra-dark-grey stroke-ultra-dark-grey stroke-0" />
              </span>

              <form
                id="search-form"
                className="flex flex-1"
                onSubmit={onClickSearch}
              >
                <input
                  {...register('query')}
                  onFocus={onFocus}
                  placeholder={t('ui.search.placeholder')}
                  autoComplete="off"
                  role="presentation"
                  className="font-regular form-input w-full border-0 bg-transparent p-0 py-[16px] text-ultra-dark-grey placeholder:text-grey focus:overflow-hidden focus:outline-none focus:ring-0 lg:px-5"
                />
              </form>

              <span
                className={classnames('mx-5 hidden lg:block', {
                  'lg:hidden': query.length === 0,
                })}
                onClick={onClickRemove}
              >
                <CloseIcon className="h-3 w-3 cursor-pointer fill-ultra-dark-grey stroke-ultra-dark-grey stroke-0" />
              </span>

              {searchableOpen && (
                <>
                  <span className="p-5 lg:hidden" onClick={onClickRemove}>
                    <CloseIcon className="h-3 w-3 cursor-pointer fill-ultra-dark-grey stroke-ultra-dark-grey stroke-0" />
                  </span>

                  <button
                    onClick={onClickRemoveMobile}
                    className="border-l border-ultra-light-grey py-4 px-5 text-grey lg:hidden"
                  >
                    {t('ui.search.cancel')}
                  </button>
                </>
              )}
            </div>
          </div>

          {searchableOpen && debouncedSearch.length > 2 && (
            <ul className="absolute z-10 max-h-[80vh] w-full overflow-y-scroll overscroll-contain rounded-b-medium border border-t-0 border-ultra-light-grey bg-white shadow-lg focus:outline-none">
              <SearchResult
                type="modal"
                query={searchQuery}
                onOutsideClick={onOutsideClick}
                className="overflow-hidden"
              />
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default SearchInput
