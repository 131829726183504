import { classnames } from '@tools/common'
import type { MouseEvent } from 'react'
import { useController } from 'react-hook-form'
import BaseField, { BaseFieldProps } from '../BaseField'

export interface InlineOptionsSelectorProps extends BaseFieldProps {
  options: string[]
}

const InlineOptionsSelector = ({
  name,
  control,
  label,
  options,
}: InlineOptionsSelectorProps) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  })

  return (
    <BaseField label={label}>
      <div className="flex w-[560px] sm:w-full">
        {options.map((option, index) => {
          const selected = value?.includes(option)

          const onChangeItem = (event: MouseEvent<HTMLButtonElement, any>) => {
            event.preventDefault()

            if (selected) {
              onChange(value.filter((item: string) => item !== option))
              return
            }

            onChange([option])
          }

          return (
            <button
              key={option}
              onClick={onChangeItem}
              className={classnames(
                'group flex flex-1 cursor-pointer justify-center rounded-xsmall py-2.5 transition duration-500',
                selected ? 'bg-pink' : 'bg-ultra-light-grey lg:bg-white',
                { 'ml-2': index !== 0 },
              )}
            >
              <p
                className={classnames(
                  'text-base font-medium transition duration-500',
                  selected ? 'text-white' : 'text-ultra-dark-grey',
                )}
              >
                {option}
              </p>
            </button>
          )
        })}
      </div>
    </BaseField>
  )
}

export default InlineOptionsSelector
