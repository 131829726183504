import type { Argument } from 'classnames'
import classnamesBase from 'classnames'
import type { NextRouter } from 'next/router'
import { overrideTailwindClasses } from 'tailwind-override'
export { default as ms } from 'ms'

export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>

export const getBaseUrl = (router: NextRouter) =>
  typeof window === 'undefined'
    ? ''
    : `${process.env.NEXT_PUBLIC_PROTOCOL}://${window?.location?.host}/${router.locale}`

export const getBaseUrlForBots = (router: NextRouter) =>
  `${process.env.NEXT_SITE_URL}/${router.locale}${router.asPath}`

export const range = (n: number) => [...Array.from({ length: n }).keys()]

export const capitalizeFirstLetter = <T extends string = string>(
  string: string,
): T => {
  return (string.charAt(0).toUpperCase() + string.slice(1)) as T
}

export const toFixed = (value?: number, fractionDigits = 2) => {
  if (typeof value !== 'number') {
    return ''
  }

  if (Number.isInteger(value)) {
    return value
  }

  return value.toFixed(fractionDigits)
}

export const successField = (data: string, regexp: RegExp) => {
  if (data) {
    const value = data.match(regexp)

    if (value?.length === 1) {
      return value[0].length > 0
    }
  }

  return false
}

export const clearText = (text: string) =>
  text.replace(/[\s!#$%&()*,./:;=^_`{}~-]/g, '')

export const keyToUrl = (args: any): string => args.filter(Boolean).join('/')

export const classnames = (...args: Argument[]) =>
  overrideTailwindClasses(classnamesBase(...args))
