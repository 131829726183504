import { useUser, useWarehouses } from '@api'
import { ArrowUpIcon, LocationIcon } from '@assets/icons'
import { BairroIllustration } from '@assets/illustrations'
import { useAnalytics } from '@hooks'
import { classnames } from '@tools/common'
import { getCartVisible } from '@tools/layout'
import {
  Label,
  PrimaryButton,
  SubtitledButton,
  Tooltip,
} from '@uikit/molecules'
import {
  CartPopover,
  DropdownAddress,
  DropdownMenu,
  SearchInput,
} from '@uikit/organisms'
import Loading from '@uikit/organisms/Loading'
import { Modal, useModals } from '@uikit/organisms/modals'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { getMenuItems } from './configs'

export interface PageHeaderProps {
  className?: string
}

const PageHeader = ({ className }: PageHeaderProps) => {
  const { t } = useTranslation()

  const analytics = useAnalytics()

  const router = useRouter()

  const { setModal } = useModals()

  const userQuery = useUser()
  const warehousesQuery = useWarehouses()

  const [selectedAddress, setSelectedAddress] = useState(false)
  const [searchableOpen, setSearchableOpen] = useState(false)

  useEffect(() => {
    if (userQuery.data?.selectedAddress) {
      setSelectedAddress(true)
    }
  }, [userQuery.data?.selectedAddress])

  const onClickLogin = () => setModal({ name: 'login', props: {} })

  const onClickAddress = () => {
    if (userQuery.data?.addresses.length === 0) {
      setModal({ name: 'selectAddress', props: {} })
    } else {
      setModal({ name: 'addresses', props: {} })
    }
  }

  const onConfirmSignOut = () => {
    analytics.userLogout({ userId: userQuery.data?.id })
    userQuery.mutations.logout.mutate(undefined)
    router.push('/')
  }

  const onClickDropdownItem = (name: Modal['name'] | 'signOut') => {
    if (name === 'signOut') {
      setModal({
        name: 'confirm',
        props: {
          onConfirm: onConfirmSignOut,
        },
      })
    }

    if (name !== 'signOut') {
      // @ts-ignore
      setModal({ name, props: {} })
    }
  }

  const onClickBack = () => router.push('/')

  const onHideTooltip = () => setSelectedAddress(false)

  const onClickDeliveryTerms = () => {
    setModal({ name: 'deliveryTerms', props: {} })
  }

  return (
    <header
      className={classnames(
        'z-40 w-full border-b border-ultra-light-grey bg-white py-2.5 lg:py-5',
        className,
      )}
    >
      <Loading
        type="query"
        query={warehousesQuery}
        loader="skeleton"
        Skeleton={<PageHeaderSkeleton />}
      >
        <div className="flex h-[58px] w-full px-2.5 lg:px-0">
          <Link href="/">
            <a className="mr-5 hidden lg:flex">
              <BairroIllustration className="h-full w-[12vw]" />
            </a>
          </Link>

          <PrimaryButton
            secondary
            Icon={<ArrowUpIcon className="-rotate-90 fill-ultra-dark-grey" />}
            onClick={onClickBack}
            className={classnames(
              'mr-2.5 border border-ultra-light-grey bg-white p-5 hover:bg-ultra-light-grey lg:hidden',
              { hidden: router.pathname === '/' || searchableOpen },
            )}
          />

          <DropdownMenu
            items={getMenuItems(t, userQuery.data?.anonymous)}
            className={classnames('mr-2.5 lg:mr-5', {
              'mr-5 hidden lg:flex': searchableOpen,
            })}
            onClickItem={(name: string) =>
              onClickDropdownItem(name as Modal['name'] | 'signOut')
            }
          />

          <SearchInput
            searchableOpen={searchableOpen}
            setSearchableOpen={setSearchableOpen}
          />

          <Tooltip
            visible={selectedAddress}
            text={t('ui.modals.select_address.tooltip.deliver')}
            onHideTooltip={onHideTooltip}
            containerClassName={classnames('ml-2.5 lg:ml-5 flex flex-1', {
              'hidden lg:inline': searchableOpen,
            })}
            tooltipClassName="top-full left-1/2 -translate-x-1/2 lg:top-12 w-fit"
          >
            {userQuery.data?.selectedAddress?.name ? (
              <DropdownAddress className="lg:hidden" />
            ) : (
              <Label
                {...{
                  title: t('layout.header.addressButton'),
                  onClick: onClickAddress,
                  className:
                    'truncate w-full h-full items-center justify-center rounded-medium border border-ultra-light-grey text-center lg:hidden max-h-[58px]',
                }}
              />
            )}

            <PrimaryButton
              onClick={onClickAddress}
              title={
                userQuery.data?.addresses.length === 0 ||
                !userQuery.data?.selectedAddress?.name
                  ? t('layout.header.addressButton')
                  : userQuery.data?.selectedAddress.name
              }
              size="small"
              Icon={<LocationIcon className="h-4 w-4 stroke-ultra-dark-grey" />}
              iconPosition="left"
              className="hidden h-full w-full overflow-hidden rounded-medium border border-ultra-light-grey bg-white hover:bg-ultra-light-grey active:bg-ultra-light-grey lg:inline"
              titleClassName="font-semibold text-ultra-dark-grey ml-0 max-w-[calc(100%-1em)] line-clamp-1"
            />
          </Tooltip>

          {!getCartVisible(router.pathname) && (
            <CartPopover
              containerClassName="hidden lg:flex ml-5"
              className="shadow-none"
            />
          )}

          {userQuery.data?.selectedAddress && (
            <SubtitledButton
              title={warehousesQuery.data?.deliveryTime!}
              subtitle={warehousesQuery.data?.deliveryFeePrice}
              onClick={onClickDeliveryTerms}
              className="ml-5 hidden w-fit self-center px-2.5 py-1 lg:inline"
            />
          )}

          {userQuery.data?.anonymous && (
            <PrimaryButton
              title={t('layout.header.loginButton')}
              secondary
              onClick={onClickLogin}
              titleClassName="font-semibold"
              className="ml-5 hidden lg:flex"
            />
          )}
        </div>
      </Loading>
    </header>
  )
}

const PageHeaderSkeleton = () => {
  const router = useRouter()

  const { modal } = useModals()

  return (
    <div className="relative bottom-1 flex h-[58px] w-full items-center px-2.5 lg:px-0">
      <Skeleton
        className="mr-2.5 h-full w-full rounded-medium"
        containerClassName="hidden h-full lg:mr-5 lg:inline lg:w-[38%]"
      />

      <Skeleton
        className="h-full w-[58px] rounded-medium "
        containerClassName="h-full mr-2.5 lg:mr-5"
      />

      <Skeleton
        className="h-full w-full rounded-medium"
        containerClassName="h-full lg:mr-5 hidden lg:inline lg:w-full "
      />

      <Skeleton
        className="h-full w-[58px] rounded-medium"
        containerClassName="h-full mr-2.5 lg:hidden"
      />

      <Skeleton
        className="h-full w-[58px] rounded-medium"
        containerClassName={classnames('h-full mr-2.5 lg:hidden', {
          hidden: router.asPath === '/' || modal?.name,
        })}
      />

      <Skeleton
        className="h-full rounded-medium"
        containerClassName="h-full lg:mr-5 w-full lg:w-[93%]"
      />

      <Skeleton
        className="h-full w-full rounded-medium"
        containerClassName="w-[21%] h-full hidden lg:inline"
      />
    </div>
  )
}

export default PageHeader
