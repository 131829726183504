import { getLanguage } from '@i18n'
import { Metadata } from '@uikit/organisms'
import ErrorComp from 'next/error'

const InternalServerError = () => {
  return (
    <>
      <Metadata
        title={
          getLanguage() === 'en'
            ? 'Internal server error'
            : 'Erro interno do servidor'
        }
        description={
          getLanguage() === 'en'
            ? 'Internal server error'
            : 'Erro interno do servidor'
        }
      />

      <ErrorComp statusCode={500} />
    </>
  )
}
export default InternalServerError
