import { classnames } from '@tools/common'

export interface CircleButtonProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'title' | 'size'
  > {
  className?: string
  Icon?: JSX.Element
}

const CircleButton = ({ className, Icon, ...props }: CircleButtonProps) => {
  return (
    <button
      className={classnames(
        'flex h-8 w-8 items-center justify-center rounded-full bg-pink hover:bg-dark-pink active:bg-ultra-dark-pink disabled:bg-grey',
        className,
      )}
      {...props}
    >
      {Icon}
    </button>
  )
}

export default CircleButton
