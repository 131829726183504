import { v1 } from '@constants/api'
import { keyToUrl } from '@tools/common'

export const faqKeys = {
  root: [v1, 'faq'] as const,
}

export const faqUrls = {
  root: keyToUrl(faqKeys.root),
}
