import type { OrderInfo } from '@models/orders'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'
interface UseOrderData extends OrderInfo {
  isSale: boolean
}

const useOrder = (id: string) =>
  useQuery<OrderInfo, AxiosError, UseOrderData>(
    ordersKeys.order(id),
    () => ordersApi.getOrder(id),
    {
      refetchInterval: data => {
        if (data && data.status === 'WAITING_PAYMENT') {
          return ms('10s')
        }

        return false
      },
    },
  )

export default useOrder
