import { useAddresses, useAddressPredictions, useFeeback } from '@api'
import { useWarehouses } from '@api/resources/warehouses'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAnalytics } from '@hooks'
import type { Coordinates, MarkerData } from '@models/map'
import { classnames } from '@tools/common'
import { Input, PrimaryButton } from '@uikit/molecules'
import { Toast } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import getValidationScheme from './validation'

interface SearchAddressForm {
  address: string
  email?: string
}

interface SearchAddressProps {
  addressAvailable: boolean
  setAddressAvailable: Dispatch<SetStateAction<boolean>>
  marker: MarkerData | null
  setMarker: Dispatch<SetStateAction<MarkerData | null>>
  className?: string
  panMapTo: (coordinate: Coordinates) => void
  onClose: () => void
}

const SearchAddress = ({
  className,
  addressAvailable,
  marker,
  onClose,
  setMarker,
  setAddressAvailable,
  panMapTo,
}: SearchAddressProps) => {
  const { t } = useTranslation()

  const analytics = useAnalytics()

  const addressesQuery = useAddresses()

  const feedbackQuery = useFeeback()

  const warehousesQuery = useWarehouses()

  const [predictionInput, setPredictionInput] = useState<string>('')

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    resetField,
    formState: { isValid },
  } = useForm<SearchAddressForm>({
    defaultValues: { address: marker?.name, email: '' },
    mode: 'onChange',
    resolver: addressAvailable
      ? undefined
      : zodResolver(getValidationScheme(t)),
  })

  const {
    predictions,
    tools: { getAddressDataById },
  } = useAddressPredictions(marker ? '' : predictionInput)

  const onCreateAddress = handleSubmit(async () => {
    await addressesQuery.mutations.createAddress.mutateAsync({
      name: marker!.name,
      fullName: marker!.fullName,
      lat: marker!.point.lat,
      long: marker!.point.long,
    })

    analytics.userAddressChange({
      address: marker!.fullName,
      latitude: marker!.point.lat,
      longitude: marker!.point.long,
    })
    onClose()
  })

  useEffect(() => {
    if (marker) {
      setValue('address', marker.name)
    }
  }, [marker])

  useEffect(() => {
    const watchAddress = watch('address')
    if (marker && marker.name !== watchAddress) {
      setMarker(null)
      setAddressAvailable(true)
    }
    setPredictionInput(watchAddress)
  }, [watch('address'), marker])

  const onClickSendEmail = handleSubmit(async data => {
    await feedbackQuery.mutations.sendZoneFeedback.mutateAsync({
      email: data.email!,
      coords: marker!.point,
    })

    resetField('email')

    toast.custom(toast => (
      <Toast
        toast={toast}
        title={t('ui.search.missing_zone_toast_title')}
        variant="success"
      />
    ))

    onClose()
  })

  const selectAddress = (
    address: google.maps.places.AutocompletePrediction,
  ) => {
    getAddressDataById(address.place_id, data => {
      const newMarker: MarkerData = {
        name: `${
          (data.address_components[1]?.short_name &&
            data.address_components[1].short_name) ??
          ''
        } ${
          (data.address_components[0]?.short_name &&
            data.address_components[0].short_name) ??
          ''
        }`,
        fullName: data?.formatted_address ?? '',
        point: {
          lat: data.geometry.location.lat(),
          long: data.geometry.location.lng(),
        },
      }

      setAddressAvailable(
        warehousesQuery.tools.pointInPoligon({
          lat: data.geometry.location.lat(),
          long: data.geometry.location.lng(),
        }),
      )
      setMarker(newMarker)
      panMapTo(newMarker.point)
      setPredictionInput('')
      setValue('address', newMarker.name)
    })
  }

  return (
    <div
      className={classnames(
        'flex flex-col rounded-medium bg-white p-4',
        {
          '-translate-y-12 transition-transform md:translate-y-0':
            predictionInput && !marker,
        },
        className,
      )}
    >
      <p className="mb-2.5 text-base font-bold text-ultra-dark-grey">
        {t('ui.modals.select_address.header')}
      </p>

      <form id="address-form" onSubmit={onCreateAddress}>
        <Input
          name="address"
          control={control}
          placeholder={t('ui.modals.select_address.inputs.address')}
          required
          inputClassName="p-4"
          className="mb-4 rounded-xsmall"
        />
      </form>

      {addressAvailable ? (
        predictionInput &&
        predictionInput.length > 0 &&
        predictions.length > 0 ? (
          <div className="flex flex-col overflow-scroll">
            {predictions.map(el => (
              <div
                key={el.place_id}
                className="cursor-pointer border-b border-ultra-light-grey px-4 py-2 last:border-none last:py-0 last:pt-2"
                onClick={() => {
                  selectAddress(el)
                }}
              >
                <p className="break-normal text-base text-ultra-dark-grey">
                  {el.structured_formatting.main_text}
                </p>

                <p className="break-normal text-xs text-grey">
                  {el.structured_formatting.secondary_text}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <>
            <PrimaryButton
              type="submit"
              form="address-form"
              onClick={onCreateAddress}
              title={t('ui.modals.select_address.buttons.choose_address')}
              className="w-full"
              size="middle"
              disabled={
                addressesQuery.mutations.createAddress.isLoading || !marker
              }
            />
          </>
        )
      ) : (
        <div className="flex flex-col">
          <p className="mb-4 break-normal text-center text-lg font-bold text-ultra-dark-grey">
            {t('ui.modals.select_address.titles.invalid_address')}
          </p>

          <form id="address-email-form">
            <Input
              name="email"
              control={control}
              placeholder={t('ui.modals.select_address.inputs.email')}
              inputClassName="p-4"
              className="rounded-xsmall"
            />

            <PrimaryButton
              type="submit"
              onClick={onClickSendEmail}
              disabled={
                !isValid ||
                (isValid && feedbackQuery.mutations.sendZoneFeedback.isLoading)
              }
              loading={feedbackQuery.mutations.sendZoneFeedback.isLoading}
              title={t('ui.modals.select_address.buttons.send_email')}
              size="middle"
              className={classnames('mt-5 w-full duration-150 ease-out', {
                'pl-5 pr-10 duration-150 ease-in':
                  feedbackQuery.mutations.sendZoneFeedback.isLoading,
              })}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default SearchAddress
