import { v1 } from '@constants/api'
import type { Coordinates } from '@models/map'
import { keyToUrl } from '@tools/common'

export const offersKeys = {
  all: (coords?: Coordinates) => [v1, 'offers', coords ?? null] as const,
  offer: (id: string, coords?: Coordinates) =>
    [...offersKeys.all(coords), id] as const,
}

export const offersUrls = {
  all: keyToUrl(offersKeys.all()),
  offer: (id: string) => keyToUrl(offersKeys.offer(id)),
}
