import {
  AccountIcon,
  CartIcon,
  InfoIcon,
  PhoneIcon,
  SignOutIcon,
  TicketIcon,
} from '@assets/icons'
import type { DropdownMenuItemProps } from '@uikit/organisms'
import type { TFunction } from 'next-i18next'

export const getMenuItems = (t: TFunction, anonymous?: boolean) => {
  const ANONYMOUS_DROPDOWN_MENU_ITEMS: Readonly<DropdownMenuItemProps[]> = [
    {
      icon: (
        <PhoneIcon className="h-4 w-4 fill-transparent stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
      id: 'contactUs',
      title: t('layout.header.menu.contactUs'),
    },
    {
      icon: (
        <InfoIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
      id: 'faq',
      title: t('layout.header.menu.faq'),
    },
    {
      icon: (
        <SignOutIcon className="h-4 w-4 rotate-180 stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
      id: 'login',
      title: t('layout.header.menu.signIn'),
    },
  ]

  const DROPDOWN_MENU_ITEMS: Readonly<DropdownMenuItemProps[]> = [
    {
      icon: (
        <AccountIcon className="h-4 w-4 fill-transparent stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
      id: 'account',
      title: t('layout.header.menu.account'),
    },
    {
      icon: (
        <CartIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
      id: 'orders',
      title: t('layout.header.menu.orders'),
    },
    {
      icon: (
        <TicketIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
      id: 'promo',
      title: t('layout.header.menu.promoCode'),
    },
    {
      icon: (
        <PhoneIcon className="h-4 w-4 fill-transparent stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
      id: 'contactUs',
      title: t('layout.header.menu.contactUs'),
    },
    {
      icon: (
        <InfoIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
      id: 'faq',
      title: t('layout.header.menu.faq'),
    },
    {
      icon: (
        <SignOutIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
      id: 'signOut',
      title: t('layout.header.menu.signOut'),
    },
  ]

  if (anonymous) {
    return ANONYMOUS_DROPDOWN_MENU_ITEMS
  }

  return DROPDOWN_MENU_ITEMS
}
