import {
  categoriesKeys,
  selectCategoriesData,
  selectUserData,
  usersKeys,
} from '@api'
import { queryClient } from '@api/queryClient'
import type { CartAction } from '@models/cart'
import type { Category } from '@models/categories'
import type { Product } from '@models/products'
import type { User } from '@models/user'
import * as Sentry from '@sentry/nextjs'
import { PROD_URL } from './constants'
import type { AlogliaProduct, ExternalLink, StringBoolean } from './models'

export const getRouteSourceByPath = (path?: string) => {
  if (!path) {
    return ''
  }

  if (path.includes('/categories')) {
    return 'Subcategory'
  }

  if (path.includes('/search')) {
    return 'Search'
  }
}

const CART_ACTION_TO_NAME: Readonly<Record<CartAction, string>> = {
  add: 'Increment',
  sub: 'Decrement',
  delete: 'Delist',
}

export const getCartActionName = (action: CartAction) =>
  CART_ACTION_TO_NAME[action]

const EXTERNAL_LINK_TO_NAME: Readonly<Record<ExternalLink, string>> = {
  delivery: 'Delivery zones',
  instagram: 'Instagram',
  facebook: 'Facebook',
  app_store: 'App Store',
  google_play: 'Google Play',
}

export const getExternalLinkName = (link: ExternalLink) =>
  EXTERNAL_LINK_TO_NAME[link]

export const generateAwinUrl = ({
  amount,
  order_id,
}: {
  amount: number
  order_id: string
}) => {
  return `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=27526&amount=${amount}&cr=EUR&ref=${order_id}&parts=DEFAULT:${amount}&vc=&ch=aw&testmode=0`
}

export const getYesNo = (value?: any): StringBoolean => (value ? 'Yes' : 'No')

const trackAvailable = () => location.host === PROD_URL

const loggerMiddleware = (name: string, props: any) =>
  console.log(`🍒 ${name} 🍒 \n ${JSON.stringify(props, null, 2)}`)

export const track = <T extends object>(
  name: string,
  props: T,
  integrations?: ('Facebook Pixel' | 'Mixpanel' | 'Algolia')[],
) => {
  console.log('integrations', integrations)
  loggerMiddleware(name, props)

  if (!trackAvailable()) {
    return
  }

  window.gtag('event', name, {
    event_label: name,
  })
  // analytics.track(name, props, {
  //   integrations: {
  //     'Facebook Pixel': integrations?.includes('Facebook Pixel') ?? false,
  //     mixpanel: integrations?.includes('Mixpanel') ?? false,
  //     algolia: integrations?.includes('Algolia') ?? false,
  //   },
  //   context: {
  //     app: {
  //       vERSION,
  //     },
  //     os: {
  //       name: OS_NAME,
  //     },
  //   },
  // })
}

export const getAlgoliaProducts = (categoryProducts: Product[][]) => {
  const user = selectUserData(queryClient.getQueryData<User>(usersKeys.root)!)

  const categories = selectCategoriesData(
    queryClient.getQueryData<Category[]>(categoriesKeys.all(user.coords))!,
  )

  return categoryProducts.flatMap<AlogliaProduct>(products =>
    products.map(product => {
      const categoryName = categories.categoriesMap.get(
        product.categoryId!,
      )!.name

      return {
        product_id: product.id,
        objectID: product.id,
        sku: product.EAN,
        category: categoryName,
        name: product.name,
        brand: product.brand,
        price: product.price.withsale,
        quantity: product.stock,
        image_url: product.image[0],
      }
    }),
  )
}

export const handleError = <T extends (...args: any) => any>(fn: T): T => {
  // @ts-ignore
  return (props: Parameters<T>[0]) => {
    try {
      return fn(props)
    } catch (error) {
      // const exception =
      //   error.error || error.message || error.originalError || error
      if (error instanceof Error) {
        Sentry.captureException(error.message)
      } else {
        Sentry.captureException(error)
      }
    }
  }
}
