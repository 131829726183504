import { classnames } from '@tools/common'

export interface AlertProps {
  title: string
  description: string
  className: string
  titleClassName?: string
}

const Alert = ({
  title,
  description,
  className,
  titleClassName,
}: AlertProps) => {
  return (
    <div
      className={classnames(
        'rounded-medium border border-pink bg-white',
        className,
      )}
    >
      <div className="flex items-center p-5">
        <div className="flex flex-col text-base text-ultra-dark-grey">
          <p className={classnames('pb-2.5 font-medium', titleClassName)}>
            {title}
          </p>
          <p className="font-medium">{description}</p>
        </div>
      </div>
    </div>
  )
}

export default Alert
