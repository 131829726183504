import type { Coordinates, ZoneType } from '@models/map'
import type { Warehouse } from '@models/warehouses'
import { useDebounceCallback } from '@react-hook/debounce'
import { isPointInPolygon } from 'geolib'
import { useEffect } from 'react'
import Geocode from 'react-geocode'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

const DEFAULT_DEBOUNCE_INTERVAL = 500

const getPlaceByCoordinates = async (coordinates: Coordinates) => {
  const query = await Geocode.fromLatLng(
    coordinates.lat.toString(),
    coordinates.long.toString(),
    process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  )

  if (query.results.length > 0) {
    const result = query.results[0]

    const res = {
      name: `${result.address_components[1]?.short_name ?? ''} ${
        result.address_components[0]?.short_name ?? ''
      }`,
      fullName: result.formatted_address ?? '',
    }

    return res
  }
  return {
    name: ``,
    fullName: ``,
  }
}

export const isPointOnWarehousePolygon = (
  coords: Coordinates,
  warehouse?: Warehouse,
): ZoneType | undefined => {
  let response: ZoneType | undefined

  if (warehouse?.zones.custom) {
    const isOnPoint = isPointInPolygon(
      { longitude: coords.long, latitude: coords.lat },
      warehouse.zones.custom.map(point => ({
        latitude: point.lat,
        longitude: point.lng,
      })),
    )

    if (isOnPoint) {
      response = 'CUSTOM'
    }
  }

  if (warehouse?.zones.large) {
    const isOnPoint = isPointInPolygon(
      { longitude: coords.long, latitude: coords.lat },
      warehouse.zones.large.map(point => ({
        latitude: point.lat,
        longitude: point.lng,
      })),
    )

    if (isOnPoint) {
      response = 'LARGE'
    }
  }

  if (warehouse?.zones.small) {
    const isOnPoint = isPointInPolygon(
      { longitude: coords.long, latitude: coords.lat },
      warehouse.zones.small.map(point => ({
        latitude: point.lat,
        longitude: point.lng,
      })),
    )

    if (isOnPoint) {
      response = 'SMALL'
    }
  }

  return response
}

const useAddressPredictions = (text?: string, debounce?: number) => {
  const {
    placePredictions,
    getPlacePredictions,
    placesService,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  })

  const lisbonLatLng = new google.maps.LatLng(38.736946, -9.142685)

  const debouncePredictions = useDebounceCallback(
    async (text: string) => {
      getPlacePredictions({
        input: text,
        location: lisbonLatLng,
        radius: 50000,
        componentRestrictions: { country: 'pt' },
      })
    },
    debounce ?? DEFAULT_DEBOUNCE_INTERVAL,
    false,
  )

  const getPredictionByString = (text: string) => {
    return debouncePredictions(text)
  }

  const getAddressDataById = (
    placeId: string,
    callback: (placeDetails: any) => void,
  ) => {
    if (placesService) {
      placesService.getDetails({ placeId }, callback)
    }
  }

  useEffect(() => {
    getPredictionByString(text || '')
  }, [text])

  return {
    predictions: placePredictions,
    loading: isPlacePredictionsLoading,
    tools: {
      getPlacePredictions,
      getPlaceByCoordinates,
      isPointOnWarehousePolygon,
      getAddressDataById,
    },
  }
}

export default useAddressPredictions
