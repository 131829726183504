import { useDatetime, usePayments } from '@api'
import { zodResolver } from '@hookform/resolvers/zod'
import { getBaseUrl } from '@tools/common'
import { Input, PrimaryButton } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import {
  CREDITCARD_REGEX,
  EXPIRE_DATE_REGEX,
  fourNumbersMask as CVV_REGEX,
} from '../CheckoutForm/validation'
import getValidationScheme from './validation'

interface CardFormValues {
  number: string
  expireMonthAndYear: string
  cvv: string
}

const CardForm = () => {
  const { t } = useTranslation()

  const router = useRouter()

  const {
    mutations: { createCard },
  } = usePayments('create-card-form')

  const datetimeQuery = useDatetime()

  const {
    control,
    handleSubmit,
    setError,
    setFocus,
    watch,
    formState: { isValid },
  } = useForm<CardFormValues>({
    mode: 'onChange',
    resolver: zodResolver(getValidationScheme(t)),
    defaultValues: {
      cvv: '',
    },
  })

  const number = watch('number')
  const expireMonthAndYear = watch('expireMonthAndYear')

  useEffect(() => {
    if (number && number.length === 23) {
      setFocus('expireMonthAndYear')
    }
  }, [setFocus, number])

  useEffect(() => {
    if (expireMonthAndYear && expireMonthAndYear.length === 7) {
      setFocus('cvv')
    }
  }, [setFocus, expireMonthAndYear])

  const onSubmit = handleSubmit(async data => {
    await datetimeQuery.refetch()

    const cardData = {
      number: data.number.replaceAll(' ', '').replaceAll('_', ''),
      expiryMonth: data.expireMonthAndYear.slice(0, 2),
      expiryYear: `20${data.expireMonthAndYear.slice(2, 4)}`,
      cvc: data.cvv,
      generationtime: datetimeQuery.data!.now,
    }
    // @ts-ignore
    const cseInstance = adyen.encrypt.createEncryption(
      process.env.NEXT_PUBLIC_ADYEN_ENCRYPT_TOKEN,
      {},
    )

    const encryptCardValidation = Object.entries(cseInstance.validate(cardData))

    if (encryptCardValidation.some(([_, value]) => !value)) {
      for (const [fieldName, value] of encryptCardValidation) {
        if ((fieldName === 'month' || fieldName === 'year') && !value) {
          setError('expireMonthAndYear', {
            message: t('pages.checkout.errors.valid_date'),
          })
          return
        }
        if (fieldName === 'number' && !value) {
          setError('number', { message: t('pages.checkout.errors.card_data') })
          return
        }
      }
    }

    const encryptCardData = cseInstance.encrypt(cardData)

    if (!encryptCardData) {
      toast.error(JSON.stringify(t('pages.checkout.errors.card_data'), null, 2))
      setError('number', { message: t('pages.checkout.errors.card_data') })
      return
    }

    createCard.mutate(
      {
        cardNumber: encryptCardData,
        cvv: encryptCardData,
        expMonth: encryptCardData,
        expYear: encryptCardData,
        label: `**** ${cardData.number.slice(-4)}`,
        redirectUrl: `${getBaseUrl(router)}${router.pathname}`,
      },
      {
        onSuccess: data => {
          if (data.error) {
            setError('number', {
              message:
                data.error.resultCode ?? t('pages.checkout.errors.card_data'),
            })
          }
        },
      },
    )
  })

  return (
    <div className="mt-2.5">
      <form id="create-card-form">
        <div className="border-l-ultra-light-grey sm:flex">
          <div className="w-full sm:w-6/12 xl:w-7/12">
            <Input
              name="number"
              control={control}
              placeholder={t('pages.checkout.placeholders.cartNumber')}
              mask={CREDITCARD_REGEX}
              className="rounded-0 rounded-xsmall border-0 bg-ultra-light-grey sm:rounded-l-xsmall sm:rounded-r-none lg:bg-white"
            />
          </div>
          <div className="flex sm:w-6/12 xl:w-5/12">
            <div className="mt-4 mr-2.5 w-4/12 min-w-[110px] border-light-grey sm:mt-0 sm:mr-0 sm:w-7/12 sm:border-l lg:min-w-0 lg:border-ultra-light-grey">
              <Input
                name="expireMonthAndYear"
                control={control}
                placeholder={t(
                  'pages.checkout.placeholders.cartExpireMonthAndYear',
                )}
                mask={EXPIRE_DATE_REGEX}
                className="rounded-0 rounded-xsmall border-0 bg-ultra-light-grey sm:rounded-r-none sm:rounded-l-none lg:bg-white"
              />
            </div>

            <div className="mt-4 w-3/12 min-w-[80px] sm:mt-0 sm:w-5/12 lg:min-w-0">
              <Input
                name="cvv"
                control={control}
                mask={CVV_REGEX}
                placeholder={t('pages.checkout.placeholders.cartCvv')}
                className="rounded-0 rounded-xsmall border-0 border-l-light-grey bg-ultra-light-grey sm:rounded-l-none sm:border-l lg:border-l-ultra-light-grey lg:bg-white"
              />
            </div>
          </div>
        </div>
      </form>

      <PrimaryButton
        type="submit"
        form="create-card-form"
        title={
          <p className="break-all text-left text-white line-clamp-1">
            {t('pages.checkout.buttons.payment_method')}
          </p>
        }
        iconPosition="right"
        disabled={!isValid}
        loading={createCard.isLoading}
        onClick={onSubmit}
        className="mt-12 w-full bg-pink disabled:bg-light-pink lg:mt-10"
      />
    </div>
  )
}

export default CardForm
