import { Transition } from '@headlessui/react'
import { classnames } from '@tools/common'
import { FC, useEffect, useState } from 'react'
import { useTimer } from 'use-timer'

export interface TooltipProps {
  visible: boolean
  text?: string | JSX.Element
  duration?: number
  noTimeout?: boolean
  onHideTooltip?: () => void
  arrowPosition?: 'top' | 'bottom' | 'left' | 'right'
  lightMode?: boolean
  className?: string
  containerClassName?: string
  tooltipClassName?: string
  tooltipContentClassName?: string
  arrowClassName?: string
}

export interface TooltipAnimationProps {
  show?: boolean
}

export const TooltipAnimation: FC<TooltipAnimationProps> = ({
  children,
  show,
}) => (
  <Transition
    show={show}
    enter="ease-out duration-150"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
)

const Tooltip: FC<TooltipProps> = ({
  visible: initialVisible,
  text,
  children,
  duration = 10,
  noTimeout,
  onHideTooltip: onBaseHideTooltip,
  arrowPosition = 'top',
  lightMode,
  className,
  containerClassName,
  tooltipClassName,
  tooltipContentClassName,
  arrowClassName,
}) => {
  const [visible, setVisible] = useState(initialVisible)

  const { start, pause, reset } = useTimer({
    initialTime: duration,
    timerType: 'DECREMENTAL',
    endTime: 0,
    onTimeOver: () => {
      onHideTooltip()
    },
  })

  const onHideTooltip = () => {
    setVisible(false)

    if (onBaseHideTooltip) {
      onBaseHideTooltip()
    }
  }

  useEffect(() => {
    if (initialVisible && noTimeout) {
      setVisible(true)

      pause()
      reset()
    } else if (initialVisible) {
      setVisible(true)

      start()
    } else {
      setVisible(false)

      pause()
      reset()
    }
  }, [initialVisible, noTimeout])

  const BaseTooltip = () => (
    <div
      className={classnames('absolute z-10', tooltipClassName)}
      onClick={onHideTooltip}
    >
      <div
        className={classnames(
          'absolute block h-3 w-3 rotate-45 bg-ultra-dark-grey',
          {
            '-top-1.5 left-1/2 -translate-x-1/2 rounded-tl-sm':
              arrowPosition === 'top',
            'top-1/2 -right-1.5 -translate-y-1/2 rounded-tr-sm':
              arrowPosition === 'right',
            '-bottom-1.5 left-1/2 -translate-x-1/2 rounded-br-sm':
              arrowPosition === 'bottom',
            'top-1/2 -left-1.5 -translate-y-1/2 rounded-bl-sm':
              arrowPosition === 'left',
            'bg-white': lightMode,
          },
          arrowClassName,
        )}
      />

      <div
        className={classnames(
          'relative break-words rounded-medium bg-ultra-dark-grey px-5 py-2.5 text-xs font-medium text-white lg:text-sm',
          {
            'bg-white': lightMode,
          },
          tooltipContentClassName,
        )}
      >
        <p className={classnames({ 'text-ultra-dark-grey': lightMode })}>
          {text}
        </p>
      </div>
    </div>
  )

  return (
    <div className={classnames('relative', containerClassName)}>
      <div className={classnames('h-full w-full', className)}>{children}</div>

      <TooltipAnimation show={visible}>
        <BaseTooltip />
      </TooltipAnimation>
    </div>
  )
}

export default Tooltip
