import { EmptyCartIllustration } from '@assets/illustrations'
import { TextButton } from '@uikit/molecules'
import { useModals } from '@uikit/organisms/modals'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

const EmptyCart = () => {
  const { t } = useTranslation()

  const router = useRouter()

  const { setModal } = useModals()

  const onClickSales = () => {
    router.push('/sales')

    setModal(null)
  }

  return (
    <div className="flex flex-col items-center p-5">
      <EmptyCartIllustration className="h-24 w-32" />

      <p className="mt-5 mb-2.5 text-base font-semibold text-ultra-dark-grey">
        {t('pages.cart.empty.title')}
      </p>

      <p className="mb-5 text-xs font-medium text-ultra-dark-grey">
        {t('pages.cart.empty.subtitle')}
      </p>

      <TextButton
        title={t('pages.cart.empty.buttonTitle')}
        onClick={onClickSales}
        className="font-bold hover:bg-transparent"
      />
    </div>
  )
}

export default EmptyCart
