import { getMaxCount } from '@api/resources/cart/tools'
import { CloseIcon } from '@assets/icons'
import type { CartProduct } from '@models/cart'
import type { Product } from '@models/products'
import { InlineAmountSelector, Label, Price, Tooltip } from '@uikit/molecules'
import { useModals } from '@uikit/organisms/modals'
import classnames from 'classnames'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { memo, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

export interface ListProductCardProps {
  product: Product
  cartProduct?: CartProduct
  onAdd: (id: string) => void
  onSub: (id: string) => void
  onDelete?: (id: string) => void
  className?: string
  lastProduct?: boolean
}

const ListProductCard = ({
  cartProduct,
  product,
  onAdd,
  onSub,
  onDelete,
  className,
  lastProduct,
}: ListProductCardProps) => {
  const { t } = useTranslation()

  const { setModal } = useModals()

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

  const onClickCard = () => {
    setModal({ name: 'product', props: { id: product.id } })
  }

  const getSale = () => {
    if (cartProduct && cartProduct.cartPrice.discount) {
      return cartProduct.cartPrice.original
    }

    if (product && product.price.sale) {
      return product.price.full
    }
  }

  useEffect(() => {
    const maxCount = getMaxCount(product, cartProduct)
    setTooltipVisible(typeof maxCount === 'number' ? maxCount > 0 : false)
  }, [cartProduct?.count, cartProduct?.deficit])

  const getTooltipText = () => {
    if (cartProduct?.deficit && maxCount !== cartProduct.count) {
      return t('product.deficit', {
        stock: maxCount,
        count: cartProduct.count,
      })
    }

    return t('product.deficit_only', { deficit: maxCount })
  }

  const onHideTooltip = () => setTooltipVisible(false)

  const maxCount = getMaxCount(product, cartProduct)

  if (!product) {
    return null
  }

  return (
    <li
      className={classnames(
        'flex',
        {
          'opacity-50': cartProduct?.deficit === 0,
          'pb-7': lastProduct && tooltipVisible,
        },

        className,
      )}
    >
      <Image
        src={product.media.small}
        height={64}
        width={64}
        objectFit="contain"
        className="cursor-pointer rounded-xsmall bg-image-grey"
        loader={({ src }) => src}
        onClick={onClickCard}
      />

      <div className="h-16 w-[calc(100%-64px)] pl-2.5">
        <Tooltip
          visible={tooltipVisible}
          onHideTooltip={onHideTooltip}
          text={getTooltipText()}
          containerClassName="h-full"
          tooltipClassName="-bottom-11"
          className="flex flex-col justify-between"
        >
          <span className="cursor-pointer" onClick={onClickCard}>
            <Label
              title={product.name}
              titleClassName={classnames('font-medium line-clamp-2 max-h-8', {
                'pr-3': onDelete,
              })}
              className="text-xs"
            />
          </span>

          {cartProduct?.deficit === 0 ? (
            <p className="text-sm font-bold text-ultra-dark-grey">
              {t('product.out_of_stock')}
            </p>
          ) : (
            <div className="flex items-end justify-between lg:items-center">
              <Price
                value={
                  cartProduct
                    ? cartProduct.cartPrice.with_discount
                    : product.price.withsale
                }
                sale={getSale()}
                size="small"
                valueClassName="text-ultra-dark-grey"
                className="text-ultra-dark-grey"
              />

              <InlineAmountSelector
                max={maxCount}
                value={cartProduct?.count ?? 0}
                onAdd={() => onAdd(product.id)}
                onSub={() => onSub(product.id)}
                className="w-20"
              />
            </div>
          )}

          {onDelete && (
            <div className="absolute top-[-0.5rem] -right-2">
              <button onClick={() => onDelete(product.id)} className="p-2">
                <CloseIcon className="h-2 w-2 fill-ultra-dark-grey stroke-ultra-dark-grey stroke-[0.5px]" />
              </button>
            </div>
          )}
        </Tooltip>
      </div>
    </li>
  )
}

interface DefaultListProductCardSkeletonProps {
  className?: string
}

export const DefaultListProductCardSkeleton = ({
  className,
}: DefaultListProductCardSkeletonProps) => (
  <div className={className}>
    <div className="flex justify-around">
      <Skeleton inline height={50} width={50} className="rounded-medium" />
      <div className="w-full">
        <div className="ml-2.5">
          <Skeleton inline count={2} className="rounded-medium" />
        </div>

        <div className="ml-2.5 flex justify-between">
          <Skeleton inline className="rounded-medium" width={60} />
          <Skeleton inline className="rounded-medium" width={80} />
        </div>
      </div>
    </div>
  </div>
)

export default memo(
  ListProductCard,
  (prev, next) =>
    prev.cartProduct?.count === next.cartProduct?.count &&
    prev.cartProduct?.deficit === next.cartProduct?.deficit &&
    prev.cartProduct?.stock === next.cartProduct?.stock,
)
