import { useCart, useUser } from '@api'
import type { Promocode } from '@models/cart'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { cartApi } from '.'
import { cartKeys } from './constants'

export interface UsePromocodesData {
  promocodes: Promocode[]
  promocodesMap: Map<string, Promocode>
  activePromocode?: Promocode
  isEmpty: boolean
}

const usePromocodes = () => {
  const cartQuery = useCart()
  const userQuery = useUser()

  const selectData = (data: Promocode[]): UsePromocodesData => {
    const activePromocode = cartQuery.data!.cart.promo?.code

    const promocodes = activePromocode
      ? [
          data.find(promocode => promocode.code === activePromocode)!,
          ...data.filter(promocode => promocode.code !== activePromocode),
        ].filter(Boolean)
      : data

    return {
      promocodes,
      promocodesMap: new Map(data.map(promocode => [promocode.id, promocode])),
      activePromocode: data.find(
        promocode => promocode.code === activePromocode,
      ),
      isEmpty: promocodes.length === 0,
    }
  }

  const query = useQuery<Promocode[], AxiosError, UsePromocodesData>(
    cartKeys.promocode(userQuery.data?.id),
    cartApi.getActivePromocodes,
    {
      staleTime: ms('20m'),
      enabled:
        userQuery.isSuccess && cartQuery.isSuccess && !userQuery.data.anonymous,
      select: selectData,
    },
  )

  return query
}

export default usePromocodes
