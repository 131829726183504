import { classnames } from '@tools/common'

export interface SaleTagProps {
  title: string
  size: 'small' | 'medium' | 'large'
  className?: string
}

const SaleTag = ({ title, size, className }: SaleTagProps) => {
  return (
    <div
      className={classnames(
        'z-10 select-none bg-pink',
        {
          'rounded-sm py-0.5 px-1.5': size === 'small',
          'rounded-br-medium rounded-tl-medium py-[5px] px-2.5':
            size === 'medium',
          'rounded-br-medium rounded-tl-medium py-2.5 px-5': size === 'large',
        },
        className,
      )}
    >
      <p
        className={classnames('text-white', {
          'text-xs font-medium': size === 'small',
          'text-xs font-bold lg:text-sm': size === 'medium',
          'text-base font-bold': size === 'large',
        })}
      >
        {title}
      </p>
    </div>
  )
}

export default SaleTag
