import { v1 } from '@constants/api'
import type { Coordinates } from '@models/map'
import { keyToUrl } from '@tools/common'

export const salesKeys = {
  root: (coords?: Coordinates) => [v1, 'sales', coords] as const,
}

export const salesUrls = {
  root: keyToUrl(salesKeys.root()),
}
