import AdyenCheckout from '@adyen/adyen-web'
import { getRestrictions, showDeficitToaster } from '@api/resources/cart/tools'
import { CART_RECOMMENDATIONS } from '@constants/source'
import { ACTIVE_ORDER_ID_KEY } from '@constants/storage'
import { useAnalytics } from '@hooks'
import type {
  Cart,
  CartInfoProduct,
  CartProduct,
  Checkout,
  CheckoutProps,
  Restrictions,
  UpdateCartProps,
  UpdateProductProps,
} from '@models/cart'
import type { Product } from '@models/products'
import type { User } from '@models/user'
import { ms } from '@tools/common'
import { useModals } from '@uikit/organisms/modals'
import type { AxiosError } from 'axios'
import * as ls from 'local-storage'
import { useTranslation } from 'next-i18next'
import { useContextualRouting } from 'next-use-contextual-routing'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { productsKeys } from '../products'
import { salesKeys } from '../sales'
import { selectUserData, usersKeys } from '../users'
import cartApi from './actions'
import {
  cartKeys,
  DEFICIT_ERROR_MESSAGE,
  MAX_PRODUCT_COUNT,
  MAX_PRODUCT_COUNT_ERROR_MESSAGE,
  NO_SELECTED_ADDRESS_ERROR_MESSAGE,
} from './constants'

export interface UseCartData extends Cart {
  products: CartProduct[]
  productsMap: Map<string, CartProduct>
  productIds: string[]
  isEmpty: boolean
  isDeficit: boolean
  productRestrictionsMap: Map<string, number>
  productsWithDeficit: CartProduct[]
  filteredProducts: Pick<CartProduct, 'id' | 'count'>[]
  checkoutAvailable: boolean
  productsCount: number
}

export const selectData = ({ cart, restrictions }: Cart): UseCartData => {
  let isDeficit = false
  const { productRestrictionsMap } = getRestrictions(restrictions)

  const products = cart.products
    .map<CartProduct>(product => {
      const productRestriction = productRestrictionsMap.get(product.id)

      const deficit =
        productRestriction !== undefined && productRestriction <= product.count
          ? productRestriction
          : undefined

      if (deficit !== undefined && deficit < product.count) {
        isDeficit = true
      }

      return { ...product, deficit }
    })
    .sort(({ deficit }) => (typeof deficit === 'number' ? 0 : -1))

  const productsMap = new Map(products.map(product => [product.id, product]))

  const productsWithDeficit = products.filter(
    product => product.deficit !== undefined,
  )

  const filteredProducts = cart.products.map(({ id, count }) => ({
    id,
    count: productRestrictionsMap.get(id) ?? count,
  }))

  const isEmpty = cart.products.length === 0

  const productsCount = cart.products.reduce((acc, { count }) => acc + count, 0)

  const checkoutAvailable = !cart.products.every(
    product => productsMap.get(product.id)?.deficit === 0,
  )

  const productIds = cart.products.map(item => item.id)

  return {
    products,
    productsMap,
    productsCount,
    cart,
    restrictions,
    isEmpty,
    productIds,
    isDeficit,
    productsWithDeficit,
    productRestrictionsMap,
    filteredProducts,
    checkoutAvailable,
  }
}

const useCart = (componentId?: string) => {
  const { t } = useTranslation()

  const analytics = useAnalytics()

  const router = useRouter()

  const queryClient = useQueryClient()

  const { returnHref } = useContextualRouting()

  const { setModal } = useModals()

  const query = useQuery<Cart, AxiosError, UseCartData>(
    cartKeys.root,
    ({ signal }) => cartApi.getCart(signal),
    {
      staleTime: ms('5m'),
      select: selectData,
    },
  )

  useEffect(() => {
    if (query.data?.isDeficit) {
      setModal({ name: 'deficit', props: {} })
    }
  }, [query.data?.isDeficit])

  const updateProduct = useMutation<
    Cart,
    AxiosError<{ restrictions: Restrictions }>,
    UpdateProductProps,
    { previousCart?: Cart }
  >(cartKeys.root, cartApi.updateProduct, {
    onMutate: async data => {
      await queryClient.cancelQueries(cartKeys.root)

      const user = queryClient.getQueryData<User>(usersKeys.root)
      const previousCart = selectData(queryClient.getQueryData(cartKeys.root)!)
      const product = previousCart.productsMap!.get(data.id)

      if (!user?.selected_address) {
        setModal({
          name: 'selectAddress',
          props: { onSuccess: () => updateProduct.mutate(data) },
        })

        throw new Error(NO_SELECTED_ADDRESS_ERROR_MESSAGE)
      }

      if (
        product &&
        product.count >= MAX_PRODUCT_COUNT &&
        data.action === 'add'
      ) {
        throw new Error(MAX_PRODUCT_COUNT_ERROR_MESSAGE)
      }

      const { productRestrictionsMap } = getRestrictions(
        previousCart.restrictions,
      )
      if (productRestrictionsMap.size > 0) {
        const deficit = productRestrictionsMap.get(data.id)

        updateProductsWithRestrictions(productRestrictionsMap)

        if (product && deficit && deficit < product?.count) {
          throw new Error(DEFICIT_ERROR_MESSAGE)
        }
      }

      const newProducts = cartApi.getNewCartProducts(data)

      queryClient.setQueryData<Cart>(cartKeys.root, cart => ({
        ...cart!,
        products: newProducts.map<CartInfoProduct>(product => ({
          ...product,
          price: {
            original: 0,
            with_discount: 0,
            discount: 0,
            final: 0,
          },
        })),
      }))

      return { previousCart }
    },
    onSuccess: (data, variables, context) => {
      const recommendations = queryClient.getQueryData<Product[]>(
        cartKeys.recommendations,
      )

      if (
        recommendations &&
        !recommendations.some(product => product.id === variables.id) &&
        !context.previousCart?.cart.products.some(
          product => product.id === variables.id,
        )
      ) {
        queryClient.invalidateQueries(cartKeys.recommendations)
      }

      analytics.cartUpdated({
        action: variables.action,
      })

      queryClient.setQueryData<Cart>(cartKeys.root, data)

      const product = data.cart.products.find(
        product => product.id === variables.id,
      )

      showDeficitToaster(t, product!)

      if (variables.action === 'add') {
        analytics.addProductToCart({
          productId: variables.id,
          source: variables.recommendations ? CART_RECOMMENDATIONS : returnHref,
        })
      }

      if (variables.action === 'sub') {
        analytics.removeProductFromCart({
          productId: variables.id,
        })
      }
    },
    onError: ({ message }, __, context) => {
      if (
        message === NO_SELECTED_ADDRESS_ERROR_MESSAGE ||
        message === MAX_PRODUCT_COUNT_ERROR_MESSAGE
      ) {
        return
      }

      if (message === DEFICIT_ERROR_MESSAGE) {
        setModal({ name: 'deficit', props: {} })
      }

      if (context?.previousCart) {
        queryClient.setQueryData<Cart>(cartKeys.root, context.previousCart)
      } else {
        queryClient.invalidateQueries(cartKeys.root)
      }
    },
  })

  const updateCart = useMutation<
    Cart,
    AxiosError<{ restrictions: Restrictions }>,
    UpdateCartProps
  >(cartKeys.root, cartApi.updateCart, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartKeys.root)
      queryClient.invalidateQueries(cartKeys.checkout)
    },
  })

  const setTimeslot = useMutation(cartApi.setTimeslot)

  const checkout = useMutation<
    Checkout,
    AxiosError<{ restrictions: Restrictions }>,
    CheckoutProps
  >(cartKeys.checkout, cartApi.checkout, {
    onSuccess: async data => {
      if (data.order_id) {
        if (data.configuration && data.configuration.type === 'redirect') {
          const configuration = {
            locale: 'en_US',
            environment: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT,
            clientKey: process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY,
          }

          const threeDSConfiguration = {
            challengeWindowSize: '05',
          }

          const checkout = await AdyenCheckout(configuration)

          ls.set<string>(ACTIVE_ORDER_ID_KEY, data.order_id)

          checkout
            .createFromAction(data.configuration, threeDSConfiguration)
            .mount(`#${componentId}`)
          return
        }
        router.replace('/')

        await queryClient.invalidateQueries(cartKeys.root)

        const user = queryClient.getQueryData<User>(usersKeys.root)!
        const userData = selectUserData(user)

        // after checkout, we need to set the address again
        updateCart.mutate({
          address: { id: userData.selected_address! },
          latitude: userData.coords!.lat,
          longitude: userData.coords!.long,
        })
        queryClient.invalidateQueries(salesKeys.root())
        queryClient.invalidateQueries(productsKeys.categoryProducts())

        setModal({
          name: 'order',
          props: { orderId: data.order_id, isNew: true },
        })
      }
    },
    onError: error => {
      const { restrictions } = error.response!.data
      const { productRestrictionsMap } = getRestrictions(restrictions)
      updateProductsWithRestrictions(productRestrictionsMap)
    },
  })

  const updateProductsWithRestrictions = (
    productRestrictionsMap: Map<string, number>,
  ) => {
    const cart = queryClient.getQueryData<Cart>(cartKeys.root)
    const products = [...cart!.cart.products]

    const filteredProducts = products.map(product => ({
      id: product.id,
      count: productRestrictionsMap.get(product.id) ?? product.count,
    }))

    return updateCart.mutateAsync({ products: filteredProducts })
  }

  return {
    ...query,
    mutations: { updateProduct, updateCart, checkout, setTimeslot },
  }
}

export default useCart
