import { useUser } from '@api'
import { AccountIcon, EditIcon, SignOutIcon, TickIcon } from '@assets/icons'
import { PrimaryButton } from '@uikit/molecules'
import { Loading } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useModals } from '..'
import BaseModal, { BaseModalProps } from '../components/BaseModal'
import type { ModalHeaderProps } from '../components/Header'

export interface AccountModalProps extends Omit<BaseModalProps, 'header'> {}

export interface NameField {
  name: string
}

const AccountModal = ({ visible, onClose }: AccountModalProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const { setModal } = useModals()

  const userQuery = useUser()

  const [disabledName, setDisabledName] = useState<boolean>(true)

  const { register, handleSubmit, setFocus, setValue } = useForm<NameField>()

  useEffect(() => {
    if (userQuery.isSuccess) {
      setValue(
        'name',
        userQuery.data.name ?? t('ui.modals.account.default_name'),
      )
    }
  }, [userQuery.data])

  const header: Omit<ModalHeaderProps, 'onClose'> = {
    Icon: (
      <AccountIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
    ),
    title: t('ui.modals.account.header'),
  }

  const onClickEditName = handleSubmit(({ name }) => {
    if (!disabledName && name !== userQuery.data!.name) {
      userQuery.mutations.updateName.mutate(name.trim())
    }

    setDisabledName(true)
  })

  const onFocusName = async () => {
    setDisabledName(false)

    setFocus('name')
  }

  // const setSetting = (setting: Setting) =>
  //   userQuery.mutations.updateSetting.mutate({
  //     ...setting,
  //     value: !setting.value,
  //   })

  const onConfirmSignOut = () => {
    userQuery.mutations.logout.mutate(undefined)
    router.push('/')
  }

  const onClickSignOut = () => {
    setModal({
      name: 'confirm',
      props: {
        onConfirm: onConfirmSignOut,
      },
    })
  }

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.account.title'),
          description: t('ui.modals.account.description'),
        },
      }}
    >
      <Loading
        type="query"
        query={userQuery}
        loader="animation"
        size={40}
        className="min-h-[34vh]"
      >
        {userQuery => (
          <>
            <section className="border-b border-ultra-light-grey p-5">
              <h3 className="flex justify-start text-base font-medium text-ultra-dark-grey">
                {t('ui.modals.account.personal_data')}
              </h3>

              <div>
                <div className="flex justify-between py-2.5">
                  <p className="text-sm text-ultra-dark-grey">
                    {t('ui.modals.account.phone_number')}
                  </p>

                  <p className="text-sm text-ultra-dark-grey">
                    {userQuery.data.phone}
                  </p>
                </div>

                <div className="flex justify-between pb-2.5">
                  <p className="text-sm text-ultra-dark-grey">
                    {t('ui.modals.account.first_name')}
                  </p>

                  <div className="text-sm text-ultra-dark-grey">
                    <div className="flex items-center">
                      <form id="change-name-form" onSubmit={onClickEditName}>
                        <input
                          {...register('name')}
                          disabled={disabledName}
                          className="mr-1.5 bg-transparent p-0 text-right text-sm text-ultra-dark-grey focus:outline-none"
                        />
                      </form>

                      {disabledName ? (
                        <button onClick={onFocusName}>
                          <EditIcon className="h-4 w-4 fill-ultra-dark-grey stroke-[1.5px]" />
                        </button>
                      ) : (
                        <button onClick={onClickEditName}>
                          <TickIcon className="h-4 w-4 stroke-ultra-dark-grey" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* {userQuery.data.settings.map(setting => (
        <section className="flex items-center justify-between border-b border-ultra-light-grey p-5">
          <div>
            <h3 className="flex justify-start pb-1 text-base font-medium text-ultra-dark-grey">
              {setting.title}
            </h3>

            <p className="text-sm font-normal text-ultra-dark-grey">
              {setting.subtitle}
            </p>
          </div>

          <Switch
            enabled={setting.value}
            setEnabled={() => setSetting(setting)}
          />
        </section>
      ))} */}

            <section className="p-5">
              <PrimaryButton
                title={t('ui.modals.account.buttons.sign_out')}
                secondary
                onClick={onClickSignOut}
                Icon={
                  <SignOutIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
                }
                iconPosition="left"
                titleClassName="text-ultra-dark-grey font-normal"
                className="w-full"
              />
            </section>
          </>
        )}
      </Loading>
    </BaseModal>
  )
}

export default AccountModal
