import { classnames } from '@tools/common'
import { useController } from 'react-hook-form'
import type { BaseFieldProps } from '../BaseField'

export interface CheckboxProps extends BaseFieldProps {}

const Checkbox = ({
  name,
  control,
  label,
  className,
  ...props
}: CheckboxProps) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: true,
  })

  return (
    <div className={classnames('flex cursor-pointer items-center', className)}>
      <input
        {...props}
        checked={value}
        onChange={onChange}
        type="checkbox"
        className={classnames(
          'checked:before:content-tick form-checkbox relative h-4 w-4 appearance-none rounded-[5px] border-[1.5px] checked:border-pink checked:bg-transparent checked:bg-pink checked:before:absolute checked:before:bottom-1 checked:before:left-0.5 checked:before:h-4 checked:before:w-4 checked:before:text-white checked:hover:bg-pink checked:hover:ring-0 focus:ring-0 focus:ring-offset-0 checked:focus:bg-pink',
          error ? 'border-red' : 'border-grey',
        )}
      />

      {label && (
        <label
          htmlFor={name}
          onClick={() => onChange(!value)}
          className="ml-2 cursor-pointer select-none"
        >
          {typeof label === 'string' ? (
            <p
              className={classnames(
                'text-sm font-normal',
                error ? 'text-red' : 'text-grey',
              )}
            >
              {label}
            </p>
          ) : (
            label
          )}
        </label>
      )}
    </div>
  )
}

export default Checkbox
