import { v1 } from '@constants/api'
import { keyToUrl } from '@tools/common'

export const addressesKeys = {
  root: [v1, 'addresses'] as const,
  address: (id: string) => [...addressesKeys.root, id] as const,
}

export const addressesUrls = {
  root: keyToUrl(addressesKeys.root),
  address: (id: string) => keyToUrl(addressesKeys.address(id)),
}
