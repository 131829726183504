import { productsKeys } from '@api'
import httpClient from '@api/httpClient'
import { queryClient } from '@api/queryClient'
import type { Coordinates } from '@models/map'
import type { Offer } from '@models/offers'
import { offersUrls } from './constants'

const getOffers = async (coords?: Coordinates) => {
  const { data: resData } = await httpClient.get<{ data: Offer[] }>(
    offersUrls.all,
    {
      params: coords,
    },
  )
  return resData.data
}

const getOffer = async (id: string, coords?: Coordinates) => {
  const { data } = await httpClient.get<Offer>(offersUrls.offer(id), {
    params: coords,
  })

  for (const product of data.products) {
    queryClient.setQueryData(productsKeys.root(product.id, coords), product)
  }

  return data
}

export default { getOffers, getOffer }
