import '@adyen/adyen-web/dist/adyen.css'
import { queryClient } from '@api/queryClient'
import { useAnalytics, useMonitoring } from '@hooks'
import { IntercomContextProvider } from '@hooks/useIntercom'
import { ErrorFallback } from '@uikit/organisms'
import Modals, { ModalsContextProvider } from '@uikit/organisms/modals'
import Notifications, {
  NotificationsContextProvider,
} from '@uikit/organisms/notifications'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import NextNProgress from 'nextjs-progressbar'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import 'react-loading-skeleton/dist/skeleton.css'
import { Hydrate, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Layout from '../layout'
import '../styles/globals.css'

const MyApp = ({ Component, pageProps }: AppProps) => {
  useMonitoring()
  useAnalytics()

  useEffect(() => {
    window.document.addEventListener('gesturestart', e => e.preventDefault(), {
      passive: false,
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <NotificationsContextProvider>
          <ModalsContextProvider>
            <IntercomContextProvider>
              <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: process.env.NEXT_PUBLIC_SEGMENT_SCRIPT,
                }}
              />

              <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: process.env.NEXT_PUBLIC_INTERCOM_SCRIPT,
                }}
              />

              {process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' && (
                <>
                  <Script
                    src="https://www.dwin1.com/27526.js"
                    type="text/javascript"
                    strategy="beforeInteractive"
                  />

                  <Script
                    type="text/javascript"
                    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_G_TAG_ID}`}
                    strategy="beforeInteractive"
                    async
                  />
                  <Script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', '${process.env.NEXT_G_TAG_ID}');`,
                    }}
                  />
                </>
              )}

              <Script
                type="text/javascript"
                src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}
                strategy="beforeInteractive"
              />

              <Script
                type="text/javascript"
                src={process.env.NEXT_PUBLIC_ADYEN_CSE_SCRIPT}
                strategy="beforeInteractive"
              />

              <Head>
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />

                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />

                <meta
                  name="facebook-domain-verification"
                  content="satuz0s7nnt5570j349cv46qfrkp7u"
                />
              </Head>

              <noscript>
                <iframe
                  src="https://www.googletagmanager.com/ns.html?id=GTM-P77G3VW"
                  height="0"
                  width="0"
                  className="invisible hidden"
                />
              </noscript>

              <ErrorBoundary
                fallbackRender={({ error, resetErrorBoundary }) => (
                  <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                  />
                )}
              >
                <Layout>
                  <NextNProgress
                    color="#FE036A"
                    startPosition={0.3}
                    stopDelayMs={200}
                    options={{ showSpinner: false }}
                    showOnShallow={false}
                  />
                  <Component {...pageProps} />
                </Layout>
              </ErrorBoundary>

              <ReactQueryDevtools />

              <Modals />

              <Notifications />
            </IntercomContextProvider>
          </ModalsContextProvider>
        </NotificationsContextProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}

export default appWithTranslation(MyApp)
