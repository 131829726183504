import {
  collection,
  CollectionReference,
  DocumentData,
  getFirestore,
} from 'firebase/firestore'
import firebaseApp from './firebaseClient'

const firestore = getFirestore(firebaseApp)

export default firestore

export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>
}
