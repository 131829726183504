import { ms } from '@tools/common'
import { useQuery } from 'react-query'
import remoteConfigApi from './actions'
import { remoteConfigKeys } from './constants'

const useRemoteConfig = () => {
  const query = useQuery(
    remoteConfigKeys.root,
    remoteConfigApi.getRemoteConfigFieldsEnabled,
    { staleTime: ms('10m') },
  )

  return query
}

export default useRemoteConfig
