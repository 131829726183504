import AdyenCheckout from '@adyen/adyen-web'
import { CreditCardIcon, MbwayIcon } from '@assets/icons'
import {
  PENDING_PAYMENT_ID_KEY,
  SELECTED_PAYMENT_ID_KEY,
} from '@constants/storage'
import { useAnalytics } from '@hooks'
import type {
  AvailablePaymentMethod,
  AvailablePaymentMethods,
  GetPaymentsData,
  PaymentMethod,
} from '@models/payments'
import { ms } from '@tools/common'
import type { DropdownItemProp } from '@uikit/molecules'
import type { AxiosError } from 'axios'
import * as ls from 'local-storage'
import { TFunction, useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import paymentsApi from './actions'
import { paymentsKeys } from './constants'
import { useRemoteConfig } from '../remoteConfig'
import { useWarehouses } from '../warehouses'

export interface UsePaymentsData {
  paymentsOptions: DropdownItemProp[]
  paymentMethods: PaymentMethod[]
  selectedPayment: string | null
  pendingPayment: string | null
  availablePaymentMethods: AvailablePaymentMethods
}

const paymentMethodToDropdownOption = (
  methods: PaymentMethod[],
): DropdownItemProp[] => {
  return methods.map(payment => ({
    label: payment.label,
    value: payment.id,
    loading: payment.status === 'PENDING',
    Icon:
      payment.type === 'MBWAY' ? (
        <MbwayIcon className="mr-2.5 fill-black" />
      ) : (
        <CreditCardIcon className="mr-2.5 fill-black" />
      ),
  }))
}

const getPaymentOptions = (
  t: TFunction,
): Readonly<Record<AvailablePaymentMethod, DropdownItemProp>> => ({
  mbway: {
    label: t('pages.checkout.choose_payment_method.add_mbway'),
    value: 'Add MB WAY',
    Icon: <MbwayIcon className="mr-2.5 fill-black" />,
  },
  card: {
    label: t('pages.checkout.choose_payment_method.add_new_card'),
    value: 'Add new card',
    Icon: <CreditCardIcon className="mr-2.5 fill-black" />,
  },
  applePay: {
    label: 'Apple Pay',
    value: 'APPLE_PAY',
    Icon: <CreditCardIcon className="mr-2.5 fill-black" />,
  },
  googlePay: {
    label: 'Google Pay',
    value: 'GOOGLE_PAY',
    Icon: <CreditCardIcon className="mr-2.5 fill-black" />,
  },
})

const usePayments = (componentId?: string) => {
  const { t } = useTranslation()

  const remoteConfig = useRemoteConfig()
  const warehousesQuery = useWarehouses()

  const analytics = useAnalytics()

  const queryClient = useQueryClient()

  const selectData = useCallback(
    ({
      paymentMethods,
      availablePaymentMethods,
    }: GetPaymentsData): UsePaymentsData => {
      let selectedPayment = ls.get<string>(SELECTED_PAYMENT_ID_KEY)
      const savedPendingId = ls.get<string>(PENDING_PAYMENT_ID_KEY)

      const pendingPaymentMethod = paymentMethods.find(
        method => method.id === savedPendingId,
      )

      const pendingPayment =
        pendingPaymentMethod?.status === 'PENDING'
          ? pendingPaymentMethod.id
          : ''

      if (!pendingPayment && savedPendingId) {
        if (pendingPaymentMethod?.status === 'ACCEPTED') {
          analytics.addPaymentInfo({ added: true })
          ls.set<string>(SELECTED_PAYMENT_ID_KEY, savedPendingId)
          selectedPayment = savedPendingId
        } else {
          analytics.addPaymentInfo({ added: false })
          toast.error(JSON.stringify('Denied', null, 2))
          selectedPayment = 'On delivery'
        }
        ls.remove(PENDING_PAYMENT_ID_KEY)
      }

      const cards = paymentMethods.filter(
        payment =>
          payment.type === 'CARD' &&
          (payment.status === 'ACCEPTED' || payment.id === pendingPayment),
      )

      const mbways = paymentMethods.filter(
        payment =>
          payment.type === 'MBWAY' &&
          (payment.status === 'ACCEPTED' || payment.id === pendingPayment),
      )

      const availableMethodOptions = Object.entries(availablePaymentMethods)
        .filter(([_, available]) => available)
        .reduce<DropdownItemProp[]>((acc, [method]) => {
          if (method === 'card') {
            return [...acc, ...paymentMethodToDropdownOption(cards)]
          }

          if (method === 'mbway') {
            return [...acc, ...paymentMethodToDropdownOption(mbways)]
          }

          return acc
        }, [])

      const getDefaultOptions = () => {
        const defaultOptions = [
          {
            label: t('pages.checkout.choose_payment_method.on_delivery'),
            value: 'On delivery',
          },
        ] as any

        const availableOptions = Object.entries(availablePaymentMethods)
          .filter(([_, available]) => available)
          .map<AvailablePaymentMethod>(
            ([name, _]) => name as AvailablePaymentMethod,
          )

        if (
          remoteConfig.isSuccess &&
          warehousesQuery.isSuccess &&
          remoteConfig.data.werahousesWithPaymentOnDelivery.includes(
            warehousesQuery.data.activeWarehouse.id,
          )
        ) {
          return [
            ...defaultOptions,
            ...availableOptions.map(method => getPaymentOptions(t)[method]),
          ]
        }

        return availableOptions.map(method => getPaymentOptions(t)[method])
      }

      return {
        paymentMethods,
        availablePaymentMethods,
        selectedPayment,
        pendingPayment,
        paymentsOptions: [...getDefaultOptions(), ...availableMethodOptions],
      }
    },
    [t],
  )

  const query = useQuery<GetPaymentsData, AxiosError, UsePaymentsData>(
    paymentsKeys.root,
    paymentsApi.getPaymentMethods,
    {
      staleTime: ms('20m'),
      select: selectData,
    },
  )

  const createCard = useMutation(paymentsApi.createCard, {
    onSuccess: async data => {
      if (data.error && data.error.resultCode !== 'Authorised') {
        toast.error(JSON.stringify(data.error.resultCode, null, 2))
        return
      }
      ls.set<string>(SELECTED_PAYMENT_ID_KEY, data.id)

      if (data.configuration) {
        ls.set<string>(PENDING_PAYMENT_ID_KEY, data.id)
        const configuration = {
          locale: 'en_US',
          environment: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT,
          clientKey: process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY,
        }

        const threeDSConfiguration = {
          challengeWindowSize: '05',
        }

        const checkout = await AdyenCheckout(configuration)

        checkout
          .createFromAction(data.configuration, threeDSConfiguration)
          .mount(`#${componentId}`)
      }
    },
  })

  const createMBWay = useMutation(paymentsApi.createMBWay, {
    onSuccess: data => {
      if (data.error) {
        toast.error(JSON.stringify(data.error.resultCode, null, 2))
        return
      }
      queryClient.setQueryData<UsePaymentsData>(
        paymentsKeys.root,
        payments => ({
          ...payments!,
          selectedPayment: data.id,
          paymentMethods: [
            ...payments!.paymentMethods,
            {
              id: data.id,
              label: data.label,
              status: 'ACCEPTED',
              type: 'MBWAY',
            },
          ],
        }),
      )
      ls.set<string>(SELECTED_PAYMENT_ID_KEY, data.id)
    },
  })

  const selectPaymentMethod = useMutation(paymentsApi.selectPaymentMethod, {
    onSuccess: paymentId => {
      queryClient.setQueryData<UsePaymentsData>(
        paymentsKeys.root,
        payments => ({
          ...payments!,
          selectedPayment: paymentId,
        }),
      )
    },
  })

  return {
    ...query,
    mutations: { createCard, createMBWay, selectPaymentMethod },
  }
}

export default usePayments
