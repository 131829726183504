import { useCart, usePromocodes, useUser } from '@api'
import {
  getMinSum,
  getPromocodeErrorMessage,
  getPromocodeTitle,
  getRestrictions,
  isPromocodeError,
} from '@api/resources/cart/tools'
import { SuccessIcon } from '@assets/icons'
import { useAnalytics } from '@hooks'
import { classnames } from '@tools/common'
import { PressableTag, TextButton, Tooltip } from '@uikit/molecules'
import { useModals } from '@uikit/organisms/modals'
import { useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import ScrollContainer from 'react-indiana-drag-scroll'

const PromocodesBar = () => {
  const { t } = useTranslation()

  const analytics = useAnalytics()

  const { setModal } = useModals()

  const promocodesQuery = usePromocodes()
  const cartQuery = useCart()
  const userQuery = useUser()

  const promocodesContainerRef = useRef<HTMLDivElement>(null)
  const [promocodeMinSum, setPromocodeMinSum] = useState<number>()
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const {
    handleSubmit,
    watch,
    setError,
    register,
    setValue,
    formState,
    clearErrors,
  } = useForm<{
    promocode: string
  }>({
    mode: 'onSubmit',
    defaultValues: { promocode: '' },
  })

  const watchPromocode = watch('promocode')

  const isSelectedPromocode = (promocode: string) => {
    const activePromocode = promocodesQuery.data?.activePromocode

    return activePromocode?.code === promocode
  }

  useEffect(() => {
    if (
      cartQuery.isSuccess &&
      cartQuery.data.cart.promo?.code &&
      watchPromocode === ''
    ) {
      setValue('promocode', cartQuery.data.cart.promo.code)
    }

    if (cartQuery.isSuccess) {
      const minSum = getMinSum(
        getRestrictions(cartQuery.data.restrictions).minSumRestrictions,
      )

      if (!minSum) {
        setPromocodeMinSum(minSum)
        return
      }

      if (minSum > cartQuery.data!.cart.total.original) {
        setTooltipVisible(true)
      }
    }
  }, [cartQuery.data])

  const onClickPromocodeButton = (code: string) => {
    let promocode = code

    if (isSelectedPromocode(promocode)) {
      promocode = ''
    }

    setValue('promocode', promocode)

    cartQuery.mutations.updateCart.mutate(
      { promo_code: promocode },
      {
        onSuccess: async data => {
          if (
            data.cart.promo?.code &&
            !promocodesQuery.data?.promocodesMap.get(
              data.cart.promo.promocode_id,
            )
          ) {
            await userQuery.mutations.addPromocode.mutateAsync(
              data.cart.promo.code,
            )

            setTimeout(() => promocodesQuery.refetch(), 500)
          }

          if (promocodesContainerRef.current) {
            promocodesContainerRef.current.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          }

          setPromocodeMinSum(
            getMinSum(getRestrictions(data.restrictions).minSumRestrictions),
          )

          clearErrors('promocode')

          analytics.promocodeApplied({ promocode })
        },
        onError: error => {
          const restrictions = error.response!.data.restrictions
          if (isPromocodeError(restrictions, 'wrong')) {
            setError('promocode', {
              message: t('pages.cart.promocode.error_wrong'),
            })
          }

          if (isPromocodeError(restrictions, 'used')) {
            setError('promocode', {
              message: t('pages.cart.promocode.error_used'),
            })
          }

          const otherError = getPromocodeErrorMessage(restrictions)

          if (otherError) {
            setError('promocode', {
              message: otherError,
            })
          }
        },
      },
    )

    analytics.promocodeEntered({ promocode })
  }

  const onHidePromocodeTooltip = () => setTooltipVisible(false)

  const isButtonVisible = () => watchPromocode.length > 2

  const onSubmit = handleSubmit(({ promocode }) => {
    if (userQuery.data?.anonymous) {
      setModal({ name: 'login', props: {} })
      return
    }

    onClickPromocodeButton(promocode)
  })

  if (!promocodesQuery.isSuccess) {
    return null
  }

  return (
    <>
      <Tooltip
        visible={tooltipVisible}
        text={t('pages.cart.promocode.error_min_sum_message', {
          min_sum: promocodeMinSum,
        })}
        onHideTooltip={onHidePromocodeTooltip}
        arrowClassName="left-10 translate-x-0"
        tooltipClassName="top-1/2 max-w-[80%]"
        containerClassName="mb-2.5"
      >
        <form onSubmit={onSubmit}>
          <div className="flex items-center justify-center">
            <input
              {...register('promocode')}
              placeholder={t('pages.cart.promocode.placeholder')}
              className={classnames(
                'form-input w-full rounded-xsmall border border-ultra-light-grey bg-white bg-transparent p-[15px] text-sm text-ultra-dark-grey placeholder:text-grey focus:border-ultra-light-grey focus:ring-0',
                {
                  'border-red text-red focus:border-red':
                    formState.errors.promocode,

                  'border-green focus:border-green':
                    promocodesQuery.data.activePromocode?.code ===
                      watchPromocode && !formState.errors.promocode,
                },
              )}
            />

            {promocodesQuery.data.activePromocode?.code === watchPromocode &&
            !formState.errors.promocode ? (
              <SuccessIcon className="absolute right-2.5 fill-green" />
            ) : null}
          </div>

          <p className="absolute max-w-full truncate text-xs font-medium text-red">
            {formState.errors.promocode?.message}
          </p>

          <div
            className={classnames('hidden', {
              'my-5 flex justify-center': isButtonVisible(),
            })}
          >
            <TextButton
              title={
                promocodesQuery.data.activePromocode?.code === watchPromocode
                  ? t('pages.cart.buttons.remove_promo_code')
                  : t('pages.cart.buttons.add_promo_code')
              }
              disabled={cartQuery.mutations.updateCart.isLoading}
              className={classnames('hidden', {
                'inline rounded-medium': isButtonVisible(),
              })}
            />
          </div>
        </form>
      </Tooltip>

      {!promocodesQuery.data?.isEmpty && (
        <ScrollContainer
          innerRef={promocodesContainerRef}
          hideScrollbars={false}
          className="flex overflow-y-hidden overflow-x-scroll pb-[1px]"
        >
          {promocodesQuery.data?.promocodes.map(promocode => (
            <PressableTag
              key={promocode.code}
              title={getPromocodeTitle(t, promocode)}
              onClick={() => onClickPromocodeButton(promocode.code)}
              selected={isSelectedPromocode(promocode.code)}
              className="mr-1 last:mr-0"
            />
          ))}
        </ScrollContainer>
      )}
    </>
  )
}

export default PromocodesBar
