import type {
  CartProduct,
  Promocode,
  Restrictions,
  RestrictionsData,
} from '@models/cart'
import type { Product } from '@models/products'
import { capitalizeFirstLetter } from '@tools/common'
import { dateFormatter } from '@tools/date'
import { Toast } from '@uikit/organisms'
import type { TFunction } from 'next-i18next'
import toast from 'react-hot-toast'
import { MAX_PRODUCT_COUNT } from './constants'

export const showDeficitToaster = (t: TFunction, product: CartProduct) => {
  if (product && product.stock === product.count) {
    toast.custom(toast => (
      <Toast
        toast={toast}
        title={t('product.deficit_only', { deficit: product.stock })}
      />
    ))
  }
}

export const isPromocodeError = (
  restrictions: Restrictions,
  type: 'wrong' | 'used',
) => {
  const code = type === 'wrong' ? '2001' : type === 'used' ? '2002' : ''
  const errors = restrictions?.errors ?? []

  return errors.some(error => error.code === code)
}

export const getPromocodeErrorMessage = (restrictions: Restrictions) => {
  const errors = restrictions?.errors ?? []
  const promocodeError = errors.find(
    error => Number(error.code) > 2002,
  )?.message

  return promocodeError && capitalizeFirstLetter(promocodeError)
}

export const getMinSum = (minSumRestrictions: RestrictionsData[]) => {
  if (minSumRestrictions.length > 0) {
    return Number(minSumRestrictions[0].args['min_sum'])
  }
}

export const getRestrictions = (restrictions: Restrictions) => {
  const warnings = restrictions?.warnings ?? []

  const productRestrictionsMap = new Map(
    warnings
      .filter(restriction => restriction.code === '1001')
      .map(product => [
        product.args['product_id'],
        Number(product.args['count']),
      ]),
  )

  const minSumRestrictions = warnings.filter(error => error.code === '4001')

  return { productRestrictionsMap, minSumRestrictions }
}

export const getMaxCount = (product: Product, cartProduct?: CartProduct) => {
  if (!cartProduct) {
    return
  }

  const { stock } = product
  const { count, deficit } = cartProduct

  if (typeof deficit === 'number') {
    return deficit
  }

  if (count === stock) {
    return stock
  }

  if (count === MAX_PRODUCT_COUNT) {
    return MAX_PRODUCT_COUNT
  }
}

export const formatComment = (
  deliveryTime?: string,
  additionalInfo?: string[],
) => {
  const comment = additionalInfo?.filter(Boolean).join(', ').trim()
  const time = `Tempo de entrega ${deliveryTime}`

  if (!deliveryTime) {
    return comment
  }

  if (comment && comment.length > 0) {
    return `${time}. (${comment})`
  }

  return time
}

export const getPromocodeTitle = (
  t: TFunction,
  {
    code,
    discount,
    usages,
    expired_at,
    min_sum,
    discount_type,
    for_first_order,
    is_delivery_free,
  }: Promocode,
) => {
  const value = `${code} - ${discount}${
    discount_type === 'PERCENT' ? '%' : '€'
  } |`

  const firstOrder = ` ${t('pages.cart.promocode.first_order')} | `

  const uses = ` ${t('pages.cart.promocode.uses', {
    value: usages,
  })} | `

  const freeDelivery = is_delivery_free
    ? t('pages.cart.promocode.free_delivery')
    : ''

  const expired =
    expired_at === null
      ? ''
      : `${t('pages.cart.promocode.expired_at', {
          value: dateFormatter.shortDate(expired_at),
        })} | `

  const minSum = `${t('pages.cart.promocode.min', { value: min_sum })}${
    freeDelivery && ' | '
  }`

  if (for_first_order) {
    return `${value + firstOrder + minSum + freeDelivery}`
  }

  return `${value}${uses}${expired}${minSum}${freeDelivery}`
}
