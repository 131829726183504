import ViewAllButton from '../ViewAllButton'

interface TitleProps {
  title?: string
  isSalesTitle?: boolean
}

const Title = ({ title, isSalesTitle }: TitleProps) => {
  if (!title) {
    return null
  }

  return (
    <>
      {isSalesTitle ? (
        <div className="flex items-center justify-between font-semibold lg:justify-start">
          <h2 className="my-2.5 text-lg font-semibold text-ultra-dark-grey lg:my-5 lg:text-2xl lg:font-bold">
            {title}
            <span className="ml-1 mr-5 text-green">%</span>
          </h2>

          <ViewAllButton />
        </div>
      ) : (
        <h3 className="py-5 text-base font-medium text-ultra-dark-grey">
          {title}
        </h3>
      )}
    </>
  )
}

export default Title
