import { CloseIcon, HamburgerIcon } from '@assets/icons'
import { Menu as BaseMenu, Transition } from '@headlessui/react'
import { classnames } from '@tools/common'
import { useRouter } from 'next/router'
import { FC, Fragment } from 'react'

export interface DropdownMenuItemProps {
  id: string
  icon?: JSX.Element
  title: string
}

export interface DropdownMenuItemCompProps extends DropdownMenuItemProps {
  last?: boolean
  onClickItem: (props: string) => void
  className?: string
  titleClassName?: string
}

export interface DropdownMenuProps {
  items: Readonly<DropdownMenuItemProps[]>
  className?: string
  onClickItem: (props: string) => void
}

export const DropdownMenuItem = ({
  id,
  icon,
  title,
  last,
  onClickItem,
  className,
  titleClassName,
}: DropdownMenuItemCompProps) => {
  return (
    <BaseMenu.Item>
      {() => (
        <li
          className={classnames(
            'hover:bg-light-grey',
            !last && 'border-b border-light-grey',
            className,
          )}
        >
          <button
            onClick={() => onClickItem(id)}
            className="group flex w-full items-center py-4 px-5"
          >
            {icon}
            <div className="ml-5">
              <p
                className={classnames(
                  'text-base font-medium text-ultra-dark-grey',
                  titleClassName,
                )}
              >
                {title}
              </p>
            </div>
          </button>
        </li>
      )}
    </BaseMenu.Item>
  )
}

export const MenuAnimation: FC = ({ children }) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {children}
    </Transition>
  )
}

const DropdownMenu = ({ items, className, onClickItem }: DropdownMenuProps) => {
  const router = useRouter()

  return (
    <BaseMenu
      as="div"
      className={classnames('relative inline-block text-left', className)}
    >
      {({ open }) => (
        <>
          <BaseMenu.Button
            as="div"
            className={classnames(
              'cursor-pointer rounded-medium border border-ultra-light-grey bg-white p-5 hover:bg-ultra-light-grey',
              {
                'bg-ultra-light-grey': open,
              },
            )}
          >
            {open ? (
              <CloseIcon className="h-4 w-4 fill-ultra-dark-grey stroke-[1.5px]" />
            ) : (
              <HamburgerIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
            )}
          </BaseMenu.Button>

          <MenuAnimation>
            <BaseMenu.Items
              as="ul"
              className={classnames(
                'absolute left-0 mt-2 w-[calc(100vw-20px)] origin-top-right overflow-hidden rounded-medium bg-ultra-light-grey shadow-lg focus:outline-none lg:w-56',
                { 'w-[calc(100vw-88px)]': router.asPath !== '/' },
              )}
            >
              {items.map((item, index) => (
                <DropdownMenuItem
                  key={item.id}
                  last={index === items.length - 1}
                  onClickItem={onClickItem}
                  {...item}
                />
              ))}
            </BaseMenu.Items>
          </MenuAnimation>
        </>
      )}
    </BaseMenu>
  )
}

export default DropdownMenu
