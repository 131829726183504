import { useNewsLine } from '@api'

const NewsLine = () => {
  const newsQuery = useNewsLine()

  if (!newsQuery.data) {
    return null
  }

  return (
    <>
      <div className="mt-5 mb-2.5 flex rounded-md bg-light-green p-2.5 lg:mb-0">
        {!!newsQuery.data.emoji && (
          <p className="text-base font-medium">{newsQuery.data.emoji}</p>
        )}

        <p className="ml-1 text-base font-medium text-ultra-dark-grey">
          {newsQuery.data.text}
        </p>
      </div>
    </>
  )
}

export default NewsLine
