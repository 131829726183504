import { InfoIcon, SuccessIcon } from '@assets/icons'
import { classnames } from '@tools/common'
import toast from 'react-hot-toast'
import type { Toast as ToastType } from 'react-hot-toast/dist/core/types'

export interface ToastProps {
  toast: ToastType
  title: string
  variant?: 'info' | 'success'
}

const Toast = ({ toast, title, variant = 'info' }: ToastProps) => {
  return (
    <div
      className={classnames(
        'pointer-events-auto flex max-w-md rounded-lg bg-ultra-light-pink shadow-lg',
        {
          'animate-enter': toast.visible,
          'animate-leave': !toast.visible,
          'bg-light-green': variant === 'success',
        },
      )}
    >
      <div className="flex items-center p-2 lg:p-4">
        <div className="flex">
          {variant === 'info' ? (
            <InfoIcon className="h-3 w-3 stroke-ultra-dark-pink lg:h-6 lg:w-6" />
          ) : (
            <SuccessIcon className="h-3 w-3 fill-ultra-dark-green lg:h-6 lg:w-6" />
          )}
        </div>
        <p
          className={classnames(
            'ml-1 text-base font-medium text-pink lg:ml-3',
            {
              'text-green': variant === 'success',
            },
          )}
        >
          {title}
        </p>
      </div>
    </div>
  )
}

export const showToast = (title: string, variant?: 'info' | 'success') => {
  toast.custom(toast => <Toast toast={toast} title={title} variant={variant} />)
}

export default Toast
