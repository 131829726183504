import { useWarehouses } from '@api/resources/warehouses'
import type { Timeslot } from '@models/warehouses'
import type { AxiosError } from 'axios'
import { format, parseISO } from 'date-fns'
import { useQuery } from 'react-query'
import { cartKeys } from '.'
import { warehousesApi } from '../warehouses'
import useCart from './useCart'

interface UseTimeslot {
  id: string
  time: string
}

interface UseTimeslotData {
  timeslots: UseTimeslot[]
  timeslotsMap: Map<string, UseTimeslot>
}

const selectData = (data: Timeslot[]): UseTimeslotData => {
  const getTimeslotTitle = (deliveryTime: Timeslot) => {
    return `${format(parseISO(deliveryTime.start), 'HH:mm')} - ${format(
      parseISO(deliveryTime.end),
      'HH:mm',
    )}`
  }

  const timeslots = data.map(timeslot => ({
    id: timeslot.id,
    time: getTimeslotTitle(timeslot),
  }))

  const timeslotsMap = new Map(
    timeslots.map(timeslot => [timeslot.id, timeslot]),
  )

  return { timeslots, timeslotsMap }
}

const useTimeslots = () => {
  const cartQuery = useCart()

  const warehousesQuery = useWarehouses()

  const query = useQuery<Timeslot[], AxiosError, UseTimeslotData>(
    cartKeys.timeslots(warehousesQuery.data?.activeWarehouse?.id),
    () => warehousesApi.getTimeslots(warehousesQuery.data!.activeWarehouse!.id),
    {
      enabled:
        cartQuery.isSuccess &&
        warehousesQuery.isSuccess &&
        !!warehousesQuery.data?.activeWarehouse?.id,
      select: selectData,
    },
  )

  return query
}

export default useTimeslots
