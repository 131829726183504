import getConfig from 'next/config'

export const CURRENCY = 'EUR' as const

const {
  publicRuntimeConfig: { version },
} = getConfig()

export const VERSION = version

export const OS_NAME = 'Web'

export const PROD_URL = 'bairro.pt'
