import { classnames } from '@tools/common'
import type { ButtonHTMLAttributes } from 'react'

export interface SubtitledButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  subtitle?: string
  secondary?: boolean
  titleClassName?: string
  subtitleClassName?: string
}

const SubtitledButton = ({
  title,
  subtitle,
  secondary,
  className,
  titleClassName,
  subtitleClassName,
  ...props
}: SubtitledButtonProps) => (
  <button
    className={classnames(
      'flex w-full flex-col justify-center rounded-medium border border-ultra-light-grey bg-white p-2.5 hover:bg-ultra-light-grey active:bg-light-grey',
      {
        'border-none bg-ultra-light-grey hover:bg-light-grey active:bg-light-grey disabled:bg-ultra-light-grey':
          secondary,
        'p-5': !subtitle,
      },
      className,
    )}
    {...props}
  >
    <p
      className={classnames(
        'text-center text-base font-semibold text-ultra-dark-grey',
        { 'w-full disabled:bg-light-grey ': secondary },
        titleClassName,
      )}
    >
      {title}
    </p>

    {subtitle && (
      <p
        className={classnames(
          'text-center text-base font-medium text-grey',
          { 'w-full text-xs text-ultra-dark-grey': secondary },
          subtitleClassName,
        )}
      >
        {subtitle}
      </p>
    )}
  </button>
)
export default SubtitledButton
