import { isPointOnWarehousePolygon, useAddressPredictions } from '@api'
import { useWarehouses } from '@api/resources/warehouses'
import { HomeIcon, LocationIcon } from '@assets/icons'
import { useAnalytics } from '@hooks'
import type {
  Coordinates,
  MarkerData,
  PolygonZoneType,
  ZoneType,
} from '@models/map'
import {
  GoogleMap,
  Marker,
  OverlayView,
  Polygon,
  useJsApiLoader,
} from '@react-google-maps/api'
import { CircleButton, Tooltip } from '@uikit/molecules'
import Loading from '@uikit/organisms/Loading'
import Toast from '@uikit/organisms/notifications/Toast'
import { useTranslation } from 'next-i18next'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import type { BaseModalProps } from '../components'
import { BaseModal } from '../components'
import SearchAddress from './components/SearchAddress'

export interface SelectAddressModalProps
  extends Omit<BaseModalProps, 'header'> {
  disableAddressSelection?: boolean
}

interface MapProps {
  polygons?: PolygonZoneType[]
  marker?: MarkerData | null
  onPointSelect: (coordinates: MarkerData, isPolygon: boolean) => void
  mapRef: google.maps.Map | null
  setRef: Dispatch<SetStateAction<google.maps.Map | null>>
  disableAddressSelection?: boolean
}

const defaultCenter = { lat: 38.722251, lng: -9.148002 }

const Map = ({
  polygons,
  marker,
  onPointSelect,
  mapRef,
  setRef,
  disableAddressSelection,
}: MapProps) => {
  const { t } = useTranslation()

  const {
    tools: { getPlaceByCoordinates },
  } = useAddressPredictions()
  const warehousesQuery = useWarehouses()

  useEffect(() => {
    if (mapRef) {
      navigator.geolocation.getCurrentPosition(async event => {
        const point = {
          lat: event.coords.latitude,
          long: event.coords.longitude,
        }
        mapRef.panTo({
          lat: event.coords.latitude,
          lng: event.coords.longitude,
        })
        const geoData = await getPlaceByCoordinates(point)
        const marker: MarkerData = {
          point,
          ...geoData,
        }

        onPointSelect(
          marker,
          warehousesQuery.tools.pointInPoligon(marker.point),
        )
      })
    }
  }, [mapRef])

  const onPolygonClick = async (e: any) => {
    const point = { lat: e.latLng.lat(), long: e.latLng.lng() }
    const geoData = await getPlaceByCoordinates(point)
    const marker: MarkerData = {
      point,
      ...geoData,
    }
    onPointSelect(marker, true)
  }

  const onLoad = useCallback((map: google.maps.Map) => setRef(map), [])

  const onMapClick = async (e: any) => {
    const point = { lat: e.latLng.lat(), long: e.latLng.lng() }
    const geoData = await getPlaceByCoordinates(point)
    const marker: MarkerData = {
      point,
      name: geoData.name,
      fullName: geoData.fullName,
    }
    onPointSelect(marker, false)
  }

  const onUnmount = useCallback(() => {
    setRef(null)
  }, [])

  const [showInfoWindow, setShowInfoWindow] = useState(false)

  const getWarehouseSize = () => {
    if (!marker) {
      return null
    }

    const warehouse = warehousesQuery.data!.warehouses?.find(warehouse =>
      isPointOnWarehousePolygon(marker.point, warehouse),
    )

    return isPointOnWarehousePolygon(marker.point, warehouse)
  }

  const warehouseSize = getWarehouseSize()

  const getDeliveryTime = () => {
    const {
      activeWarehouse: { warehouse_status_object: warehouseStatus },
    } = warehousesQuery.data!

    if (
      !warehouseSize ||
      !warehouseStatus['delivery_time'][
        warehouseSize.toLowerCase() as Lowercase<ZoneType>
      ]
    ) {
      return
    }

    return `${
      warehouseStatus['delivery_time'][
        warehouseSize.toLowerCase() as Lowercase<ZoneType>
      ]
    } ${t('ui.modals.select_address.tooltip.minutes')}`
  }

  return (
    <GoogleMap
      mapContainerClassName="w-full h-full absolute rounded-b-medium"
      center={defaultCenter}
      onClick={onMapClick}
      zoom={12}
      onLoad={onLoad}
      onUnmount={onUnmount}
      clickableIcons={false}
      options={{
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        keyboardShortcuts: false,
      }}
    >
      {polygons?.map((polygon, index) => (
        <Polygon
          key={index}
          onClick={onPolygonClick}
          options={{
            fillColor: getPoligonStyle(polygon.type),
            fillOpacity: 0.15,
            strokeColor: getPoligonStyle(polygon.type),
            strokeWeight: 2,
            visible: polygon.visible,
            clickable: !disableAddressSelection,
          }}
          path={polygon.points}
        />
      ))}
      {disableAddressSelection ||
        (marker && (
          <Marker
            icon={{
              path: 'M16 31.6308C16 30.0927 17.1781 28.8365 18.652 28.3968C24.6368 26.6113 29 21.0652 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 21.0652 4.36318 26.6113 10.348 28.3968C11.8219 28.8365 13 30.0927 13 31.6308V58.5C13 59.3284 13.6716 60 14.5 60C15.3284 60 16 59.3284 16 58.5V31.6308Z',
              fillColor: '#FE036A',
              fillOpacity: 1,
              strokeWeight: 0,
              rotation: 0,
              scale: 0.85,
              anchor: new google.maps.Point(15, 60),
            }}
            onLoad={() => setShowInfoWindow(true)}
            onUnmount={() => setShowInfoWindow(false)}
            position={{ lat: marker.point.lat, lng: marker.point.long }}
          >
            {getDeliveryTime() && showInfoWindow && (
              <OverlayView
                mapPaneName="markerLayer"
                position={{ lat: marker.point.lat, lng: marker.point.long }}
              >
                <Tooltip
                  visible
                  text={getDeliveryTime()}
                  noTimeout
                  lightMode
                  tooltipClassName="mt-3 ml-[-36.5px] w-[72px] lg:ml-[-40px] lg:w-[80px]"
                  tooltipContentClassName="bg-white text-xs px-[5px] text-center py-[5px] lg:text-xs"
                />
              </OverlayView>
            )}
          </Marker>
        ))}
    </GoogleMap>
  )
}

const getPoligonStyle = (polygonType: ZoneType) => {
  if (polygonType === 'LARGE') {
    return '#FE036A'
  }
  if (polygonType === 'SMALL') {
    return 'transparent'
  }
  if (polygonType === 'CUSTOM') {
    return '#ff519a'
  }
}

const SelectAddressModal = ({
  visible,
  onClose: onBaseClose,
  onSuccess,
  disableAddressSelection,
}: SelectAddressModalProps) => {
  const { t } = useTranslation()

  const warehousesQuery = useWarehouses()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  })

  useAnalytics([], [], analytics => analytics.mapClick())

  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null)
  const [marker, setMarker] = useState<MarkerData | null>(null)
  const [addressOnRegion, setAddressOnRegion] = useState<boolean>(true)

  const onPointSelect = (marker: MarkerData, isPolygon: boolean) => {
    setAddressOnRegion(isPolygon)
    setMarker(marker)
  }

  const panMapTo = (cord: Coordinates) => {
    if (mapRef) {
      mapRef?.panTo({ lat: cord.lat, lng: cord.long })

      mapRef?.setZoom(17)
    }
  }
  const [userCoords, setUserCoords] = useState<Coordinates>()

  const {
    tools: { getPlaceByCoordinates },
  } = useAddressPredictions()

  useEffect(() => {
    ;(async () => {
      if (userCoords) {
        if (mapRef) {
          mapRef.panTo({ lat: userCoords?.lat!, lng: userCoords?.long! })

          mapRef?.setZoom(17)
        }
        const geoData = await getPlaceByCoordinates(userCoords!)

        const marker: MarkerData = {
          point: { lat: userCoords?.lat!, long: userCoords?.long! },
          ...geoData,
        }

        setMarker(marker)

        onPointSelect(
          marker,
          warehousesQuery.tools.pointInPoligon(marker.point),
        )
      }
    })()
  }, [userCoords])

  const onClose = () => {
    if (onSuccess) {
      onSuccess()
    }

    onBaseClose()
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setUserCoords({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          })
        },
        () => {
          toast.custom(toast => (
            <Toast
              toast={toast}
              title={t('ui.modals.select_address.toast.locationError.title')}
              variant="info"
            />
          ))
        },
        { enableHighAccuracy: true },
      )
    }
  }

  const header = {
    Icon: <LocationIcon className="h-5 w-5 stroke-black text-pink" />,
    title: disableAddressSelection
      ? t('ui.modals.select_address.delivery_zones')
      : t('ui.modals.select_address.header'),
  }

  return (
    <BaseModal
      {...{
        onClose: onBaseClose,
        visible,
        header,
        metadata: {
          title: t('ui.modals.select_address.title'),
          description: t('ui.modals.select_address.description'),
        },
        className: 'lg:w-full h-full lg:h-[68vh]',
        dialogClassName: 'h-full',
        containerClassName: 'md:max-w-5xl lg:max-h-[80vh] lg:h-full',
      }}
    >
      <Loading
        type="common"
        loader="animation"
        loading={!isLoaded}
        size={40}
        className="h-full lg:min-h-[68vh]"
      >
        <>
          {!disableAddressSelection && (
            <SearchAddress
              addressAvailable={addressOnRegion}
              setAddressAvailable={setAddressOnRegion}
              panMapTo={panMapTo}
              marker={marker}
              onClose={onClose}
              setMarker={setMarker}
              className="absolute z-10 m-2.5 max-h-[calc(100%-85px)] w-[calc(100%-20px)] md:bottom-auto md:right-0 md:w-[43%] lg:w-[32%]"
            />
          )}

          <CircleButton
            className="absolute bottom-5 left-5 z-10 h-10 w-10 md:top-20"
            Icon={<HomeIcon className="h-4 w-4 fill-white" />}
            onClick={getLocation}
          />

          <Map
            mapRef={mapRef}
            setRef={setMapRef}
            polygons={warehousesQuery.data?.zones}
            onPointSelect={onPointSelect}
            marker={marker}
            disableAddressSelection={disableAddressSelection}
          />
        </>
      </Loading>
    </BaseModal>
  )
}

export default SelectAddressModal
