import { getMaxCount } from '@api/resources/cart/tools'
import type { CartProduct } from '@models/cart'
import type { Product } from '@models/products'
import { classnames } from '@tools/common'
import type { PriceProps } from '@uikit/molecules'
import { AmountSelector, Label, Price, SaleTag } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { memo } from 'react'

export interface ProductCardProps {
  product: Product
  cartProduct?: CartProduct
  smallCard?: boolean
  onAdd: (id: string) => void
  onSub: (id: string) => void
  onOpenProduct: (id: string) => void
  className?: string
  priceClassName?: Pick<
    PriceProps,
    'valueClassName' | 'saleClassName' | 'className'
  >
}

const ProductCard = ({
  product,
  cartProduct,
  smallCard,
  onAdd,
  onSub,
  className,
  priceClassName,
  onOpenProduct,
}: ProductCardProps) => {
  const { t } = useTranslation()

  const maxCount = getMaxCount(product, cartProduct)

  return (
    <div
      className={classnames(
        'group flex flex-1 flex-col overflow-hidden rounded-t-medium bg-white',
        className,
      )}
      onClick={() => onOpenProduct(product.id)}
    >
      <div className="relative">
        <Image
          className="cursor-pointer rounded-medium bg-image-grey"
          src={product.media.small}
          width={270}
          height={270}
          objectFit="contain"
          layout="responsive"
          loader={({ src }) => src}
        />

        <AmountSelector
          value={cartProduct?.count}
          onAdd={() => onAdd(product.id)}
          onSub={() => onSub(product.id)}
          max={maxCount}
          smallCard={smallCard}
          className={classnames(
            'absolute right-2.5 bottom-3 z-10 rounded-full shadow lg:bottom-4 lg:right-5',
            { 'lg:bottom-3 lg:right-2.5': smallCard },
          )}
        />

        {product.price.sale > 0 && (
          <SaleTag
            size="medium"
            title={`-${product.price.sale}%`}
            className="absolute top-0 left-0 z-10"
          />
        )}

        {maxCount && (
          <div className="absolute inset-0 cursor-pointer select-none rounded-medium bg-ultra-dark-grey bg-opacity-20">
            <p className="relative top-[calc(50%-25px)] text-center text-2xl font-bold text-white">
              {t('product.deficit_only', { deficit: maxCount })}
            </p>
          </div>
        )}
      </div>

      <div className="flex flex-col pt-2.5">
        <span className="min-h-[37px] lg:mb-1">
          <Label
            title={product.name}
            titleClassName={classnames(
              'text-xs lg:text-base line-clamp-2 font-medium shrink-0 cursor-pointer',
              { 'lg:text-xs': smallCard },
            )}
          />
        </span>

        <Price
          smallCard={smallCard}
          value={product.price.withsale}
          sale={product.price.sale ? product.price.full : undefined}
          valueClassName="lg:text-xl xs:text-xs xm:text-base"
          saleClassName="lg:text-sm text-xs xm:mb-[2px]"
          {...priceClassName}
        />
      </div>
    </div>
  )
}

export default memo(
  ProductCard,
  (prev, next) =>
    prev.cartProduct?.count === next.cartProduct?.count &&
    prev.cartProduct?.deficit === next.cartProduct?.deficit,
)
