import { CloseIcon } from '@assets/icons'
import { Transition } from '@headlessui/react'
import { usePageHeaderVisible } from '@hooks'
import { classnames } from '@tools/common'
import { FC, useState } from 'react'

export interface PopupProps {
  emoji: string
  title: string
  message: string
  onClose: () => void
}

export interface PopupAnimationProps {
  show: boolean
}

const PopupAnimation: FC<PopupAnimationProps> = ({ children, show }) => {
  const headerVisible = usePageHeaderVisible()

  return (
    <Transition
      show={show}
      as="div"
      leave="transition duration-200 ease-in"
      leaveFrom="transform translate-y-0 opacity-100"
      leaveTo="transform translate-y-20 opacity-30"
      className={classnames(
        'fixed left-[18px] right-[18px] top-3 z-10 flex w-auto opacity-100 sm:top-auto sm:bottom-3 md:right-[20%] md:left-[20%] lg:bottom-10 lg:left-[33%] lg:w-1/3',
        { 'opacity-0 sm:opacity-100': !headerVisible },
      )}
      role="alert"
    >
      {children}
    </Transition>
  )
}

const Popup = ({ emoji, title, message, onClose: onBaseClose }: PopupProps) => {
  const [show, setShow] = useState<boolean>(true)

  const onClose = () => {
    setShow(false)
    setTimeout(onBaseClose, 300)
  }

  return (
    <PopupAnimation show={show}>
      <div className="flex flex-1 items-start rounded-medium border border-very-light-grey bg-white shadow">
        <div className="flex items-center py-1 pl-4">
          <span className="mr-3 select-none text-[45px] lg:text-[52px]">
            {emoji}
          </span>

          <div className="flex flex-col text-sm text-ultra-dark-grey lg:text-base">
            <p className="font-bold">{title}</p>
            <p className="font-medium">{message}</p>
          </div>
        </div>

        <button
          onClick={onClose}
          className="group ml-auto rounded-full p-5 focus:outline-none"
        >
          <CloseIcon className="h-3 w-3 fill-ultra-dark-grey group-active:animate-ping" />
        </button>
      </div>
    </PopupAnimation>
  )
}

export default Popup
