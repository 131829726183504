import { v1, v2 } from '@constants/api'
import { keyToUrl } from '@tools/common'

export const authKeys = {
  root: [v2, 'auth'] as const,
  create: () => [...authKeys.root, 'create'] as const,
  refresh: () => [...authKeys.root, 'refresh'] as const,
  login: () => [...authKeys.root, 'login'] as const,
  code: () => [...authKeys.root, 'code'] as const,
}

export const authUrls = {
  root: keyToUrl(authKeys.root),
  create: keyToUrl(authKeys.create()),
  refresh: keyToUrl(authKeys.refresh()),
  login: keyToUrl(authKeys.login()),
  code: keyToUrl(authKeys.code()),
}

export const usersKeys = {
  root: [v1, 'users'] as const,
  settings: () => [...usersKeys.root, 'settings'] as const,
  promocode: () => [...usersKeys.root, 'promocode'] as const,
}

export const usersUrls = {
  root: keyToUrl(usersKeys.root),
  settings: keyToUrl(usersKeys.settings()),
  promocode: keyToUrl(usersKeys.promocode()),
}
