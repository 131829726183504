import axios from 'axios'
import qs from 'qs'
import applyAuth from './interceptors/auth'
import applyErrorResponse from './interceptors/errorResponse'
import applyAnalyticHeaders from './interceptors/analytics'

const httpClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
})

applyAnalyticHeaders(httpClient)
applyErrorResponse(httpClient)
applyAuth(httpClient)

export default httpClient
