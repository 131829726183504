import { usePrevious } from '@hooks'
import type { WithOptional } from '@tools/common'
import { useContextualRouting } from 'next-use-contextual-routing'
import { useRouter } from 'next/router'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import type { AccountModalProps } from './AccountModal'
import AccountModal from './AccountModal'
import type { AddressesModalProps } from './AddressesModal'
import AddressesModal from './AddressesModal'
import type { CartModalProps } from './CartModal'
import CartModal from './CartModal'
import type { ConfirmModalProps } from './ConfirmModal'
import ConfirmModal from './ConfirmModal'
import type { ContactUsModalProps } from './ContactUsModal'
import ContactUsModal from './ContactUsModal'
import type { DeficitModalProps } from './DeficitModal'
import DeficitModal from './DeficitModal'
import type { DeliveryTermsModalProps } from './DeliveryTermsModal'
import DeliveryTermsModal from './DeliveryTermsModal'
import type { FaqModalProps } from './FaqModal'
import FaqModal from './FaqModal'
import type { FeedbackModalProps } from './FeedbackModal'
import FeedbackModal from './FeedbackModal'
import LoginModal, { LoginModalProps } from './LoginModal'
import OrderModal, { OrderModalProps } from './OrderModal'
import type { OrdersModalProps } from './OrdersModal'
import OrdersModal from './OrdersModal'
import type { OutOfWorkModalProps } from './OutOfWorkModal'
import OutOfWorkModal from './OutOfWorkModal'
import ProductInfoModal, { ProductInfoModalProps } from './ProductInfoModal'
import type { PromoCodeModalProps } from './PromoCodeModal'
import PromoCodeModal from './PromoCodeModal'
import type { SelectAddressModalProps } from './SelectAddressModal'
import SelectAddressModal from './SelectAddressModal'

export type Modal =
  | { name: 'account'; props: WithOptional<AccountModalProps, 'onClose'> }
  | { name: 'addresses'; props: WithOptional<AddressesModalProps, 'onClose'> }
  | { name: 'contactUs'; props: WithOptional<ContactUsModalProps, 'onClose'> }
  | { name: 'faq'; props: WithOptional<FaqModalProps, 'onClose'> }
  | { name: 'login'; props: WithOptional<LoginModalProps, 'onClose'> }
  | { name: 'orders'; props: WithOptional<OrdersModalProps, 'onClose'> }
  | { name: 'outOfWork'; props: WithOptional<OutOfWorkModalProps, 'onClose'> }
  | {
      name: 'product'
      props: WithOptional<ProductInfoModalProps, 'onClose'>
    }
  | { name: 'promo'; props: WithOptional<PromoCodeModalProps, 'onClose'> }
  | {
      name: 'selectAddress'
      props: WithOptional<SelectAddressModalProps, 'onClose'>
    }
  | { name: 'feedback'; props: WithOptional<FeedbackModalProps, 'onClose'> }
  | { name: 'order'; props: WithOptional<OrderModalProps, 'onClose'> }
  | {
      name: 'deliveryTerms'
      props: WithOptional<DeliveryTermsModalProps, 'onClose'>
    }
  | { name: 'deficit'; props: WithOptional<DeficitModalProps, 'onClose'> }
  | { name: 'cart'; props: WithOptional<CartModalProps, 'onClose'> }
  | { name: 'confirm'; props: WithOptional<ConfirmModalProps, 'onClose'> }

export interface ModalsContextProps {
  modal: Modal | null
  setModal: React.Dispatch<React.SetStateAction<Modal | null>>
  isOpened: (name: Modal['name']) => boolean
}

const ModalsContext = createContext<ModalsContextProps>({
  modal: null,
  setModal: () => {},
  isOpened: () => false,
})

export const ModalsContextProvider: FC = ({ children }) => {
  const [modal, setModal] = useState<Modal | null>(null)

  const isOpened = (name: Modal['name']) => modal?.name === name

  return (
    <ModalsContext.Provider value={{ modal, setModal, isOpened }}>
      {children}
    </ModalsContext.Provider>
  )
}

export const useModals = () => useContext(ModalsContext)

const Modals = () => {
  const router = useRouter()

  const { modal, setModal } = useModals()

  const { makeContextualHref, returnHref } = useContextualRouting()

  const prevModal = usePrevious(modal)

  const onModalSuccess = () => {
    const onSuccess = modal!.props.onSuccess!
    setModal(null)
    onSuccess()
  }

  const onSuccess = modal?.props?.onSuccess ? onModalSuccess : undefined

  const onClose = () => {
    const onModalClose = modal!.props?.onClose

    if (onModalClose) {
      setModal(null)
      onModalClose()
      return
    }

    if (!['deficit', 'cart'].includes(modal!.name)) {
      if (router.asPath === returnHref) {
        router.push('/', undefined, { shallow: true })
      } else {
        router.push(returnHref, undefined, { shallow: true })
      }
    }

    if (!onModalClose) {
      setModal(null)
    }

    if (
      (modal?.name === 'deliveryTerms' || modal?.name === 'product') &&
      prevModal?.name === 'cart'
    ) {
      setModal(prevModal)
    }

    if (modal?.name === 'order' && prevModal && prevModal.name === 'orders') {
      setModal(prevModal)
    }

    if (
      (modal?.name === 'confirm' || modal?.name === 'contactUs') &&
      prevModal
    ) {
      setModal(prevModal)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (!modal?.name || ['deficit', 'cart'].includes(modal.name)) {
        return
      }

      if (modal.name === 'product') {
        router.push(makeContextualHref({}), `/products/${modal.props.id}`, {
          shallow: true,
        })
        return
      }

      if (modal.name === 'order') {
        router.push(makeContextualHref({}), `/orders/${modal.props.orderId}`, {
          shallow: true,
        })
        return
      }

      if (modal.name === 'orders' && modal.props.orderId) {
        router.push(
          makeContextualHref({ id: modal.props.orderId }),
          `/orders/${modal.props.orderId}`,
          { shallow: true },
        )
        return
      }

      if (modal.name === 'feedback') {
        router.push(
          makeContextualHref({}),
          `/feedback/${modal.props.orderId}`,
          { shallow: true },
        )
        return
      }

      router.push(makeContextualHref({}), `/${modal.name}`, {
        shallow: true,
      })
    }, 100)
  }, [modal?.name])

  if (!modal) {
    return null
  }

  if (modal.name === 'account') {
    return (
      <AccountModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'selectAddress') {
    return (
      <SelectAddressModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'addresses') {
    return (
      <AddressesModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'contactUs') {
    return (
      <ContactUsModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'faq') {
    return (
      <FaqModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'login') {
    return (
      <LoginModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'orders') {
    return (
      <OrdersModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'outOfWork') {
    return (
      <OutOfWorkModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'product') {
    return (
      <ProductInfoModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'promo') {
    return (
      <PromoCodeModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'feedback') {
    return (
      <FeedbackModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'order') {
    return (
      <OrderModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'deficit') {
    return (
      <DeficitModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'cart') {
    return (
      <CartModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'deliveryTerms') {
    return (
      <DeliveryTermsModal
        {...modal.props}
        onSuccess={onSuccess}
        onClose={onClose}
        visible
      />
    )
  }

  if (modal.name === 'confirm') {
    return <ConfirmModal {...modal.props} onClose={onClose} visible />
  }

  return null
}

export default Modals
