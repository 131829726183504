import httpClient from '@api/httpClient'
import type { Category } from '@models/categories'
import type { Coordinates } from '@models/map'
import { categoriesUrls } from './constants'

const getCategories = async (coords?: Coordinates) => {
  const { data: resData } = await httpClient.get<{ data: Category[] }>(
    categoriesUrls.all,
    { params: coords },
  )
  return resData.data
}

export default { getCategories }
