import { createCollection } from '@api/firebase/firestoreClient'
import type {
  SendMissingProductFeedbackProps,
  SendOrdersFeedbackProps,
  SendZoneFeedbackProps,
} from '@models/feedback'
import type { Coordinates } from '@models/map'
import { addDoc, serverTimestamp } from 'firebase/firestore'
import { feedbackKeys } from './constants'

export const ordersFeedbackColection =
  createCollection<SendOrdersFeedbackProps>(feedbackKeys.ordersFeedback)

export const sendZoneFeedbackColection =
  createCollection<SendZoneFeedbackProps>(feedbackKeys.zonesFeedback)

export const sendMissingProductsFeedbackColection =
  createCollection<SendMissingProductFeedbackProps>(
    feedbackKeys.missingProductsFeedback,
  )

const sendOrdersFeedback = async (data: SendOrdersFeedbackProps) =>
  addDoc(ordersFeedbackColection, data)

const sendZoneFeedback = async ({
  email,
  coords,
}: {
  email: string
  coords: Coordinates
}) => {
  const feedback: SendZoneFeedbackProps = {
    date: serverTimestamp(),
    email,
    location: {
      latitude: coords.lat,
      longitude: coords.long,
    },
  }

  await addDoc(sendZoneFeedbackColection, feedback)
}

const sendMissingProductsFeedback = async (
  data: SendMissingProductFeedbackProps,
) => addDoc(sendMissingProductsFeedbackColection, data)

export default {
  sendOrdersFeedback,
  sendZoneFeedback,
  sendMissingProductsFeedback,
}
