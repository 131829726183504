import {
  prefetchCategories,
  prefetchCategoryProducts,
  prefetchOffer,
  prefetchOffers,
  prefetchProduct,
} from '@api'
import { defaultQueryFn } from '@api/queryClient'
import { getIsBot } from '@api/seo'
import type { GetServerSideProps, NextApiRequest } from 'next'
import { dehydrate, QueryClient, UseQueryResult } from 'react-query'

export const isQueryLoading = (queries: UseQueryResult | UseQueryResult[]) =>
  (Array.isArray(queries) ? queries : [queries]).some(query => !query.isSuccess)

export const prefetchQueries = async ({
  req,
  query,
}: Parameters<GetServerSideProps>[0]) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
        notifyOnChangeProps: 'tracked',
      },
    },
  })

  const isBot = getIsBot(req as NextApiRequest)
  const isSitemap = process.env.IS_SITEMAP

  if (isBot || isSitemap) {
    await prefetchCategories(queryClient)
    await prefetchOffers(queryClient)

    if (query['categoryId']) {
      await prefetchCategoryProducts(queryClient, query['categoryId'] as string)
    }

    if (query['offerId']) {
      await prefetchOffer(queryClient, query['offerId'] as string)
    }

    if (query['productId']) {
      await prefetchProduct(queryClient, query['productId'] as string)
    }
  }

  return { dehydratedState: dehydrate(queryClient) }
}
