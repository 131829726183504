import { useCart } from '@api'
import { CART_RECOMMENDATIONS } from '@constants/source'
import type { Product } from '@models/products'
import type { PriceProps } from '@uikit/molecules'
import { ProductCard } from '@uikit/organisms'
import { useModals } from '@uikit/organisms/modals'
import { useContextualRouting } from 'next-use-contextual-routing'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
import NextButton from '../components/NextButton'
import PrevButton from '../components/PrevButton'
import Title from '../components/Title'

export interface ProductsCarouselProps {
  products: Product[]
  settings?: Settings
  title: string
  isSalesTitle?: boolean
  smallCard?: boolean
  additionalComponents?: JSX.Element[]
  className?: string
  priceClassName?: Pick<
    PriceProps,
    'valueClassName' | 'saleClassName' | 'className'
  >
  prevButtonClassName?: string
  nextButtonClassName?: string
  recommendations?: boolean
}

const ProductsCarousel = ({
  products,
  settings: baseSettings,
  title,
  isSalesTitle,
  smallCard,
  additionalComponents,
  className,
  priceClassName,
  prevButtonClassName,
  nextButtonClassName,
  recommendations = false,
}: ProductsCarouselProps) => {
  const cartQuery = useCart()

  const { returnHref } = useContextualRouting()

  const { setModal } = useModals()

  const onOpenProduct = (productId: string) => {
    setModal({
      name: 'product',
      props: { id: productId, recommendations },
    })
  }

  const onAdd = (productId: string) => {
    cartQuery.mutations.updateProduct.mutate({
      id: productId,
      action: 'add',
      source: recommendations ? CART_RECOMMENDATIONS : returnHref,
      recommendations,
    })
  }

  const onSub = (productId: string) => {
    cartQuery.mutations.updateProduct.mutate({
      id: productId,
      action: 'sub',
    })
  }

  const settings: Settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: (
      <NextButton
        btnClassName={nextButtonClassName}
        slidesToScroll={baseSettings?.slidesToScroll ?? 3}
      />
    ),
    prevArrow: <PrevButton btnClassName={prevButtonClassName} />,
    ...baseSettings,
  }

  if (products.length === 0) {
    return null
  }

  return (
    <div className={className}>
      <Title title={title} isSalesTitle={isSalesTitle} />

      <Slider {...settings}>
        {products.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            smallCard={smallCard}
            cartProduct={cartQuery.data?.productsMap.get(product.id)!}
            onAdd={onAdd}
            onSub={onSub}
            onOpenProduct={onOpenProduct}
            priceClassName={priceClassName}
          />
        ))}

        {additionalComponents?.map(component => component)}
      </Slider>
    </div>
  )
}

export default ProductsCarousel
