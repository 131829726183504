import { useRef } from 'react'

const usePrevious = <T>(value: T | null) => {
  const currRef = useRef<T | null>(value)
  const prevRef = useRef<T | null>()

  if (currRef.current !== value) {
    prevRef.current = currRef.current
    currRef.current = value
  }

  return prevRef.current
}

export default usePrevious
