import { useCart } from '@api'
import { classnames } from '@tools/common'
import { getCartVisible } from '@tools/layout'
import { DefaultListProductCardSkeleton, Loading } from '@uikit/organisms'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { useModals } from '../modals'
import {
  CheckoutButton,
  EmptyCart,
  PriceSection,
  ProductsList,
  PromocodesBar,
  Title,
} from './components'

interface CartProps {
  className?: string
}

const Cart = ({ className }: CartProps) => {
  const router = useRouter()

  return (
    <>
      <div
        className={classnames(
          'overflow-y-auto overflow-x-hidden overscroll-contain px-5 duration-500 ease-in-out',
          {
            'max-h-[calc(100vh-275px)]': getCartVisible(router.pathname),
            'max-h-full lg:max-h-[calc(100vh-196px)]':
              router.pathname.includes('checkout'),
          },
          className,
        )}
      >
        <ProductsList />
        <PromocodesBar />
        {/* <Recommendations /> */}
        <PriceSection />
      </div>

      <CheckoutButton />
    </>
  )
}

export interface CartCompProps {
  hideTitle?: boolean
  className?: string
  containerClassName?: string
}

const CartComp = ({
  hideTitle,
  className,
  containerClassName,
}: CartCompProps) => {
  const router = useRouter()

  const { isOpened } = useModals()

  const cartQuery = useCart()

  return (
    <div className={containerClassName}>
      <Title hideTitle={hideTitle} />

      <div
        className={classnames(
          'overflow-hidden rounded-medium border border-ultra-light-grey bg-white',
          {
            'rounded-b-none border border-b-0 lg:rounded-medium lg:border-b':
              router.pathname.includes('checkout'),
            'rounded-none border-none': isOpened('cart') && hideTitle,
          },
        )}
      >
        <Loading
          type="query"
          loader="skeleton"
          query={cartQuery}
          Skeleton={<CartSkeleton />}
        >
          <Loading
            type="query"
            loader="skeleton"
            query={cartQuery}
            Skeleton={<CartSkeleton />}
          >
            {cartQuery => (
              <>
                {cartQuery.data.isEmpty ? (
                  <EmptyCart />
                ) : (
                  <Cart className={className} />
                )}
              </>
            )}
          </Loading>
        </Loading>
      </div>
    </div>
  )
}

export default CartComp

export const CartSkeleton = () => (
  <div className="p-5">
    {range(3).map(n => (
      <DefaultListProductCardSkeleton key={n} className="pb-5 last:pb-0" />
    ))}
  </div>
)
