import { PrimaryButton } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import { useModals } from '..'
import { BaseModal, BaseModalProps } from '../components'

export interface ConfirmModalProps extends BaseModalProps {
  title?: string
  subtitle?: string
  onConfirm: () => void
}

const header = () => {
  return null
}

const ConfirmModal = ({
  title,
  subtitle,
  visible,
  onClose,
  onConfirm: onBaseConfirm,
}: ConfirmModalProps) => {
  const { t } = useTranslation()

  const { setModal } = useModals()

  const onConfirm = () => {
    onBaseConfirm()

    setModal(null)
  }

  return (
    <BaseModal
      {...{
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.confirm.title'),
        },
      }}
      header={{ ...header, withBorder: false }}
    >
      <div className="flex flex-col items-center px-5 pb-10">
        <h3 className="mb-10 text-2xl font-bold text-ultra-dark-grey">
          {title ?? t('ui.confirm.title')}
        </h3>

        {subtitle && (
          <p className="mb-10 break-words text-ultra-dark-grey">{subtitle}</p>
        )}

        <div className="flex items-center">
          <PrimaryButton
            title={t('ui.buttons.cancel')}
            secondary
            onClick={onClose}
            className="mr-5"
          />

          <PrimaryButton title={t('ui.buttons.confirm')} onClick={onConfirm} />
        </div>
      </div>
    </BaseModal>
  )
}

export default ConfirmModal
