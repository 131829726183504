import { productsKeys } from '@api'
import httpClient from '@api/httpClient'
import { queryClient } from '@api/queryClient'
import type { PaginatedResponse } from '@models/common'
import type { Coordinates } from '@models/map'
import type { Product } from '@models/products'
import { salesUrls } from './constants'

const getSalesProducts = async (coords?: Coordinates) => {
  const {
    data: { data },
  } = await httpClient.get<PaginatedResponse<Product[]>>(salesUrls.root, {
    params: coords,
  })

  data.sort(
    (prevProduct, product) => product.price.sale - prevProduct.price.sale,
  )

  for (const product of data) {
    queryClient.setQueryData(productsKeys.root(product.id, coords), product)
  }

  return data
}

export default { getSalesProducts }
