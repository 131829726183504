import { usePayments } from '@api'
import { zodResolver } from '@hookform/resolvers/zod'
import { Input, PrimaryButton } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import { MBWAY_REGEX } from '../CheckoutForm/validation'
import getValidationScheme from './validation'

const MBWayForm = () => {
  const { t } = useTranslation()

  const {
    mutations: { createMBWay },
  } = usePayments()

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ phone: string }>({
    mode: 'onTouched',
    resolver: zodResolver(getValidationScheme(t)),
    defaultValues: {
      phone: '+351',
    },
  })

  const onSubmit = handleSubmit(data => {
    createMBWay.mutate({ phoneNumber: data.phone })
  })

  return (
    <div className="mt-10">
      <form id="mbWay-form" onSubmit={onSubmit}>
        <Input
          name="phone"
          control={control}
          placeholder="+351"
          mask={MBWAY_REGEX}
          className="rounded-xsmall"
        />
      </form>

      <PrimaryButton
        type="submit"
        form="mbWay-form"
        title={t('pages.checkout.buttons.payment_method')}
        iconPosition="right"
        disabled={!isValid}
        loading={createMBWay.isLoading}
        onClick={onSubmit}
        className="mt-10 w-full bg-pink disabled:bg-light-pink"
      />
    </div>
  )
}

export default MBWayForm
