export const LINK_CONDITIONS =
  'https://bairro.io/bairro/termoscondicoes' as const

export const LINK_PRIVACY_POLICY =
  'https://bairro.io/bairro/privacidade' as const

export const LINK_FACEBOOK = 'https://www.facebook.com/bairro.pt' as const

export const LINK_INSTAGRAM = 'https://www.instagram.com/bairro_pt' as const

export const LINK_APP_STORE =
  'https://apps.apple.com/pt/app/bairro/id1542190024?shortlink=f7edb1fc&pid=site&c=button-ios' as const

export const LINK_PLAY_MARKET =
  'https://bairro.onelink.me/hQiE/3b47bb24' as const
