import { Router } from 'next/router'
import { useEffect, useState } from 'react'

const useRouteLoading = () => {
  const [path, setPath] = useState<string>()

  useEffect(() => {
    const onRouteChange = (url?: string) => {
      setPath(url)
    }

    Router.events.on('routeChangeStart', url => onRouteChange(url))
    Router.events.on('routeChangeComplete', () => onRouteChange(undefined))
    Router.events.on('routeChangeError', () => onRouteChange(undefined))

    return () => {
      Router.events.off('routeChangeStart', url => onRouteChange(url))
      Router.events.off('routeChangeComplete', onRouteChange)
      Router.events.off('routeChangeError', onRouteChange)
    }
  }, [])

  return { path, pathLoading: !!path }
}

export default useRouteLoading
