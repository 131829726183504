import { useCart, useUser } from '@api'
import { useOrder } from '@api/resources/orders'
import { CartIcon } from '@assets/icons'
import { ACTIVE_ORDER_ID_KEY } from '@constants/storage'
import { useAnalytics } from '@hooks'
import { classnames } from '@tools/common'
import { Loading } from '@uikit/organisms'
import { safeMetadata } from '@uikit/organisms/Metadata'
import type {
  BaseModalProps,
  ModalHeaderProps,
} from '@uikit/organisms/modals/components'
import { BaseModal } from '@uikit/organisms/modals/components'
import { InfoSection } from '@uikit/organisms/modals/OrdersModal/components'
import * as ls from 'local-storage'
import { useTranslation } from 'next-i18next'
import { useContextualRouting } from 'next-use-contextual-routing'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export interface OrderModalProps extends BaseModalProps {
  orderId: string
  isNew?: boolean
}

const OrderModal = ({ orderId, isNew, visible, onClose }: OrderModalProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const orderQuery = useOrder(orderId)
  const userQuery = useUser()
  const cartQuery = useCart()

  const analytics = useAnalytics([orderQuery], ['generateAwinUrl'], analytics =>
    analytics.orderClick({ orderId }),
  )

  const { makeContextualHref } = useContextualRouting()

  const [isNewOrder, setIsNewOrder] = useState<boolean | undefined>(isNew)
  const [orderAwinUrl, setOrderAwinUrl] = useState<string | null>(null)

  useEffect(() => {
    if (isNewOrder && orderQuery.isSuccess) {
      if (orderQuery.data.status === 'READY_TO_PICK') {
        setOrderAwinUrl(
          analytics.generateAwinUrl({
            amount: orderQuery.data.price.with_sale,
            order_id: orderQuery.data.order_id,
          }),
        )
        setIsNewOrder(false)
      }

      if (
        orderQuery.data.payment_info &&
        orderQuery.data.status === 'CANCELED'
      ) {
        setIsNewOrder(false)
      }

      return
    }

    setOrderAwinUrl(null)
  }, [isNewOrder, orderQuery.data])

  useEffect(() => {
    if (userQuery.isSuccess) {
      const activeOrderId = ls.get<string>(ACTIVE_ORDER_ID_KEY)
      if (activeOrderId) {
        ls.remove(ACTIVE_ORDER_ID_KEY)

        router.replace(
          makeContextualHref({ id: activeOrderId }),
          `/orders/${activeOrderId}`,
        )
        setIsNewOrder(true)

        // after successful checkout via redirect url open new order modal
        // after checkout, we need to set the address again
        cartQuery.mutations.updateCart.mutate({})
      }
    }
  }, [userQuery.data])

  const header: Omit<ModalHeaderProps, 'onClose'> = {
    Icon: <CartIcon className="h-4 w-4 stroke-black stroke-[1.5px]" />,
    title: orderQuery.data
      ? t('ui.modals.orders.info.form.buttons.back', {
          ordernumber: orderQuery.data.ordernumber,
        })
      : t('ui.messages.loading'),
  }

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: safeMetadata(
          orderQuery,
          orderQuery => ({
            title: t('ui.modals.order.title', {
              ordernumber: orderQuery.data.ordernumber,
            }),
            description: t('ui.modals.order.description', {
              ordernumber: orderQuery.data.ordernumber,
            }),
          }),
          t,
        ),
        className: 'lg:w-full h-full lg:max-h-[68vh]',
        dialogClassName: 'h-full lg:h-fit',
        containerClassName: classnames({
          'lg:max-w-4xl md:max-w-2xl': orderQuery.data?.status === 'DELIVERING',
        }),
      }}
    >
      <Loading
        type="query"
        query={orderQuery}
        loader="animation"
        size={40}
        className="h-full lg:min-h-[68vh]"
      >
        <>
          {process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' && orderAwinUrl && (
            <img src={orderAwinUrl} />
          )}

          <InfoSection orderId={orderId} />
        </>
      </Loading>
    </BaseModal>
  )
}

export default OrderModal
