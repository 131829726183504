import { HEADER_HEIGHT } from '@constants/layout'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useState } from 'react'

const usePageHeaderVisible = () => {
  const [headerVisible, setHeaderVisible] = useState<boolean>(false)

  useScrollPosition(
    ({ currPos }) => {
      if (!headerVisible && currPos.y >= HEADER_HEIGHT) {
        setHeaderVisible(true)
      }

      if (headerVisible && currPos.y < HEADER_HEIGHT) {
        setHeaderVisible(false)
      }
    },
    [headerVisible],
    undefined,
    true,
  )

  return headerVisible
}

export default usePageHeaderVisible
