import { v1 } from '@constants/api'
import { keyToUrl } from '@tools/common'

export const ordersKeys = {
  all: [v1, 'orders'] as const,
  order: (id: string) => [...ordersKeys.all, id] as const,
}

export const ordersUrls = {
  all: keyToUrl(ordersKeys.all),
  order: (id: string) => keyToUrl(ordersKeys.order(id)),
}
