import type { EmojiStatus as EmojiStatusType } from '@models/orders'
import { classnames } from '@tools/common'
import { EmojiStatus } from '@uikit/molecules'

export interface EmojiStatusCompProps {
  type: EmojiStatusType
  className?: string
}

const EmojiStatusComp = ({ type, className }: EmojiStatusCompProps) => {
  const status: EmojiStatusType =
    type === 'READY_TO_PICK' || type === 'PICKING' || type === 'PICKED'
      ? 'PICKING'
      : type

  if (type === 'WAITING_PAYMENT') {
    return (
      <div>
        <EmojiStatus type="status" emojiType="WAITING_PAYMENT" />
      </div>
    )
  }

  if (type === 'CANCELED') {
    return (
      <div>
        <EmojiStatus type="status" emojiType="CANCELED" />
      </div>
    )
  }

  if (type === 'DELIVERED' || type === 'DONE') {
    return (
      <div>
        <EmojiStatus type="status" emojiType="DELIVERED" />
      </div>
    )
  }

  return (
    <div className={classnames('p-5', className)}>
      <div className="flex justify-center text-center">
        <EmojiStatus
          className="z-20 w-1/3"
          type="status"
          emojiType={type === 'PICKED' ? 'PICKED' : 'PICKING'}
          animated={status === 'PICKING'}
        />

        <EmojiStatus
          className="z-20 w-1/3"
          type="status"
          emojiType="DELIVERING"
          animated={status === 'DELIVERING'}
          disabled={status === 'PICKING'}
        />

        <EmojiStatus
          className="z-20 w-1/3"
          type="status"
          emojiType="DELIVERED"
          disabled={status === 'PICKING' || status === 'DELIVERING'}
        />
      </div>
    </div>
  )
}

export default EmojiStatusComp
