import type { AxiosInstance } from 'axios'
import { parse } from 'next-useragent'
import getConfig from 'next/config'

const {
  publicRuntimeConfig: { version },
} = getConfig()

export default (axios: AxiosInstance) => {
  const ua = typeof window !== 'undefined' && parse(window.navigator.userAgent)

  if (ua) {
    axios.interceptors.request.use(config => {
      config.headers!['X-Application-Platform'] = 'web'
      config.headers!['X-Application-Version'] = version
      config.headers![
        'X-Application-Platform-Version'
      ] = `${ua.browser} ${ua.browserVersion}`

      return config
    })
  }
}
