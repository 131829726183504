import { PulseAnimation as PulseData } from '@assets/animations'
import type { EmojiStatus, FeedbackType } from '@models/orders'
import { classnames } from '@tools/common'
import { useTranslation } from 'next-i18next'
import Lottie from 'react-lottie'

const DELIVERY_STATUS_TO_EMOJI: Readonly<Record<EmojiStatus, string>> = {
  WAITING_PAYMENT: '💳',
  READY_TO_PICK: '🛍️',
  PICKING: '🛍️',
  PICKED: '🛍️',
  DELIVERING: '🚴‍♂️',
  DELIVERED: '🙌',
  CANCELED: '😕',
  DONE: '🙌',
}

const FEEDBACK_TYPE_TO_EMOJI: Readonly<Record<FeedbackType, string>> = {
  THUMBS_UP: '👍',
  THUMBS_DOWN: '👎',
}

const getDeliveryEmoji = (deliveryStatus: EmojiStatus) =>
  DELIVERY_STATUS_TO_EMOJI[deliveryStatus]

const getFeedbackEmoji = (feedbackType: FeedbackType) =>
  FEEDBACK_TYPE_TO_EMOJI[feedbackType]

export interface EmojiStatusProps {
  type: 'status'
  hideTitle?: boolean
  emojiType: EmojiStatus
  disabled?: boolean
  animated?: boolean
  className?: string
  onClick?: () => void
}

export interface EmojiFeedbackProps {
  type: 'feedback'
  emojiType: FeedbackType
  selected: boolean
  className?: string
  onClick: () => void
}

export type EmojiTypeProps = EmojiStatusProps | EmojiFeedbackProps

const EmojiStatusComp = (props: EmojiTypeProps) => {
  const { t } = useTranslation()

  if (props.type === 'status') {
    const {
      hideTitle = false,
      disabled,
      onClick,
      className,
      emojiType,
      animated,
    } = props

    return (
      <div
        className={classnames(
          'flex select-none flex-col items-center',
          {
            'opacity-20': disabled,
            'cursor-pointer': onClick,
          },
          className,
        )}
        onClick={onClick}
      >
        <div className="relative rounded-full border-2 border-pink bg-white">
          <div className="absolute -z-10 scale-[200%]">
            {!disabled && animated && (
              <Lottie
                isClickToPauseDisabled
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: PulseData,
                }}
              />
            )}
          </div>

          <p className="flex h-[51px] w-[51px] items-center justify-center text-[30px]">
            {getDeliveryEmoji(emojiType)}
          </p>
        </div>

        {!hideTitle && (
          <p className="mt-1 text-sm font-medium text-ultra-dark-grey">
            {t(`ui.delivery.${emojiType}`)}
          </p>
        )}
      </div>
    )
  }

  if (props.type === 'feedback') {
    const { onClick, className, selected, emojiType } = props
    return (
      <button
        onClick={onClick}
        className={classnames(
          'flex select-none flex-col items-center',
          className,
        )}
      >
        <div
          className={classnames('rounded-full border-2 border-white bg-white', {
            'border-pink': selected,
          })}
        >
          <p className="flex h-[51px] w-[51px] items-center justify-center text-[30px]">
            {getFeedbackEmoji(emojiType)}
          </p>
        </div>
      </button>
    )
  }

  return null
}

export default EmojiStatusComp
