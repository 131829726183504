import httpClient from '@api/httpClient'
import type { Timeslot, Warehouse } from '@models/warehouses'
import { warehousesUrls } from './constants'

const getWarehouses = async () => {
  const { data } = await httpClient.get<{ data: Warehouse[] }>(
    warehousesUrls.all,
  )
  return data.data
}

const getTimeslots = async (warehouseId: string) => {
  const { data: resData } = await httpClient.get<{ data: Timeslot[] }>(
    warehousesUrls.timeslot(warehouseId),
  )

  return resData.data
}

export default { getWarehouses, getTimeslots }
