import { Label, Price } from '@uikit/molecules'
import classnames from 'classnames'
import Image from 'next/image'

export interface HistoryListProductCardProps {
  name: string
  count: number
  price: number
  image: string
  weight?: number
  className?: string
}

const HistoryListProductCard = ({
  name,
  count,
  price,
  image,
  className,
}: HistoryListProductCardProps) => {
  return (
    <li className={classnames('flex flex-1 items-center', className)}>
      <Image
        className="rounded-xsmall bg-image-grey"
        width={50}
        height={50}
        objectFit="contain"
        src={image}
        alt={name}
        loader={({ src }) => src}
      />

      <div className="ml-2.5 flex w-full flex-col">
        <Label
          title={name}
          subtitleClassName="text-xs self-start"
          className="self-start text-xs"
        />

        <div className="mt-2 flex justify-between">
          <Price value={price} size="small" className="text-ultra-dark-grey" />

          <p className="rounded-xsmall bg-ultra-light-grey px-2 text-sm font-medium text-ultra-dark-grey">{`x${count}`}</p>
        </div>
      </div>
    </li>
  )
}

export default HistoryListProductCard
