import { getLanguage } from '@i18n'
import type { News, NewsResp } from '@models/news'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { newsApi, newsKeys } from '.'

const selectData = (data: NewsResp | undefined): News | undefined => {
  if (!data) {
    return
  }

  return { ...data, text: data.text[getLanguage()] }
}

const useNewsLine = () =>
  useQuery<NewsResp | undefined, AxiosError, News | undefined>(
    newsKeys.root,
    newsApi.getNewsLine,
    {
      staleTime: ms('20m'),
      select: selectData,
    },
  )

export default useNewsLine
