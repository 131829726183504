import type { ExternalLink } from '@analytics/models'
import { BairroIllustration } from '@assets/illustrations'
import { useAnalytics } from '@hooks'
import { IconLinkButton } from '@uikit/molecules'
import { useModals } from '@uikit/organisms/modals'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { getFooterLinks, getSocialsLinks, STORES_LINKS } from './configs'

export interface PageFooterProps {}

// eslint-disable-next-line no-empty-pattern
const PageFooter = ({}: PageFooterProps) => {
  const { t } = useTranslation()

  const analytics = useAnalytics()

  const { setModal } = useModals()

  const onStoreClick = (type: ExternalLink) => {
    analytics.openExternalLink({ link: type })
  }

  const onSocialClick = (title: string) => {
    if (title === 'Facebook') {
      analytics.openExternalLink({ link: title.toLowerCase() as ExternalLink })
    }

    if (title === 'Instagram') {
      analytics.openExternalLink({ link: title.toLowerCase() as ExternalLink })
    }
  }

  const setAddressModal = () => {
    analytics.openExternalLink({ link: 'delivery' })
    setModal({
      name: 'selectAddress',
      props: { disableAddressSelection: true },
    })
  }

  return (
    <footer className="mt-5 sm:px-2.5 lg:mt-0 lg:px-0">
      <div className="flex flex-col items-center border-y border-ultra-light-grey bg-white lg:flex-row lg:py-10">
        <div className="flex w-full flex-col justify-between lg:flex-row">
          <div className="flex justify-around py-5 lg:py-0">
            <Link href="/">
              <a>
                <BairroIllustration className="h-[39px] w-[128px] lg:mb-3 lg:h-[49px] lg:w-[166px]" />
              </a>
            </Link>

            <div className="flex lg:hidden">
              {getSocialsLinks(t).map(socialLink => (
                <IconLinkButton
                  key={socialLink.title}
                  roundedIcon
                  target="_blank"
                  href={socialLink.href}
                  Icon={socialLink.Icon}
                  containerClassName="mr-2 rounded-full last:mr-0"
                />
              ))}
            </div>
          </div>

          <span className="flex w-full flex-col-reverse lg:flex-row">
            <div className="flex w-full justify-around border-ultra-light-grey px-2.5 py-5 text-center text-sm sm:text-base lg:mb-0 lg:border-y lg:border-none lg:px-0 lg:py-0">
              {getFooterLinks(t).map(footerLink => (
                <IconLinkButton
                  key={footerLink.title}
                  target="_blank"
                  {...footerLink}
                  className="mr-2.5 text-ultra-dark-grey last:mr-0 lg:mr-0"
                />
              ))}

              <button
                title={t('layout.footer.delivery_zones')}
                className="text-center text-sm text-ultra-dark-grey lg:text-left lg:text-base"
                onClick={setAddressModal}
              >
                {t('layout.footer.delivery_zones')}
              </button>
            </div>
            <span className="flex w-full flex-wrap justify-center border-y border-ultra-light-grey py-5 lg:justify-end lg:border-none lg:py-0">
              {STORES_LINKS.map(({ href, Icon, type }) => (
                <Link key={href as string} href={href}>
                  <a
                    onClick={() => onStoreClick(type)}
                    target="_blank"
                    className="mr-5 rounded-lg last:mr-0"
                  >
                    {Icon}
                  </a>
                </Link>
              ))}
            </span>
          </span>
        </div>
      </div>
      <div className="flex min-w-full justify-between py-5 lg:py-[30px]">
        <p className="ml-2.5 text-base text-ultra-dark-grey sm:ml-0">
          @{new Date().getFullYear()}. Bairro
        </p>

        <div className="hidden lg:flex">
          {getSocialsLinks(t).map(socialLink => (
            <div
              key={socialLink.href}
              onClick={() => onSocialClick(socialLink.title!)}
              className="mr-10 last:mr-0"
            >
              <IconLinkButton
                key={socialLink.title}
                target="_blank"
                {...socialLink}
              />
            </div>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default PageFooter
