import { ArrowRightIcon } from '@assets/icons'
import { classnames } from '@tools/common'
import type { CustomArrowProps } from 'react-slick'

interface ButtonsProps extends CustomArrowProps {
  btnClassName?: string
  slidesToScroll?: number
}

const NextButton = ({
  onClick,
  btnClassName,
  currentSlide,
  slideCount,
  slidesToScroll,
}: ButtonsProps) => (
  <button
    className={classnames(
      'absolute top-1/4 right-0 cursor-pointer rounded-full border border-ultra-light-grey bg-white p-2.5 shadow-lg sm:top-1/4 lg:-right-5',
      {
        hidden:
          slideCount &&
          currentSlide &&
          slideCount - currentSlide === slidesToScroll,
      },

      btnClassName,
    )}
    onClick={onClick}
  >
    <ArrowRightIcon className="h-4 w-4 fill-transparent stroke-ultra-dark-grey" />
  </button>
)

export default NextButton
