import { usersApi, useUser } from '@api'
import { CheckmarkIcon } from '@assets/icons'
import { LINK_CONDITIONS, LINK_PRIVACY_POLICY } from '@constants/socials'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAnalytics } from '@hooks'
import { clearText, successField } from '@tools/common'
import { LinkProps, LinksText } from '@uikit/atoms'
import { Checkbox, Input, PrimaryButton } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTimer } from 'use-timer'
import { BaseModal, BaseModalProps } from '../components'
import getValidationScheme, {
  PHONE_MASK_REGEXP,
  PHONE_REGEXP,
} from './validation'

interface LoginForm {
  phone: string
  code: string
  confirm: boolean
}

export interface LoginModalProps extends BaseModalProps {}

const LoginModal = ({
  header,
  visible,
  onClose,
  onSuccess,
}: LoginModalProps) => {
  const { t } = useTranslation()

  const userQuery = useUser()

  const [loading, setLoading] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<number>(0)

  const analytics = useAnalytics([], [], analytics =>
    analytics.authorizationClick(),
  )

  const onCloseModal = () => {
    if (currentStep === 1) {
      const confirmed = confirm(t('ui.modals.login.confirm'))
      if (confirmed) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  const { time, start, reset } = useTimer({
    initialTime: 30,
    endTime: 0,
    timerType: 'DECREMENTAL',
  })

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    setError,
    getValues,
    setFocus,
    getFieldState,
    formState: { isValid },
  } = useForm<LoginForm>({
    mode: 'all',
    resolver: zodResolver(getValidationScheme(t, currentStep)),
    defaultValues: {
      phone: '+351',
      code: '',
      confirm: true,
    },
  })

  const watchPhone = watch('phone')
  const watchCode = watch('code')
  const watchConfirm = watch('confirm')

  useEffect(() => {
    if (watchCode?.length === 5) {
      trigger('code')
    }
  }, [watchCode])

  const resetTimer = () => {
    reset()
    start()
  }

  const onSubmit = handleSubmit(async ({ phone, code }) => {
    if (isValid) {
      setLoading(true)

      if (currentStep === 0) {
        const success = await usersApi.sendAuthCode(clearText(phone))

        if (success) {
          setCurrentStep(1)
          setFocus('code')
          resetTimer()
        }

        if (!success) {
          setError('phone', {
            message: t('ui.modals.login.form.validation.phone'),
          })
        }
      }

      if (currentStep === 1) {
        const success = await userQuery.mutations.login.mutateAsync(code)

        if (success) {
          const user = await userQuery.refetch()

          analytics.accountLogin({
            isSuccess: true,
            userId: user.data!.id,
            newUser: user.data!.addresses.length === 0,
          })

          if (user.data!.addresses.length === 0) {
            analytics.signUp()
          }

          if (onSuccess) {
            onSuccess()
            return
          }

          onClose()
        }

        if (!success) {
          analytics.accountLogin({
            isSuccess: false,
            userId: '',
            newUser: false,
          })
          setError('code', {
            message: t('ui.modals.login.form.validation.code'),
          })
        }
      }

      setLoading(false)
    }
  })

  const onSendCode = async () => {
    const phone = getValues('phone')
    await usersApi.sendAuthCode(clearText(phone))

    resetTimer()
  }

  const getButtonTitle =
    currentStep === 1 && isValid ? t('ui.delivery.DONE') : t('ui.delivery.NEXT')

  const getButtonIcon =
    currentStep === 1 && isValid ? (
      <CheckmarkIcon className="fill-white" />
    ) : undefined

  const isButtonDisabled =
    currentStep === 0
      ? !successField(watchPhone, PHONE_REGEXP) || !watchConfirm
      : !isValid || !watchConfirm

  const personalLinks: LinkProps[] = [
    { title: t('ui.modals.login.links.confirm_personal') },
    {
      title: t('ui.modals.login.links.personal_data'),
      href: LINK_PRIVACY_POLICY,
    },
  ]

  const otherLinks: LinkProps[] = [
    { title: t('ui.modals.login.links.agree_privacy') },
    {
      title: t('ui.modals.login.links.privacy_policy'),
      href: LINK_PRIVACY_POLICY,
    },
    { title: t('ui.modals.login.links.and') },
    {
      title: t('ui.modals.login.links.terms_of_service'),
      href: LINK_CONDITIONS,
    },
  ]

  return (
    <BaseModal
      {...{
        visible,
        onClose: onCloseModal,
        metadata: {
          title: t('ui.modals.login.title'),
          description: t('ui.modals.login.description'),
        },
        header: { ...header, withBorder: false },
      }}
    >
      <div className="p-5">
        <h3 className="text-center text-2xl font-bold text-ultra-dark-grey">
          {t('ui.modals.login.header')}
        </h3>

        <p className="mb-10 text-center text-sm text-ultra-dark-grey">
          {t('ui.modals.login.header_description')}
        </p>

        <form id="login-form" onSubmit={onSubmit}>
          <Input
            name="phone"
            control={control}
            Icon={
              successField(watchPhone, PHONE_REGEXP) &&
              !getFieldState('phone').error ? (
                <CheckmarkIcon className="fill-pink" />
              ) : undefined
            }
            placeholder="+351"
            mask={PHONE_MASK_REGEXP}
            className="w-full overflow-hidden rounded-xsmall"
            inputClassName="rounded-xsmall overflow-hidden"
          />

          {currentStep === 1 && (
            <Input
              name="code"
              control={control}
              placeholder={t('ui.modals.login.form.code.placeholder')}
              Icon={
                watchCode?.length === 5 && !getFieldState('code').error ? (
                  <CheckmarkIcon className="fill-pink" />
                ) : undefined
              }
              maxLength={5}
              className="mt-3 overflow-hidden rounded-xsmall"
              inputClassName="rounded-xsmall overflow-hidden"
            />
          )}
        </form>

        <PrimaryButton
          type="submit"
          form="login-form"
          title={getButtonTitle}
          disabled={isButtonDisabled}
          iconPosition="right"
          Icon={getButtonIcon}
          loading={loading}
          onSubmit={onSubmit}
          titleClassName="text-white text-center"
          className="mt-8 w-full rounded-medium bg-pink disabled:bg-light-pink ml:mt-5"
        />

        {currentStep === 0 && (
          <>
            <Checkbox
              name="confirm"
              control={control}
              label={<LinksText links={personalLinks} />}
              className="my-5"
            />

            <LinksText links={otherLinks} />
          </>
        )}

        {currentStep === 1 && (
          <div className="mt-5 flex justify-center">
            {time !== 0 && (
              <div className="flex justify-center">
                <span className="text-grey">
                  {t('ui.modals.login.new_code_request.title')}
                </span>
                &nbsp;
                {time.toString().padStart(4, '0:0')}
              </div>
            )}

            {time === 0 && (
              <button onClick={onSendCode} className="font-bold text-pink">
                {t('ui.modals.login.buttons.send_a_code')}
              </button>
            )}
          </div>
        )}
      </div>
    </BaseModal>
  )
}

export default LoginModal
