import { useCart } from '@api'
import type { Product } from '@models/products'
import { classnames } from '@tools/common'
import { ProductCard } from '@uikit/organisms'
import { useModals } from '@uikit/organisms/modals'
import { range } from 'lodash'
import { useTranslation } from 'next-i18next'
import { useContextualRouting } from 'next-use-contextual-routing'
import Skeleton from 'react-loading-skeleton'

export interface ProductsListProps {
  products: Product[]
  headerTitle?: string
  subcategoryId?: string
  listClassName?: string
}

export interface ProductOperation {
  action: 'add' | 'sub'
  productId: string
}

const ProductsList = ({
  products,
  headerTitle,
  subcategoryId,
  listClassName,
}: ProductsListProps) => {
  const { t } = useTranslation()

  const { returnHref } = useContextualRouting()

  const { setModal } = useModals()

  const cartQuery = useCart()

  const onOpenProduct = (productId: string) => {
    setModal({
      name: 'product',
      props: { id: productId },
    })
  }

  const onAdd = (productId: string) => {
    cartQuery.mutations.updateProduct.mutate({
      id: productId,
      action: 'add',
      source: returnHref,
    })
  }

  const onSub = (productId: string) => {
    cartQuery.mutations.updateProduct.mutate({
      id: productId,
      action: 'sub',
    })
  }

  return (
    <section id={subcategoryId} className="py-5">
      {headerTitle && (
        <h2 className="mb-2.5 text-lg font-semibold text-ultra-dark-grey lg:mb-5 lg:text-2xl lg:font-bold">
          {headerTitle}
        </h2>
      )}

      <div
        className={classnames(
          'grid grid-cols-3 gap-2.5 lg:grid-cols-4 lg:gap-5 xl:grid-cols-5',
          listClassName,
        )}
      >
        {products?.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            cartProduct={cartQuery.data?.productsMap.get(product.id)}
            onAdd={onAdd}
            onSub={onSub}
            onOpenProduct={onOpenProduct}
          />
        ))}
      </div>

      {products.length === 0 && (
        <p className="text-base font-medium text-dark-pink lg:text-lg">
          {t('ui.messages.no_products')}
        </p>
      )}
    </section>
  )
}

export default ProductsList

interface ProductListSkeletonProps {
  responsive?: boolean
  numOfProductsRows?: number
  type?: 'carousel' | 'page'
  header?: JSX.Element
  className?: string
  titleClassName?: string
}

export const ProductListSkeleton = ({
  responsive = true,
  numOfProductsRows,
  type = 'carousel',
  className,
  header,
  titleClassName,
}: ProductListSkeletonProps) => {
  return (
    <div className={className}>
      {header || (
        <Skeleton
          inline
          className={classnames(
            'lg:w-1/4" mb-2.5 h-7 w-1/3 rounded-medium lg:mb-5',
            titleClassName,
          )}
        />
      )}

      {range(numOfProductsRows ?? 1).map(n => (
        <div
          key={n}
          className={classnames(
            'mb-2.5 grid h-[156px] w-full grid-cols-3 grid-rows-1 gap-x-2.5 xm:h-[175px] ml:h-[191px] md:h-[305px] lg:mb-5 lg:h-[246px] lg:gap-x-5 xl:h-[248px]',
            { 'lg:grid-cols-4 xl:grid-cols-5': type === 'page' && responsive },
          )}
        >
          <Skeleton inline className="block h-full rounded-medium" />

          <Skeleton inline className="block h-full rounded-medium" />

          <Skeleton inline className="block h-full rounded-medium" />

          {responsive && (
            <Skeleton
              inline
              className={classnames('hidden ', {
                'h-full rounded-medium lg:block': type === 'page',
              })}
            />
          )}

          {responsive && (
            <Skeleton
              inline
              className={classnames('hidden ', {
                'h-full rounded-medium xl:block': type === 'page',
              })}
            />
          )}
        </div>
      ))}
    </div>
  )
}

const SalesSkeletonHeader = () => (
  <div className="flex items-center justify-between lg:justify-start">
    <Skeleton inline className="mb-2.5 h-7 w-[95px] rounded-medium lg:mb-5" />

    <Skeleton
      inline
      className="mb-2.5 h-7 w-[110px] rounded-medium lg:ml-10 lg:mb-5 "
    />
  </div>
)

export const SalesSkeleton = () => (
  <ProductListSkeleton className="mt-5" header={<SalesSkeletonHeader />} />
)
