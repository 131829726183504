import { ArrowRightIcon } from '@assets/icons'
import { classnames } from '@tools/common'
import type { CustomArrowProps } from 'react-slick'

interface ButtonsProps extends CustomArrowProps {
  btnClassName?: string
}

const PrevButton = ({ onClick, btnClassName, currentSlide }: ButtonsProps) => (
  <button
    className={classnames(
      'absolute top-1/4 z-20  cursor-pointer rounded-full border border-ultra-light-grey bg-white p-2.5 shadow-lg sm:top-1/4 lg:-left-5',
      { hidden: currentSlide === 0 },
      btnClassName,
    )}
    onClick={onClick}
  >
    <ArrowRightIcon className="h-4 w-4 rotate-180 fill-transparent  stroke-ultra-dark-grey" />
  </button>
)

export default PrevButton
