import { v1 } from '@constants/api'
import { keyToUrl } from '@tools/common'

export const datetimeKeys = {
  root: [v1, 'datetime'],
  now: () => [...datetimeKeys.root, 'now'] as const,
}

export const datetimeUrls = {
  now: keyToUrl(datetimeKeys.now()),
}
