import type { Order } from '@models/orders'
import { classnames } from '@tools/common'
import { useRouter } from 'next/router'
import { createContext, FC, useContext, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useScrollToTopButtonVisible } from '../ScrollToTopButton'
import ActiveOrderFlightButton, {
  ActiveOrderFlightButtonProps,
} from './ActiveOrderFlightButton'
import type { PopupProps } from './Popup'
import Popup from './Popup'

export type Notification =
  | { name: 'popup'; props: Omit<PopupProps, 'onClose'> }
  | { name: 'flightButton'; props: ActiveOrderFlightButtonProps }

export interface NotificationsContextProps {
  notification: Notification | null
  setNotification: React.Dispatch<React.SetStateAction<Notification | null>>
  activeOrderOpened: Order | null
  setActiveOrderOpened: React.Dispatch<React.SetStateAction<Order | null>>
}

const NotificationsContext = createContext<NotificationsContextProps>({
  notification: null,
  setNotification: () => {},
  activeOrderOpened: null,
  setActiveOrderOpened: () => {},
})

export const NotificationsContextProvider: FC = ({ children }) => {
  const [notification, setNotification] = useState<Notification | null>(null)

  const [activeOrderOpened, setActiveOrderOpened] = useState<Order | null>(null)

  return (
    <NotificationsContext.Provider
      value={{
        notification,
        setNotification,
        activeOrderOpened,
        setActiveOrderOpened,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => useContext(NotificationsContext)

const Notifications = () => {
  const router = useRouter()

  const { notification, setNotification, activeOrderOpened } =
    useNotifications()

  const scrollToTopButtonVisible = useScrollToTopButtonVisible()

  const onClose = () => setNotification(null)

  if (router.pathname === '/404' || router.pathname === '/500') {
    return null
  }

  return (
    <>
      <Toaster position="top-right" containerClassName="mt-[75px]" />

      {activeOrderOpened && (
        <ActiveOrderFlightButton
          orderId={activeOrderOpened.id}
          className={classnames({
            'bottom-[85px]':
              scrollToTopButtonVisible && notification?.name !== 'popup',
          })}
        />
      )}

      {notification?.name === 'popup' && (
        <Popup onClose={onClose} {...notification.props} />
      )}
    </>
  )
}

export default Notifications
