import { useTimeslots } from '@api'
import { classnames } from '@tools/common'
import BaseField, { BaseFieldProps } from '@uikit/molecules/form/BaseField'
import NextButton from '@uikit/organisms/carousels/components/NextButton'
import PrevButton from '@uikit/organisms/carousels/components/PrevButton'
import { useController } from 'react-hook-form'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
export interface DeliveryTimeCarouselProps
  extends Omit<BaseFieldProps, 'name'> {}

const DeliveryTimeCarousel = ({
  control,
  label,
  required,
}: DeliveryTimeCarouselProps) => {
  const {
    field: { onChange, value },
  } = useController({ name: 'deliveryTime', control })

  const timeslotsQuery = useTimeslots()

  const settings: Settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextButton btnClassName="-mr-5" slidesToScroll={4} />,
    prevArrow: <PrevButton btnClassName="-ml-5" />,
    rows: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          nextArrow: <NextButton btnClassName="-mr-5" slidesToScroll={3} />,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          nextArrow: <NextButton btnClassName="-mr-5" slidesToScroll={2} />,
        },
      },
    ],
  }

  if (!timeslotsQuery.isSuccess) {
    return null
  }

  const isSelected = (timeId: string) => value?.includes(timeId)

  const onChangeItem = (timeId: string) => {
    onChange(timeId)
  }

  return (
    <BaseField label={label} required={required}>
      <div className="mx-auto mt-5">
        <Slider {...settings}>
          {timeslotsQuery.data.timeslots.map(({ id, time }) => {
            return (
              <button
                type="button"
                key={id}
                onClick={() => onChangeItem(id)}
                className={classnames(
                  'group mb-2.5 flex flex-1 cursor-pointer justify-center rounded-xsmall py-2.5 transition duration-500',
                  isSelected(id)
                    ? 'bg-pink'
                    : 'bg-ultra-light-grey lg:bg-white',
                )}
              >
                <p
                  className={classnames(
                    'text-sm font-medium transition duration-500',
                    isSelected(id) ? 'text-white' : 'text-ultra-dark-grey',
                  )}
                >
                  {time}
                </p>
              </button>
            )
          })}
        </Slider>
      </div>
    </BaseField>
  )
}

export default DeliveryTimeCarousel
