import { classnames } from '@tools/common'

export interface PressableTagProps {
  title: string
  onClick: () => void
  selected?: boolean
  className?: string
}

const PressableTag = ({
  title,
  onClick,
  selected,
  className,
}: PressableTagProps) => {
  return (
    <button
      onClick={onClick}
      className={classnames(
        'select-none rounded-xsmall bg-ultra-light-grey py-[5px] px-2.5 transition duration-300',
        { 'bg-pink': selected },
        className,
      )}
    >
      <p
        className={classnames(
          'truncate text-sm font-medium text-ultra-dark-grey transition duration-300',
          { 'text-white': selected },
        )}
      >
        {title}
      </p>
    </button>
  )
}

export default PressableTag
