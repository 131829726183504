import {
  FacebookIcon,
  InstagramIcon,
  MailIcon,
  ComplaintIcon,
} from '@assets/icons'
import {
  AppStoreIllustration,
  GooglePlayIllustration,
} from '@assets/illustrations'
import {
  LINK_APP_STORE,
  LINK_CONDITIONS,
  LINK_FACEBOOK,
  LINK_INSTAGRAM,
  LINK_PLAY_MARKET,
  LINK_PRIVACY_POLICY,
} from '@constants/socials'
import type { IconLinkButtonProps } from '@uikit/molecules'
import type { ExternalLink } from 'analytics/models'
import type { TFunction } from 'next-i18next'
import type { LinkProps } from 'next/link'

export const getSocialsLinks = (t: TFunction) => {
  return [
    {
      Icon: (
        <MailIcon className="stroke-ultra-dark-grey group-hover:stroke-grey group-active:stroke-grey" />
      ),
      href: 'mailto:info@bairro.shop',
      title: 'info@bairro.shop',
      className:
        'text-ultra-dark-grey group-hover:text-grey group-active:text-grey',
    },
    {
      Icon: (
        <InstagramIcon className="fill-ultra-dark-grey group-hover:fill-bright-red group-active:fill-bright-red" />
      ),
      href: LINK_INSTAGRAM,
      title: 'Instagram',
      className:
        'text-ultra-dark-grey group-hover:text-bright-red group-active:text-bright-red',
    },
    {
      Icon: (
        <FacebookIcon className="fill-ultra-dark-grey group-hover:fill-blue group-active:fill-blue" />
      ),
      href: LINK_FACEBOOK,
      title: 'Facebook',
      className:
        'text-ultra-dark-grey group-hover:text-blue group-active:text-blue',
    },
    {
      Icon: (
        <ComplaintIcon className="h-[20px] w-[20px] stroke-ultra-dark-grey group-hover:stroke-grey group-active:stroke-grey" />
      ),
      href: 'https://www.livroreclamacoes.pt/Inicio/',
      title: t('layout.footer.complaint_book'),
      className:
        'text-ultra-dark-grey group-hover:text-grey group-active:text-grey',
    },
  ]
}

export const getFooterLinks = (t: TFunction) => {
  const FOOTER_LINKS: Readonly<IconLinkButtonProps[]> = [
    {
      href: LINK_CONDITIONS,
      title: t('layout.footer.terms_and_conditions'),
      className: 'text-ultra-dark-grey',
    },
    {
      href: LINK_PRIVACY_POLICY,
      title: t('layout.footer.privacy_policy'),
      className: 'text-ultra-dark-grey',
    },
  ]
  return FOOTER_LINKS
}

export const STORES_LINKS: Readonly<
  (LinkProps & { Icon: JSX.Element; type: ExternalLink })[]
> = [
  {
    type: 'app_store',
    href: LINK_APP_STORE,
    Icon: (
      <AppStoreIllustration className="h-[49px] w-[147px] lg:h-[59px] lg:w-[178px]" />
    ),
  },
  {
    type: 'google_play',
    href: LINK_PLAY_MARKET,
    Icon: (
      <GooglePlayIllustration className="h-[49px] w-[165px] lg:h-[59px] lg:w-[200px]" />
    ),
  },
]
