import { ArrowRightIcon } from '@assets/icons'
import { Transition } from '@headlessui/react'
import { classnames } from '@tools/common'
import 'embla-carousel'
import Autoplay, { AutoplayOptionsType } from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { FC, useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import ViewAllButton from '../components/ViewAllButton'
import { useRouter } from 'next/router'

const autoplayRoot = (emblaRoot: any) => emblaRoot.parentElement

export interface SlideProps {
  image?: string
  images?: string[]
  href?: string
  name?: string
}

export interface ImageCarouselProps {
  hideTitle?: boolean
  slides: SlideProps[]
  autoplay?: boolean
  speed?: number
  className?: string
  titleClassName?: string
  containerClassName?: string
  onImageLoad?: () => void
  isMultipleProductImages?: boolean
}

interface DotButtonProps {
  selected: boolean
  onClick?: () => void
  className?: string
}

export const DotButton = ({ selected, onClick, className }: DotButtonProps) => (
  <button
    className={classnames(
      'h-3 w-3 rounded-full bg-black opacity-50',
      {
        'bg-white': selected,
      },
      className,
    )}
    type="button"
    onClick={onClick}
  />
)

interface ButtonProps {
  enabled: boolean
  onClick?: () => void
}

const PrevButton = ({ enabled, onClick }: ButtonProps) => (
  <button
    className="absolute top-[calc(50%-15px)] -left-2 z-10 cursor-pointer rounded-full border border-ultra-light-grey bg-white p-2.5 shadow-lg lg:-left-5"
    onClick={onClick}
    disabled={!enabled}
  >
    <ArrowRightIcon className="h-4 w-4 rotate-180 fill-transparent  stroke-ultra-dark-grey" />
  </button>
)

const NextButton = ({ enabled, onClick }: ButtonProps) => (
  <button
    className="absolute -right-2 top-[calc(50%-15px)] z-10 cursor-pointer rounded-full border border-ultra-light-grey bg-white p-2.5 shadow-lg lg:-right-5"
    onClick={onClick}
    disabled={!enabled}
  >
    <ArrowRightIcon className="h-4 w-4 fill-transparent stroke-ultra-dark-grey" />
  </button>
)

const CarouselButtonAnimation: FC<{ show: boolean; className?: string }> = ({
  children,
  show,
  className,
}) => {
  return (
    <Transition
      show={show}
      enter="transition duration-200"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-200"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      className={className}
    >
      {children}
    </Transition>
  )
}

const ImageCarousel = ({
  slides,
  autoplay: baseAutoplay = false,
  speed = 2000,
  className,
  titleClassName,
  containerClassName,
  onImageLoad,
  isMultipleProductImages,
}: ImageCarouselProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const options: AutoplayOptionsType = { delay: speed }

  const autoplay = Autoplay(options, autoplayRoot)

  const [viewportRef, embla] = useEmblaCarousel(
    { loop: false },
    baseAutoplay ? [autoplay] : undefined,
  )

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollTo = useCallback(index => embla?.scrollTo(index), [embla])

  useEffect(() => {
    const onSelect = () => {
      if (embla) {
        setPrevBtnEnabled(embla.canScrollPrev())
        setNextBtnEnabled(embla.canScrollNext())
        setSelectedIndex(embla.selectedScrollSnap())
      }
    }

    embla?.on('select', onSelect)

    onSelect()

    return () => {
      embla?.off('select', onSelect)
    }
  }, [embla])

  const onClickPrev = useCallback(() => embla?.scrollPrev(), [embla])
  const onClickNext = useCallback(() => embla?.scrollNext(), [embla])
  const onClickDot = (index: number) => scrollTo(index)

  if (slides.length === 0) {
    return null
  }

  return (
    <section className={containerClassName}>
      {!isMultipleProductImages && (
        <div className="flex items-center justify-between font-semibold lg:justify-start">
          <h2
            className={classnames(
              'my-2.5 w-fit text-lg font-semibold text-ultra-dark-grey lg:my-5 lg:text-2xl lg:font-bold',
              titleClassName,
            )}
          >
            {t('pages.home.sections.special_offers')}
          </h2>
          <ViewAllButton onClick={() => router.push('/offers')} />
        </div>
      )}

      <div className="relative right-2.5 w-[calc(100%+20px)] cursor-pointer lg:right-auto lg:w-full">
        <div className="overflow-hidden lg:rounded-medium" ref={viewportRef}>
          <div
            className={classnames(
              'relative left-2.5 flex w-[200px] xs:w-[240px] xm:w-[290px] ml:w-[340px] sm:w-[430px] md:w-[530px] lg:left-auto lg:right-auto lg:w-[400px] xl:w-[510px]',
              {
                'xm:w-[290px] ml:w-[290px] sm:w-[290px] md:w-[290px] lg:w-[290px] xl:w-[290px]':
                  isMultipleProductImages,
              },
            )}
          >
            {!isMultipleProductImages &&
              slides!.map(({ image, href }, index) => (
                <div
                  key={index}
                  className="mr-2.5 min-w-full last:mr-0 lg:mr-5"
                >
                  <Link href={href!}>
                    <div
                      className={classnames(
                        'relative overflow-hidden',
                        className,
                      )}
                    >
                      <Image
                        className="rounded-medium"
                        layout="responsive"
                        objectFit="cover"
                        src={image!}
                        width={530}
                        height={260}
                        priority
                        loader={({ src }) => src}
                      />
                    </div>
                  </Link>
                </div>
              ))}

            {isMultipleProductImages &&
              slides!.map(({ images, name }, index) =>
                images!.map(image => (
                  <div key={index} className="mx-2 min-w-full">
                    <Image
                      className="rounded-medium"
                      layout="responsive"
                      objectFit="contain"
                      src={image}
                      width={290}
                      height={290}
                      alt={name}
                      priority
                      onLoad={onImageLoad}
                      loader={({ src }) => src}
                    />
                  </div>
                )),
              )}
          </div>
        </div>

        {isMultipleProductImages && (
          <>
            <CarouselButtonAnimation
              className="absolute top-[calc(50%-15px)] z-10"
              show={!!embla?.canScrollPrev()}
            >
              <PrevButton onClick={onClickPrev} enabled={prevBtnEnabled} />
            </CarouselButtonAnimation>

            <CarouselButtonAnimation
              className="absolute top-[calc(50%-15px)] right-0 z-10"
              show={!!embla?.canScrollNext()}
            >
              <NextButton onClick={onClickNext} enabled={nextBtnEnabled} />
            </CarouselButtonAnimation>
          </>
        )}

        {!isMultipleProductImages && (
          <span className="absolute bottom-5 z-10 hidden w-full justify-center lg:flex">
            {slides.map((_, index) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                onClick={() => onClickDot(index)}
                className="mr-3 last:mr-0"
              />
            ))}
          </span>
        )}
      </div>
    </section>
  )
}

export default ImageCarousel

interface ImageCarouselSkeletonProps {
  className?: string
  titleClassName?: string
}

export const ImageCarouselSkeleton = ({
  className,
  titleClassName,
}: ImageCarouselSkeletonProps) => {
  return (
    <div className={className}>
      <Skeleton
        inline
        className={classnames(
          'mb-2.5 h-7 w-1/3 rounded-medium lg:mb-5',
          titleClassName,
        )}
      />

      <Skeleton
        inline
        className="grid h-[117px] w-full rounded-medium xm:h-[142px] ml:h-[166px] md:h-[260px] lg:h-[196px] lg:grid-cols-[6fr_1fr] xl:h-[250px] 2xl:h-[260px]"
      />
    </div>
  )
}
