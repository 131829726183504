import { useFeeback, useOrder, useUser } from '@api'
import { CheckmarkIcon } from '@assets/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import type { FeedbackType } from '@models/orders'
import { classnames } from '@tools/common'
import { EmojiStatus, Input, PrimaryButton } from '@uikit/molecules'
import { safeMetadata } from '@uikit/organisms/Metadata'
import { serverTimestamp } from 'firebase/firestore'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useModals } from '..'
import { BaseModal, BaseModalProps } from '../components'
import getValidationScheme from './validation'

export interface FeedbackModalProps extends BaseModalProps {
  orderId: string
}

const header = () => {
  return null
}

const FeedbackModal = ({ orderId, visible, onClose }: FeedbackModalProps) => {
  const { t } = useTranslation()

  const { setModal } = useModals()

  const userQuery = useUser()
  const orderQuery = useOrder(orderId)
  const feedbackQuery = useFeeback()

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [emojiType, setEmojiType] = useState<
    Lowercase<FeedbackType> | undefined
  >()

  const { control, handleSubmit } = useForm<{ comment: string }>({
    mode: 'onTouched',
    resolver: zodResolver(getValidationScheme(t)),
    defaultValues: { comment: '' },
  })

  const onClickThumbsUp = () => {
    setEmojiType('thumbs_up')
    setCurrentStep(1)
  }

  const onClickThumbsDown = () => {
    setEmojiType('thumbs_down')
    setCurrentStep(1)
  }

  const getTitle = () => {
    if (currentStep === 0 || currentStep === 1) {
      return t('ui.modals.feedback.header')
    }

    if (currentStep === 2) {
      return t('ui.modals.feedback.conclusion')
    }
  }

  const onSubmit = handleSubmit(async data => {
    await feedbackQuery.mutations.sendOrdersFeedback.mutateAsync({
      order_id: orderId,
      feedback: emojiType!,
      user_id: userQuery.data!.id,
      phone: userQuery.data!.phone,
      comment: data.comment,
      date: serverTimestamp(),
    })

    setCurrentStep(2)

    setTimeout(() => {
      setModal(null)
    }, 2000)
  })

  return (
    <BaseModal
      {...{
        visible,
        onClose,
        metadata: safeMetadata(
          orderQuery,
          orderQuery => ({
            title: t('ui.modals.feedback.title', {
              ordernumber: orderQuery.data?.ordernumber,
            }),
            description: t('ui.modals.feedback.desciption', {
              ordernumber: orderQuery.data?.ordernumber,
            }),
          }),
          t,
        ),
      }}
      header={{ ...header, withBorder: false }}
    >
      <div
        className={classnames('px-6 pb-6', { 'pb-[51px]': currentStep === 2 })}
      >
        <h3
          className={classnames('text-2xl font-bold text-ultra-dark-grey', {
            'text-center': currentStep === 2,
          })}
        >
          {getTitle()}
        </h3>

        {(currentStep === 0 || currentStep === 1) && (
          <div className="grid grid-cols-2 py-6">
            <EmojiStatus
              selected={emojiType === 'thumbs_up'}
              type="feedback"
              emojiType="THUMBS_UP"
              onClick={onClickThumbsUp}
            />

            <EmojiStatus
              selected={emojiType === 'thumbs_down'}
              type="feedback"
              emojiType="THUMBS_DOWN"
              onClick={onClickThumbsDown}
            />
          </div>
        )}

        {currentStep === 1 && (
          <>
            <p className="mb-4 text-sm text-ultra-dark-grey">
              {t(`ui.modals.feedback.${emojiType!}`)}
            </p>

            <form id="feedback-form" onSubmit={onSubmit}>
              <Input
                name="comment"
                control={control}
                textArea
                placeholder={t('ui.modals.feedback.form.comment.placeholder')}
              />
            </form>

            <PrimaryButton
              type="submit"
              form="feedback-form"
              title={t('ui.modals.feedback.buttons.confirm')}
              onClick={onSubmit}
              iconPosition="right"
              loading={feedbackQuery.mutations.sendOrdersFeedback.isLoading}
              Icon={<CheckmarkIcon className="fill-white" />}
              titleClassName="text-white text-center"
              className="mt-5 w-full rounded-medium bg-pink disabled:bg-light-pink"
            />
          </>
        )}
      </div>
    </BaseModal>
  )
}

export default FeedbackModal
