import { MAX_PRODUCT_COUNT } from '@api'
import { MinusIcon, PlusIcon } from '@assets/icons'
import { classnames } from '@tools/common'

export interface InlineAmountSelectorProps {
  value: number
  min?: number
  max?: number
  onAdd: () => void
  onSub: () => void
  className?: string
}

const InlineAmountSelector = ({
  value,
  min = 0,
  max = MAX_PRODUCT_COUNT,
  onAdd: onBaseAdd,
  onSub: onBaseSub,
  className,
}: InlineAmountSelectorProps) => {
  const onAdd = value < max ? onBaseAdd : undefined
  const onSub = value > min ? onBaseSub : undefined

  return (
    <div
      className={classnames(
        'group w-full cursor-pointer select-none rounded-xsmall bg-ultra-light-grey px-[6px] text-center lg:px-[2.5px]',
        className,
      )}
    >
      <div className="flex-2 flex items-center justify-between">
        <div
          onClick={onSub}
          className={classnames(
            'flex flex-1 rounded-md py-[12px] pl-[5px] lg:py-[7px]',
            {
              'opacity-50': value === 0,
            },
          )}
        >
          <MinusIcon className="h-[8.5px] w-[8.5px] stroke-ultra-dark-grey stroke-[1.5px]" />
        </div>

        <p className="text-sm font-medium text-ultra-dark-grey">
          {value.toString()}
        </p>

        <div
          onClick={onAdd}
          className={classnames(
            'flex flex-1 justify-end rounded-md py-[12px] pr-[5px] lg:py-[7px]',
            { 'opacity-50': value >= max },
          )}
        >
          <PlusIcon className="h-[8.5px] w-[8.5px] stroke-ultra-dark-grey stroke-[1.5px]" />
        </div>
      </div>
    </div>
  )
}

export default InlineAmountSelector
