import classnames from 'classnames'

export interface TextIconRowProps {
  title: string
  titleClassName?: string
  icon: JSX.Element
  iconPosition?: 'left' | 'right'
  iconVisible?: boolean
  className?: string
  onClick?: () => void
}

const TextIconRow = ({
  title,
  titleClassName,
  icon,
  iconPosition = 'right',
  iconVisible = true,
  className,
  onClick,
}: TextIconRowProps) => {
  const showIcon = iconVisible && title.length > 0

  return (
    <div
      className={classnames(
        'flex flex-row items-center justify-between rounded bg-white p-5',
        { 'cursor-pointer': onClick },
        className,
      )}
      onClick={onClick}
    >
      <div className="flex flex-row items-center">
        {showIcon && iconPosition === 'left' && (
          <span className="mr-2.5">{icon}</span>
        )}

        <p
          className={classnames(
            'text-base font-normal text-ultra-dark-grey',
            titleClassName,
          )}
        >
          {title}
        </p>
      </div>

      {showIcon && iconPosition === 'right' ? (
        <span className="ml-2.5">{icon}</span>
      ) : null}
    </div>
  )
}

export default TextIconRow
