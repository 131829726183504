import { useLastActiveOrder, useUser, useUserSetup, useWarehouses } from '@api'
import { useAnalytics, useIntercomAnalyticsEvents, useQueryError } from '@hooks'
import { PageBody, PageFooter, PageHeader } from '@uikit/organisms'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

const Layout: FC = ({ children }) => {
  const router = useRouter()

  useQueryError()

  useLastActiveOrder()

  useUserSetup()

  const userQuery = useUser()
  const warehouseQuery = useWarehouses()

  useAnalytics([], [], analytics => analytics.sessionStart())

  useIntercomAnalyticsEvents()

  useEffect(() => {
    if (
      warehouseQuery.isSuccess &&
      userQuery.isSuccess &&
      warehouseQuery.data.activeWarehouse.warehouse_status_object.status &&
      userQuery.data.selected_address
    ) {
      warehouseQuery.tools.setActiveStatus()
    }
  }, [
    userQuery.data?.selected_address,
    warehouseQuery.data?.activeWarehouse.warehouse_status_object.status,
  ])

  if (router.pathname === '/404' || router.pathname === '/500') {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center">
        {children}
      </div>
    )
  }

  return (
    <div className="mx-auto flex min-h-screen flex-col lg:px-4 xl:container">
      <PageHeader />
      <PageBody>{children}</PageBody>
      <PageFooter />
    </div>
  )
}

export default Layout
