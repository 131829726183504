import { useUser } from '@api'
import { CartIcon } from '@assets/icons'
import { Cart } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useModals } from '..'
import type { ModalHeaderProps } from '../components'
import BaseModal, { BaseModalProps } from '../components/BaseModal'

export interface CartModalProps extends BaseModalProps {}

const CartModal = ({ visible, onClose }: CartModalProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const { setModal } = useModals()

  const userQuery = useUser()

  const onCloseLoginModal = () => {
    setModal(null)

    router.replace('/')
  }

  const onSuccessLogin = () => {
    setModal({ name: 'cart', props: {} })
  }

  useEffect(() => {
    if (userQuery.data!.anonymous) {
      setModal({
        name: 'login',
        props: {
          onClose: onCloseLoginModal,
          onSuccess: onSuccessLogin,
        },
      })
    }
  }, [userQuery.data])

  const header: Omit<ModalHeaderProps, 'onClose'> = {
    Icon: <CartIcon className="h-4 w-4 stroke-black stroke-[1.5px]" />,
    title: t('ui.modals.cart.header'),
  }

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.cart.title'),
          description: t('ui.modals.cart.description'),
        },
        className: 'lg:w-full',
        containerClassName: 'md:max-w-md',
        dialogClassName: 'overflow-y-hidden',
      }}
    >
      <Cart hideTitle className="max-h-[calc(100vh-275px)]" />
    </BaseModal>
  )
}

export default CartModal
