import httpClient from '@api/httpClient'
import { queryClient } from '@api/queryClient'
import type {
  Cart,
  CartInfo,
  CartProduct,
  CartResponse,
  ChangeCartProduct,
  Checkout,
  CheckoutProps,
  Promocode,
  RecommendationsResp,
  UpdateCartProps,
  UpdateProductProps,
} from '@models/cart'
import type { Coordinates } from '@models/map'
import type { Product } from '@models/products'
import type { User } from '@models/user'
import type { AxiosResponse } from 'axios'
import { omit } from 'lodash'
import { productsApi, productsKeys } from '../products'
import { selectUserData, usersKeys } from '../users'
import { cartKeys, cartUrls } from './constants'

const fetchAndSetProduct = async (id: string, coords?: Coordinates) => {
  let product = queryClient.getQueryData<Product>(productsKeys.root(id, coords))

  if (!product) {
    product = await productsApi.getProduct(id, coords)
    queryClient.setQueryData(productsKeys.root(id, coords), product)
  }

  return product
}

const fetchProducts = async (data: CartResponse): Promise<CartProduct[]> => {
  const { coords } = selectUserData(
    queryClient.getQueryData<User>(usersKeys.root)!,
  )

  return Promise.all(
    data.cart.products.map<Promise<CartProduct>>(async cartProduct => {
      const product = await fetchAndSetProduct(cartProduct.id, coords)

      return {
        ...omit(cartProduct, 'price'),
        cartPrice: cartProduct.price,
        ...product,
      }
    }),
  )
}

const getCart = async (signal?: AbortSignal): Promise<Cart> => {
  const { data } = await httpClient.get<CartResponse>(cartUrls.root, {
    signal,
  })

  return {
    ...data,
    cart: { ...data.cart, products: await fetchProducts(data) },
  }
}

const getNewCartProducts = ({ id, action, source }: UpdateProductProps) => {
  const {
    cart: { products },
  } = queryClient.getQueryData<CartResponse>(cartKeys.root)!

  let count = 0

  const product = products.find(product => product.id === id)

  if (action === 'add') {
    count = product ? product.count + 1 : 1
  }

  if (action === 'sub') {
    count = product ? product.count - 1 : 0
  }

  if (action === 'delete') {
    count = 0
  }

  let newItems = products.map<ChangeCartProduct>(product => ({
    id: product.id,
    count: product.count,
    source: product.source,
  }))

  if (product) {
    newItems = newItems.filter(product => product.id !== id)
  }

  if (count >= 0) {
    newItems = [
      ...newItems,
      { id, count, source: product ? product.source : source },
    ]
  }

  return newItems
}

const updateProduct = async (props: UpdateProductProps) => {
  const newProducts = getNewCartProducts(props)

  const user = selectUserData(queryClient.getQueryData<User>(usersKeys.root)!)

  const { data } = await httpClient.put<
    CartInfo,
    AxiosResponse<CartResponse>,
    UpdateCartProps
  >(cartUrls.root, {
    products: newProducts,
    latitude: user.coords!.lat,
    longitude: user.coords!.long,
  })
  return {
    ...data,
    cart: { ...data.cart, products: await fetchProducts(data) },
  }
}

const updateCart = async (props?: UpdateCartProps) => {
  const user = selectUserData(queryClient.getQueryData<User>(usersKeys.root)!)

  const { data } = await httpClient.put<
    CartInfo,
    AxiosResponse<CartResponse>,
    UpdateCartProps
  >(cartUrls.root, {
    ...props,
    latitude: user.coords!.lat,
    longitude: user.coords!.long,
  })
  return {
    ...data,
    cart: { ...data.cart, products: await fetchProducts(data) },
  }
}

const checkout = async (props: CheckoutProps) => {
  const { data } = await httpClient.post<Checkout, AxiosResponse<Checkout>>(
    cartUrls.checkout,
    null,
    { params: props },
  )
  return data
}

const getActivePromocodes = async () => {
  const {
    data: { promocodes },
  } = await httpClient.get<{ promocodes: Promocode[] }>(cartUrls.promocode)
  return promocodes
}

const getRecommendations = async (
  product_ids: string[],
): Promise<Product[]> => {
  const {
    data: { product_ids: recommendations },
  } = await httpClient.post<RecommendationsResp>(cartUrls.recommendations, {
    product_ids,
  })

  const { coords } = selectUserData(
    queryClient.getQueryData<User>(usersKeys.root)!,
  )

  const products = await Promise.all(
    (recommendations ?? []).map(async productId =>
      fetchAndSetProduct(productId, coords),
    ),
  )

  return products
}

const setTimeslot = async (id: string) => {
  const { data } = await httpClient.put(cartUrls.root, {
    time_slot: { id },
  })

  return data
}

export default {
  getRecommendations,
  setTimeslot,
  getCart,
  updateCart,
  getNewCartProducts,
  updateProduct,
  checkout,
  getActivePromocodes,
}
