import { useCart } from '@api'
import { CartIcon } from '@assets/icons'
import { PrimaryButton } from '@uikit/molecules'
import { ProductAmountIndicator } from '@uikit/organisms/CartPopover'
import { useModals } from '@uikit/organisms/modals'

export interface CartButtonProps {
  className?: string
}

const CartButton = ({ className }: CartButtonProps) => {
  const cartQuery = useCart()

  const { setModal } = useModals()

  const onClick = () => {
    setModal({ name: 'cart', props: {} })
  }

  return (
    <span className={className}>
      <PrimaryButton
        secondary
        Icon={<CartIcon className="h-8 w-8 stroke-ultra-dark-grey" />}
        size="small"
        className="flex bg-white p-3 shadow-lg"
        onClick={onClick}
      />

      {cartQuery.data?.productsCount! > 0 && (
        <ProductAmountIndicator amount={cartQuery.data!.productsCount!} />
      )}
    </span>
  )
}

export default CartButton
