import { classnames } from '@tools/common'
import Link from 'next/link'
import type { HTMLAttributeAnchorTarget } from 'react'

export interface IconLinkButtonProps {
  href: string
  target?: HTMLAttributeAnchorTarget
  title?: string
  Icon?: JSX.Element
  roundedIcon?: boolean
  containerClassName?: string
  className?: string
}

const IconLinkButton = ({
  href,
  target,
  Icon,
  title,
  roundedIcon,
  containerClassName,
  className,
}: IconLinkButtonProps) => {
  return (
    <Link href={href}>
      <a
        target={target}
        className={classnames(
          'group items-center hover:text-dark-pink active:text-ultra-dark-pink disabled:text-grey lg:inline-flex',
          containerClassName,
        )}
      >
        <div
          className={classnames({
            'rounded-full bg-ultra-light-grey p-3.5': roundedIcon,
          })}
        >
          {Icon}
        </div>
        {title && (
          <p
            className={classnames(
              'text-sm text-grey lg:text-base',
              { 'ml-2': Icon },
              className,
            )}
          >
            {title}
          </p>
        )}
      </a>
    </Link>
  )
}

export default IconLinkButton
