import { classnames } from '@tools/common'

export interface LinkProps {
  title: string
  href?: string
}

export interface LinksTextProps {
  links: LinkProps[]
  linkClassName?: string
  className?: string
}

const getTitle = (link: LinkProps, last: boolean) => {
  const title = ` ${link.title} `

  return last ? title.trimEnd() : title
}

const LinksText = ({ links, className, linkClassName }: LinksTextProps) => {
  return (
    <p className={classnames('text-sm text-grey', className)}>
      {links.map((link, index, { length }) =>
        link.href ? (
          <span key={index} className={classnames('text-black', linkClassName)}>
            <a href={link.href} target="_blank">
              {getTitle(link, index === length - 1)}
            </a>
          </span>
        ) : (
          link.title
        ),
      )}
    </p>
  )
}

export default LinksText
