import { useCategories, useCategoryProducts, useWarehouses } from '@api'
import { ArrowDownIcon } from '@assets/icons'
import { Transition } from '@headlessui/react'
import { useAnalytics } from '@hooks'
import type { CategorySection } from '@models/categories'
import { classnames } from '@tools/common'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Link as ScrollLink } from 'react-scroll'

const CategoryMenuAnimation: FC<{ active: boolean }> = ({
  children,
  active,
}) => {
  return (
    <Transition
      className="scroll-fix"
      show={active}
      enter="transition-[max-height] duration-[600ms] ease-in overflow-hidden"
      enterFrom="transform max-h-0"
      enterTo="transform max-h-[1000px]"
      leave="transition-[max-height] duration-[600ms] ease-out overflow-hidden"
      leaveFrom="transform max-h-[1000px]"
      leaveTo="transform max-h-0"
    >
      {children}
    </Transition>
  )
}

export interface CategoryMenuCardProps {
  category: CategorySection
  className?: string
}

const CategoryMenuCard = ({
  category: { id, media, name, subcategories },
  className,
}: CategoryMenuCardProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const { categoryId } = router.query as { categoryId: string }

  const warehousesQuery = useWarehouses()
  const categoriesQuery = useCategories()
  const productsQuery = useCategoryProducts(categoryId)

  const [active, setActive] = useState(false)
  const [activeSubcategoryId, setActiveSubcategoryId] = useState(categoryId)

  const analytics = useAnalytics(
    [warehousesQuery, categoriesQuery, productsQuery],
    ['subcategoryClick'],
  )

  useEffect(() => {
    if (categoryId === id && !active) {
      setActive(true)
    }

    if (categoryId !== id) {
      setActive(false)
    }
  }, [categoryId])

  const onToggleCategory = () => {
    setActive(prev => !prev)

    if (categoryId !== id) {
      router.push(`/categories?categoryId=${id}`, undefined, {
        shallow: true,
      })
    }
  }

  return (
    <li id={id} className="flex flex-col">
      <button
        className={classnames(
          'group box-border flex cursor-pointer appearance-none items-center justify-between focus:outline-none',
          className,
        )}
        onClick={onToggleCategory}
      >
        <div className="flex items-center">
          <div className="mr-[15px] shrink-0">
            <Image
              width={50}
              height={50}
              objectFit="contain"
              src={media.medium}
              alt={name}
              loader={({ src }) => src}
              priority
            />
          </div>

          <h3 className="text-left text-base font-medium text-ultra-dark-grey">
            {name}
          </h3>
        </div>

        <ArrowDownIcon
          className={classnames(
            'mr-1 inline-block h-[6px] w-[12px] stroke-ultra-dark-grey stroke-2',
            {
              'ease transform  duration-700': active,
              'ease invisible rotate-90 transform opacity-0 duration-700 group-hover:visible group-hover:opacity-100 group-active:rotate-0':
                !active,
            },
          )}
        />
      </button>

      <CategoryMenuAnimation active={active}>
        {subcategories?.map(subcategory => (
          <div key={subcategory.id} className="mb-[3px] ml-20">
            <ScrollLink
              to={subcategory.id}
              duration={0}
              spy
              smooth
              activeClass="active"
              onSetActive={() => setActiveSubcategoryId(subcategory.id)}
              onClick={() => {
                analytics.subcategoryClick({ subcategoryId: subcategory.id, t })
              }}
              className={classnames(
                'flex cursor-pointer select-none pb-3 text-left text-base font-medium text-ultra-dark-grey',
                {
                  'hover:text-light-pink':
                    subcategory.id !== activeSubcategoryId,
                },
              )}
            >
              {subcategory.name}
            </ScrollLink>
          </div>
        ))}
      </CategoryMenuAnimation>
    </li>
  )
}

export default CategoryMenuCard
