import httpClient from '@api/httpClient'
import { queryClient, RequestError } from '@api/queryClient'
import type { Coordinates } from '@models/map'
import type { Product } from '@models/products'
import { groupBy } from 'lodash'
import { productsKeys, productsUrls } from './constants'

const getProductsByCategories = async (
  categoriesIds?: string[],
  coords?: Coordinates,
) => {
  if (!categoriesIds) {
    throw new RequestError(404)
  }

  const productsResult = await Promise.all(
    (categoriesIds || []).map(async categoryId => {
      const {
        data: { data },
      } = await httpClient.get<{ data: Product[] }>(
        productsUrls.categoryProducts(),
        {
          params: { category_id: categoryId, limit: 200, ...coords },
        },
      )

      return (data || []).map<Product>(product => ({
        ...product,
        categoryId,
      }))
    }),
  )

  const products = productsResult.flat()

  for (const product of products) {
    queryClient.setQueryData(productsKeys.root(product.id, coords), product)
  }

  return new Map(
    Object.entries(groupBy(products, product => product.categoryId)),
  )
}

const getProduct = async (id: string, coords?: Coordinates) => {
  const { data } = await httpClient.get<Product>(productsUrls.root(id), {
    params: coords,
  })
  return data
}

export default {
  getProduct,
  getProductsByCategories,
}
