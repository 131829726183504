import httpClient from '@api/httpClient'
import type { FAQ } from '@models/faq'
import { faqUrls } from './constants'

const getFAQs = async () => {
  const { data } = await httpClient.get<{ faq: FAQ[] }>(faqUrls.root)
  return data.faq
}

export default { getFAQs }
