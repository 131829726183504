import {
  categoriesKeys,
  productsKeys,
  salesKeys,
  useCart,
  usersKeys,
} from '@api'
import type { User } from '@models/user'
import { useMutation, useQueryClient } from 'react-query'
import { addressesApi } from '.'

const useAddresses = () => {
  const queryClient = useQueryClient()

  const cartQuery = useCart()

  const invalidateQueries = () => {
    queryClient.invalidateQueries(categoriesKeys.all())
    queryClient.invalidateQueries(productsKeys.categoryProducts())
    queryClient.invalidateQueries(salesKeys.root())

    cartQuery.mutations.updateCart.mutate({})
  }

  const createAddress = useMutation(addressesApi.createAddress, {
    onSuccess: data => {
      queryClient.setQueryData<User>(usersKeys.root, user => ({
        ...user!,
        addresses: data,
        selected_address: data[0]?.id!,
      }))
      invalidateQueries()
    },
  })

  const updateAddress = useMutation(addressesApi.updateAddress, {
    onSuccess: data => {
      queryClient.setQueryData<User>(usersKeys.root, user => ({
        ...user!,
        addresses: data,
      }))
      invalidateQueries()
    },
  })

  const deleteAddress = useMutation(addressesApi.deleteAddress, {
    onSuccess: data => {
      queryClient.setQueryData<User>(usersKeys.root, user => ({
        ...user!,
        addresses: data,
        selected_address: data[0]?.id!,
      }))
      invalidateQueries()
    },
  })

  const mutations = {
    createAddress,
    updateAddress,
    deleteAddress,
  }

  return { mutations }
}

export default useAddresses
