import { useWarehouses } from '@api/resources/warehouses'
import { PhoneIcon } from '@assets/icons'
import { useAnalytics, useIntercom } from '@hooks'
import type { Courier } from '@models/orders'
import { classnames } from '@tools/common'
import { Label, PrimaryButton, SubtitledButton } from '@uikit/molecules'
import { Loading } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import type { ModalHeaderProps } from '../components'
import type { BaseModalProps } from '../components/BaseModal'
import BaseModal from '../components/BaseModal'

export interface ContactUsModalProps extends BaseModalProps {
  warehouseId?: string
  courier?: Courier
}

export const formatPhoneNumber = (number: string) =>
  number.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 ')

const ContactUsModal = ({
  visible,
  onClose,
  warehouseId,
  courier,
}: ContactUsModalProps) => {
  const { t } = useTranslation()

  const warehousesQuery = useWarehouses()

  const {
    enabled: intercomEnabled,
    available: intercomAvailable,
    actions,
  } = useIntercom()

  useAnalytics([], [], analytics => analytics.openContactUs())

  const warehouse = warehouseId
    ? warehousesQuery.data?.warehousesMap.get(warehouseId)!
    : warehousesQuery.data?.activeWarehouse

  const header: Omit<ModalHeaderProps, 'onClose'> = {
    Icon: <PhoneIcon className="h-4 w-4 stroke-black stroke-[1.5px]" />,
    title: courier
      ? t('ui.modals.contact_us.contact_courier')
      : t('ui.modals.contact_us.header'),
  }

  const onClickCall = () => {
    if (courier) {
      window.open(`tel://${courier.phone}`, '_self')
    }

    if (!courier && warehouse) {
      window.open(`tel://${warehouse.tel}`, '_self')
    }
  }

  const CallCourierRow = () => (
    <div className="mb-2.5 flex items-center justify-between">
      <Label
        title={
          courier
            ? formatPhoneNumber(courier.phone)
            : warehouse
            ? formatPhoneNumber(warehouse.tel)
            : undefined
        }
        subtitle={courier ? courier.name : warehouse?.name}
        className="mr-4"
        titleClassName="text-base font-medium"
        subtitleClassName="text-base font-normal"
      />

      <PrimaryButton
        onClick={onClickCall}
        Icon={
          <PhoneIcon className="h-4 w-4 fill-transparent stroke-white stroke-[1.5px]" />
        }
        iconPosition="left"
      />
    </div>
  )

  const onClickChatWithUs = () => actions.show()

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.contact_us.title'),
          description: t('ui.modals.contact_us.description'),
        },
      }}
      className="p-5"
    >
      <Loading
        type="common"
        loading={!warehousesQuery.data}
        loader="animation"
        size={40}
      >
        <>
          {(!intercomAvailable || !intercomEnabled) && <CallCourierRow />}

          <SubtitledButton
            title={t('ui.modals.contact_us.buttons.chat_with_support')}
            subtitle={
              intercomAvailable
                ? undefined
                : t('ui.modals.contact_us.buttons.not_available')
            }
            secondary
            onClick={onClickChatWithUs}
            titleClassName={classnames('font-medium', {
              'text-grey': !intercomAvailable,
            })}
          />
        </>
      </Loading>
    </BaseModal>
  )
}

export default ContactUsModal
