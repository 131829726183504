import httpClient from '@api/httpClient'
import { queryClient } from '@api/queryClient'
import type { AuthData, Setting, User } from '@models/user'
import type { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import { datenowApi } from '../datetime'
import { authUrls, usersKeys, usersUrls } from './constants'
import {
  clearAuthCredentials,
  getAuthCredentials,
  getClientToken,
  setAuthCredentials,
} from './tools'
import { sha256 } from 'js-sha256'

const createNewUser = async () => {
  const { accessToken, isNewToken } = getAuthCredentials()
  const clientToken = getClientToken()
  const { now: timeNow } = await datenowApi.getDateNow()

  const body = {
    clientID: 'web',
    deviceID: clientToken,
    timeNow,
  }

  const signature = sha256.hmac(
    process.env.NEXT_PUBLIC_WEB_SECRET,
    JSON.stringify(body),
  )

  const { data } = await httpClient.post<AuthData>(authUrls.create, {
    ...body,
    signature,
    ...(accessToken && !isNewToken
      ? {
          accesstoken: accessToken,
        }
      : {}),
  })

  setAuthCredentials(data)
}

const getNewAccessToken = async () => {
  const { accessToken, refreshToken } = getAuthCredentials()

  return httpClient.post<AuthData>(
    authUrls.refresh,
    {
      access: accessToken,
      refresh: refreshToken,
    },
    { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig,
  )
}

const sendAuthCode = async (phone: string) => {
  try {
    await httpClient.post<void>(authUrls.code, {
      phone,
    })
  } catch {
    return false
  }

  return true
}

const login = async (code: string) => {
  try {
    const { data } = await httpClient.post<AuthData>(authUrls.login, {
      code,
    })
    setAuthCredentials(data)
    queryClient.refetchQueries(usersKeys.root)
  } catch {
    return false
  }

  return true
}

const logout = async () => {
  clearAuthCredentials()
  createNewUser()
  queryClient.invalidateQueries()
}

const getUser = async () => {
  const { data } = await httpClient.get<User>(usersUrls.root)
  return data
}

const updateName = async (name: string) => {
  const { data } = await httpClient.put<User>(usersUrls.root, {
    name,
  })
  return data
}

const updateAddress = async (address_id: string) => {
  const { data } = await httpClient.put<User>(usersUrls.root, {
    address_id,
  })
  return data
}

const updateSetting = async ({ id, value }: Pick<Setting, 'id' | 'value'>) => {
  const {
    data: { settings },
  } = await httpClient.put<{ settings: Setting[] }>(usersUrls.settings, {
    [id]: value,
  })
  return settings
}

const addPromocode = async (code: string) => {
  await httpClient.post<void>(usersUrls.promocode, {
    code,
  })
  return code
}

export default {
  sendAuthCode,
  getNewAccessToken,
  login,
  logout,
  getUser,
  updateName,
  updateAddress,
  updateSetting,
  addPromocode,
  createNewUser,
}
