import { ArrowRightIcon } from '@assets/icons'
import { PrimaryButton } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

export interface ViewAllBudttonProps {
  onClick?: () => void
}

const ViewAllButton = ({ onClick }: ViewAllBudttonProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const onClickViewAll = () => {
    if (onClick) {
      onClick()

      return
    }

    router.push('/sales')
  }

  return (
    <PrimaryButton
      className="ml-5 rounded-full bg-green py-[5px] px-[15px] text-sm text-white hover:bg-dark-green active:bg-dark-green"
      title={t('pages.home.sections.view_all')}
      size="small"
      iconPosition="right"
      titleClassName="ml-[5px]"
      Icon={
        <ArrowRightIcon className="h-4 w-4 fill-transparent stroke-white stroke-2" />
      }
      onClick={onClickViewAll}
    />
  )
}

export default ViewAllButton
