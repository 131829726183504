import { useCart } from '@api'
import { classnames } from '@tools/common'
import { ListProductCard } from '@uikit/organisms'
import { useContextualRouting } from 'next-use-contextual-routing'

interface CartProductsListProps {
  className?: string
}

const CartProductsList = ({ className }: CartProductsListProps) => {
  const cartQuery = useCart()
  const { returnHref } = useContextualRouting()

  const onAdd = (productId: string) => {
    cartQuery.mutations.updateProduct.mutate({
      id: productId,
      action: 'add',
      source: returnHref,
    })
  }

  const onSub = (productId: string) => {
    cartQuery.mutations.updateProduct.mutate({
      id: productId,
      action: 'sub',
    })
  }

  const onDeleteProduct = (productId: string) => {
    cartQuery.mutations.updateProduct.mutate({
      id: productId,
      action: 'delete',
    })
  }

  return (
    <ul className={className}>
      {cartQuery.data!.products.map((product, index, { length }) => (
        <ListProductCard
          key={product.id}
          product={product}
          cartProduct={product}
          onAdd={onAdd}
          onSub={onSub}
          onDelete={onDeleteProduct}
          className={classnames('py-5', {
            'border-b border-ultra-light-grey': index !== length - 1,
          })}
        />
      ))}
    </ul>
  )
}

export default CartProductsList
