import { useCart } from '@api'
import { CartIcon } from '@assets/icons'
import { Popover, Transition } from '@headlessui/react'
import { classnames } from '@tools/common'
import { Cart } from '@uikit/organisms'
import type { FC } from 'react'
import { useState } from 'react'
import { useTimer } from 'use-timer'
import { useModals } from '../modals'

interface CartPopoverAnimationProps {
  show?: boolean
}

const CartPopoverAnimation: FC<CartPopoverAnimationProps> = ({
  children,
  show,
}) => (
  <Transition
    show={show}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
)

interface ProductAmountIndicatorProps {
  amount: number
}

export const ProductAmountIndicator = ({
  amount,
}: ProductAmountIndicatorProps) => (
  <div
    className={classnames(
      'absolute -bottom-1 right-0 flex h-5 w-5 items-center justify-center rounded-full bg-pink',
    )}
  >
    <p className="text-[10px] text-white">{amount}</p>
  </div>
)

export interface CartPopoverProps {
  className?: string
  containerClassName?: string
}

const CartPopover = ({ className, containerClassName }: CartPopoverProps) => {
  const { modal } = useModals()

  const cartQuery = useCart()

  const [cartOpen, setCartOpen] = useState(false)

  const { start, reset } = useTimer({
    initialTime: 1,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: () => {
      setCartOpen(false)
    },
  })

  const onOpenCart = () => {
    setCartOpen(true)
    reset()
  }

  const onCloseCart = () => {
    if (modal?.name === 'product' || modal?.name === 'deliveryTerms') {
      return
    }

    start()
  }

  return (
    <Popover
      as="div"
      className={classnames(
        'relative inline-block text-left',
        containerClassName,
      )}
    >
      {({ open }) => (
        <>
          <Popover.Button
            as="div"
            onClick={onOpenCart}
            onMouseEnter={onOpenCart}
            onMouseLeave={onCloseCart}
            className={classnames(
              'flex h-full cursor-pointer items-center justify-center rounded-medium border border-ultra-light-grey bg-white px-3 shadow-xl hover:bg-ultra-light-grey',
              {
                'bg-ultra-light-grey': open || cartOpen,
              },
              className,
            )}
          >
            <CartIcon className="h-8 w-8 stroke-ultra-dark-grey" />
          </Popover.Button>

          {cartQuery.data?.products.length! > 0 && (
            <ProductAmountIndicator amount={cartQuery.data!.productsCount!} />
          )}

          <CartPopoverAnimation show={cartOpen}>
            <Popover.Panel
              onMouseEnter={onOpenCart}
              onMouseLeave={onCloseCart}
              className="absolute right-0 top-[68px] z-30 w-[350px] overflow-hidden rounded-medium bg-ultra-light-grey shadow-xl focus:outline-none"
            >
              <Cart className="max-h-[calc(100vh-197px)]" hideTitle />
            </Popover.Panel>
          </CartPopoverAnimation>
        </>
      )}
    </Popover>
  )
}

export default CartPopover
