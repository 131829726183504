import { useUser } from '@api'
import type { Offer } from '@models/offers'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { QueryClient, useQuery } from 'react-query'
import offersApi from './actions'
import { offersKeys } from './constants'

const useOffers = () => {
  const userQuery = useUser()

  const query = useQuery<Offer[], AxiosError>(
    offersKeys.all(userQuery.data?.coords),
    () => offersApi.getOffers(userQuery.data?.coords),
    {
      enabled: userQuery.isSuccess,
      staleTime: ms('30m'),
    },
  )

  return query
}

export default useOffers

export const prefetchOffers = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery(offersKeys.all(), () => offersApi.getOffers())
}
