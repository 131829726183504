import type { Product } from '@models/products'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { cartApi } from '.'
import { cartKeys } from './constants'
import useCart from './useCart'
import { useRemoteConfig } from '../remoteConfig'

const MAX_RECOMMENDATIONS_COUNT = 15 as const

interface UseRecommendationsData {
  products: Product[]
  productIds: string[]
}

export const selectData = (data: Product[]): UseRecommendationsData => {
  const products = data
    .filter(
      (value): value is Product =>
        value?.stock !== 0 && value?.stock !== undefined,
    )
    .slice(0, MAX_RECOMMENDATIONS_COUNT)

  return {
    products,
    productIds: products.map(product => product.id),
  }
}

const useRecommendations = () => {
  const cartQuery = useCart()
  const remoteConfigQuery = useRemoteConfig()

  const query = useQuery<Product[], AxiosError, UseRecommendationsData>(
    cartKeys.recommendations,
    () => cartApi.getRecommendations(cartQuery.data!.productIds),
    {
      staleTime: ms('20m'),
      select: selectData,
      refetchOnWindowFocus: false,
      enabled:
        cartQuery.isSuccess &&
        remoteConfigQuery.isSuccess &&
        remoteConfigQuery.data.recommendationsEnabled,
      onError: error => {
        if (error.response?.status === 503) {
          return null
        }
      },
    },
  )

  return query
}
export default useRecommendations
