import type { TFunction } from 'next-i18next'
import { z } from 'zod'

export const MIN_LENGTH_CARD_NUMBER = 14
export const MAX_LENGTH_CARD_NUMBER = 19
export const CVV_LENGTH = 4
export const DATE_LENGTH = 7

export const twoNumbersMask = [/\d/, /\d/]
export const threeNumbersMask = [/\d/, /\d/, /\d/]
export const fourNumbersMask = [/\d/, /\d/, /\d/, /\d/]
const nineNumbersMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const CREDITCARD_REGEX: (RegExp | string)[] = [
  ...fourNumbersMask,
  ' ',
  ...fourNumbersMask,
  ' ',
  ...fourNumbersMask,
  ' ',
  ...fourNumbersMask,
  ' ',
  ...threeNumbersMask,
]

export const EXPIRE_DATE_REGEX: (RegExp | string)[] = [
  ...twoNumbersMask,
  ' ',
  '/',
  ' ',
  ...twoNumbersMask,
]

export const NIF_REGEX: (RegExp | string)[] = [...nineNumbersMask]

export const MBWAY_REGEX: (RegExp | string)[] = [
  '+',
  ...threeNumbersMask,
  ' ',
  ...threeNumbersMask,
  ' ',
  ...threeNumbersMask,
  ' ',
  ...threeNumbersMask,
  ' ',
  ...threeNumbersMask,
  ' ',
  ...twoNumbersMask,
]

export default (t: TFunction) =>
  z.object({
    address: z.string(),
    apt: z.string().optional(),
    floor: z.string().optional(),
    entrance: z.string().optional(),
    selectedAnswers: z.array(z.string()),
    comment: z.string().optional(),
    nif: z
      .string()
      .optional()
      .refine(
        data => {
          if (data) {
            const nif = data.replace(/\D/g, '')
            if (nif.length < 9) {
              return false
            }
          }

          return true
        },
        { message: t('pages.checkout.errors.nif') },
      ),
    paymentMethod: z.string().nonempty(),
  })
