import { useIntercom, usePageHeaderVisible } from '@hooks'
import { classnames } from '@tools/common'
import { getCartVisible, getScrollToTopButtonVisible } from '@tools/layout'
import {
  Cart,
  CartPopover,
  CategoriesNavList,
  ScrollToTopButton,
} from '@uikit/organisms'
import CartButton from '@uikit/organisms/Cart/components/CartButton'
import { useNotifications } from '@uikit/organisms/notifications'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FC } from 'react'
import CookieConsent from 'react-cookie-consent'

const PageBody: FC = ({ children }) => {
  const router = useRouter()

  const { t } = useTranslation()

  const { notification } = useNotifications()

  // const { showLoading, setShowLoading } = useShowLoading()
  // const onClickShowLoading = () => setShowLoading(prev => !prev)

  const { showed: intercomShowed } = useIntercom()

  const cartVisible = getCartVisible(router.pathname)
  const headerVisible = usePageHeaderVisible()

  return (
    <section
      className={classnames(
        'flex flex-col px-2.5 lg:grid lg:grid-cols-[1fr_3.1fr] lg:px-0',
        {
          'lg:grid-cols-[1fr_minmax(0px,_2fr)_minmax(0px,_1.4fr)] xl:grid-cols-[1fr_minmax(0px,_2fr)_minmax(0px,_1.1fr)]':
            cartVisible,
        },
      )}
    >
      <CategoriesNavList />

      {router.pathname.includes('checkout') && (
        <Cart containerClassName="lg:hidden" />
      )}

      <main className="lg:mx-7">{children}</main>

      {cartVisible && <Cart containerClassName="hidden lg:inline" />}

      {!getCartVisible(router.pathname) && headerVisible && (
        <CartPopover
          containerClassName="hidden lg:inline z-40 fixed right-5 top-5"
          className="py-3 shadow-lg"
        />
      )}

      <CartButton
        className={classnames(
          'fixed bottom-5 right-5 z-40 transition duration-700 lg:hidden',
          {
            hidden: router.pathname.includes('checkout'),
            'bottom-24': notification?.name === 'popup' || intercomShowed,
          },
        )}
      />

      {getScrollToTopButtonVisible(router.asPath) && <ScrollToTopButton />}

      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText={t('pages.home.accept')}
        declineButtonText={t('pages.home.dont_allow')}
        declineButtonStyle={{
          background: '#FE036A',
          color: 'white',
          fontSize: '16px',
        }}
        cookieName="cookieConsent"
        style={{ background: 'white', color: '#404040' }}
        buttonStyle={{
          background: '#FE036A',
          color: 'white',
          fontSize: '16px',
        }}
        expires={150}
      >
        <span>
          {t('pages.home.cookie_consent')}
          <Link href="https://bairro.io/bairro/privacidade/">
            <a className="text-pink">{t('pages.home.privacy_policy')}</a>
          </Link>
        </span>
      </CookieConsent>

      {/* <div
        className={classnames(
          'fixed right-5 bottom-[96px] z-50 transition duration-700 ease-in-out lg:bottom-5',
          { 'bottom-5': router.pathname.includes('checkout') },
        )}
      >
        <PrimaryButton
          title={showLoading ? '✋' : '⌛'}
          secondary
          onClick={onClickShowLoading}
          className="px-5 shadow-xl"
        />
      </div> */}
    </section>
  )
}

export default PageBody
