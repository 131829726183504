import { ArrowUpIcon } from '@assets/icons'
import { SCROLL_TO_TOP_MIN_HEIGHT } from '@constants/layout'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { classnames } from '@tools/common'
import { useNotifications } from '@uikit/organisms/notifications'
import { useState } from 'react'
import { animateScroll } from 'react-scroll'

export interface ScrollToTopButtonProps {}

export const useScrollToTopButtonVisible = () => {
  const [buttonVisible, setButtonVisible] = useState<boolean>(false)

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y >= SCROLL_TO_TOP_MIN_HEIGHT) {
        setButtonVisible(true)
      }

      if (currPos.y < SCROLL_TO_TOP_MIN_HEIGHT) {
        setButtonVisible(false)
      }
    },
    [],
    undefined,
    true,
    500,
  )

  return buttonVisible
}

const onClick = () => animateScroll.scrollToTop()

// eslint-disable-next-line no-empty-pattern
const ScrollToTopButton = ({}: ScrollToTopButtonProps) => {
  const { notification } = useNotifications()

  const visible = useScrollToTopButtonVisible()

  return (
    <button
      onClick={onClick}
      className={classnames(
        'fixed left-5 bottom-5 z-40 hidden rounded-medium border border-ultra-light-grey bg-white p-5 shadow-lg transition duration-700 lg:hidden',
        { 'bottom-24': notification?.name === 'popup' },
        {
          hidden: !visible,
          'inline lg:hidden': visible,
        },
      )}
    >
      <ArrowUpIcon className="fill-ultra-dark-grey" />
    </button>
  )
}

export default ScrollToTopButton
