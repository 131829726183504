import httpClient from '@api/httpClient'
import { SELECTED_PAYMENT_ID_KEY } from '@constants/storage'
import type {
  AvailablePaymentMethods,
  CreateCardProps,
  CreateMBWayProps,
  CreatePaymentData,
  PaymentMethod,
  SelectPaymentMethodProps,
} from '@models/payments'
import * as ls from 'local-storage'
import { paymentsUrls } from './constants'

const getPaymentMethods = async () => {
  const { data: paymentMethods } = await httpClient.get<{
    paymentsMethod: PaymentMethod[]
  }>(paymentsUrls.root)

  const { data: availablePaymentMethods } =
    await httpClient.get<AvailablePaymentMethods>(paymentsUrls.available)

  return {
    paymentMethods: paymentMethods.paymentsMethod,
    availablePaymentMethods,
  }
}

const createCard = async (card: CreateCardProps) => {
  const { data } = await httpClient.post<CreatePaymentData>(
    paymentsUrls.card,
    card,
  )
  return data
}

const createMBWay = async ({ phoneNumber }: CreateMBWayProps) => {
  const { data } = await httpClient.post<CreatePaymentData>(
    paymentsUrls.mbway,
    { phoneNumber },
  )
  return data
}

const selectPaymentMethod = async (data: SelectPaymentMethodProps) => {
  if (!data.paymentsMethod) {
    return ''
  }

  if (data.paymentsMethod.some(method => method.id === data.id)) {
    ls.set<string>(SELECTED_PAYMENT_ID_KEY, data.id)
    return data.id
  }

  if (data.id === 'On delivery') {
    ls.set<string>(SELECTED_PAYMENT_ID_KEY, data.id)
    return data.id
  }

  ls.remove(SELECTED_PAYMENT_ID_KEY)
  return ''
}

export default {
  getPaymentMethods,
  createCard,
  createMBWay,
  selectPaymentMethod,
}
