import InternalServerError from '@pages/500'
import * as Sentry from '@sentry/browser'

interface ErrorFallbackProps {
  error: Error
  resetErrorBoundary: (...args: unknown[]) => void
}

const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  console.log(
    '❌ ErrorFallback ❌',
    JSON.stringify(error),
    error.message.includes('IDBDatabase'),
  )

  if (error.message.includes('IDBDatabase')) {
    Sentry.captureException(error)

    resetErrorBoundary()
  }

  Sentry.captureException(error)

  return <InternalServerError />
}

export default ErrorFallback
