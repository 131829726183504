import { v1 } from '@constants/api'
import { keyToUrl } from '@tools/common'

export const paymentsKeys = {
  root: [v1, 'payments'] as const,
  available: () => [...paymentsKeys.root, 'available'] as const,
  card: () => [...paymentsKeys.root, 'card'] as const,
  mbway: () => [...paymentsKeys.root, 'mbway'] as const,
}

export const paymentsUrls = {
  root: keyToUrl(paymentsKeys.root),
  available: keyToUrl(paymentsKeys.available()),
  card: keyToUrl(paymentsKeys.card()),
  mbway: keyToUrl(paymentsKeys.mbway()),
}
