import type { CategoriesList } from '@models/categories'
import { CategoryCard } from '@uikit/organisms'
import { range } from 'lodash'
import { useTranslation } from 'next-i18next'
import Skeleton from 'react-loading-skeleton'

export interface CategoriesListProps {
  categories: CategoriesList
  className?: string
}

const CategoriesListComp = ({ categories }: CategoriesListProps) => {
  const { t } = useTranslation()

  return (
    <section>
      <h2 className="my-2.5 text-lg font-semibold text-ultra-dark-grey lg:my-5 lg:text-2xl lg:font-bold">
        {t('pages.home.sections.categories')}
      </h2>

      <nav className="grid grid-cols-3 gap-2.5 pb-10 lg:gap-5">
        {categories.map(category => (
          <CategoryCard key={category.id} category={category} />
        ))}
      </nav>
    </section>
  )
}

export default CategoriesListComp

interface CategoriesListSkeletonProps {
  className?: string
}

export const CategoriesListSkeleton = ({
  className,
}: CategoriesListSkeletonProps) => {
  return (
    <div className={className}>
      <Skeleton inline className="mb-2.5 h-7 w-1/3 rounded-medium lg:mb-5" />

      <div className="grid grid-cols-3 gap-2.5 lg:gap-5">
        {range(3).map(n => (
          <Skeleton
            key={n}
            inline
            className="mb-2.5 block rounded-medium pb-[calc(100%-16px)] lg:mb-5"
            count={8}
          />
        ))}
      </div>
    </div>
  )
}
