import analytics from '@analytics'
import * as Sentry from '@sentry/nextjs'
import { isQueryLoading } from '@tools/query'
import { useEffect, useState } from 'react'
import type { UseQueryResult } from 'react-query'

type Analytics = typeof analytics

const useAnalytics = (
  queryDeps: UseQueryResult[] = [],
  eventsDeps?: (keyof Analytics)[],
  cb?: (analytics: Analytics) => any,
) => {
  const [loading, setLoading] = useState(true)

  useEffect(
    () => {
      if (isQueryLoading(queryDeps)) {
        setLoading(true)
      } else {
        setLoading(false)
        if (cb) {
          cb(analytics)
        }
      }
    },
    queryDeps.map(query => query.data),
  )

  const eventPlaceholder = () => {
    Sentry.captureException({
      message: 'Unsuccessful analytics event',
      eventsDeps,
    })
  }

  return {
    ...analytics,
    ...(loading &&
      Object.fromEntries(
        eventsDeps?.map(eventDep => [eventDep, eventPlaceholder]) ?? [],
      )),
  }
}

export default useAnalytics
