import { useEffect, useState } from 'react'
import usersApi from './actions'
import { getAuthCredentialsExist } from './tools'

const useUserSetup = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      setLoading(true)

      const userExist = getAuthCredentialsExist()
      if (!userExist) {
        await usersApi.createNewUser()
      }

      setLoading(false)
    })()
  }, [])

  return loading
}

export default useUserSetup
