import { CheckmarkIcon, CopyIcon } from '@assets/icons'
import { PrimaryButton } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Linkify from 'react-linkify'
import Skeleton from 'react-loading-skeleton'

interface OfferCardProps {
  name: string
  image: string
  description: string
  promocode?: string
  className?: string
}

const descriptionDecorator = (href: string, text: string, key: number) => (
  <a key={key} href={href} target="_blank" className="font-medium underline">
    {text}
  </a>
)

const OfferCard = ({
  name,
  image,
  description,
  promocode,
  className,
}: OfferCardProps) => {
  const { t } = useTranslation()

  const [promocodeCopied, setPromocodeCopied] = useState(false)

  const onCopyToClipboard = () => {
    setPromocodeCopied(true)
    setTimeout(() => {
      setPromocodeCopied(false)
    }, 5000)
  }

  return (
    <section className={className}>
      {name && (
        <h3 className="my-2.5 text-2xl font-bold text-ultra-dark-grey lg:my-5">
          {name}
        </h3>
      )}

      <div className="rounded-medium border border-ultra-light-grey bg-white">
        <Image
          className="rounded-t-medium"
          src={image}
          width={530}
          height={260}
          priority
          layout="responsive"
          loader={({ src }) => src}
        />

        {description && (
          <div className="p-5">
            <p className="text-base font-normal text-ultra-dark-grey">
              <Linkify componentDecorator={descriptionDecorator}>
                {description}
              </Linkify>
            </p>
          </div>
        )}

        {promocode && (
          <div className="flex items-center rounded-b-medium bg-ultra-light-grey p-2.5">
            <CopyToClipboard onCopy={onCopyToClipboard} text={promocode}>
              <PrimaryButton
                disabled={promocodeCopied}
                title={`${t('pages.offers.add_promocode')} ${promocode}`}
                size="large"
                secondary
                className="flex w-full justify-center rounded-medium bg-white"
                titleClassName="text-ultra-dark-grey font-medium text-base"
                Icon={
                  promocodeCopied ? (
                    <CheckmarkIcon className="h-[17px] w-[17px] fill-ultra-dark-grey" />
                  ) : (
                    <CopyIcon className="h-[17px] w-[17px] fill-ultra-dark-grey stroke-2" />
                  )
                }
                iconPosition="right"
              />
            </CopyToClipboard>
          </div>
        )}
      </div>
    </section>
  )
}

export default OfferCard

interface OffersCardSkeletonProps {
  className?: string
}

export const OfferCardSkeleton = ({ className }: OffersCardSkeletonProps) => {
  return (
    <div className={className}>
      <Skeleton
        inline
        className="mb-2.5 h-7 w-1/3 rounded-medium lg:mb-5 lg:w-1/4"
      />

      <div className="h-[173px] xm:h-[197px] md:h-[365px] lg:h-[218px] xl:h-[279px]">
        <Skeleton inline className="h-full w-full rounded-medium" />
      </div>
      <div className="mt-5 w-full px-5">
        <Skeleton inline className="mb-1 h-4 rounded-medium" />
        <Skeleton inline className="mb-1 h-4 rounded-medium" />
        <Skeleton inline className="mb-1 h-4 rounded-medium" />
        <Skeleton inline className="mb-1 h-4 rounded-medium" />
      </div>
    </div>
  )
}
