export const getCartVisible = (pathname: string) =>
  !pathname.includes('categories') &&
  !pathname.includes('search') &&
  !pathname.includes('sales')

export const getScrollToTopButtonVisible = (pathname: string) =>
  pathname.includes('categories') ||
  pathname.includes('search') ||
  pathname.includes('sales') ||
  pathname.includes('offers')
