import type { TFunction } from 'next-i18next'
import { z } from 'zod'

export default (t: TFunction) =>
  z.object({
    comment: z
      .string()
      .max(500, { message: t('ui.modals.feedback.form.validation.comment') })
      .optional(),
  })
