import { useUser } from '@api'
import type { EmojiStatus, Order } from '@models/orders'
import { ms } from '@tools/common'
import { useModals } from '@uikit/organisms/modals'
import { useNotifications } from '@uikit/organisms/notifications'
import type { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'
import useSound from 'use-sound'
import { ordersApi, ordersKeys } from '.'

const isActiveOrder = (order: Order) =>
  order.status === 'READY_TO_PICK' ||
  order.status === 'PICKING' ||
  order.status === 'PICKED' ||
  order.status === 'DELIVERING'

const useOrders = () => {
  const { setModal } = useModals()

  const userQuery = useUser()

  const query = useQuery<Order[], AxiosError, Order[]>(
    ordersKeys.all,
    ordersApi.getOrders,
    {
      enabled: !userQuery.data?.anonymous,
      refetchInterval: data => {
        if (data?.some(isActiveOrder)) {
          return ms('30s')
        }

        return false
      },
    },
  )

  const [play] = useSound('/assets/sounds/notification.mp3', {
    volume: 0.5,
    interrupt: true,
  })

  useOrderStatusChange(
    query,
    order => {
      setModal({
        name: 'feedback',
        props: { orderId: order.id },
      })
    },
    ['DELIVERED'],
  )

  useOrderStatusChange(query, play, [
    'DELIVERED',
    'CANCELED',
    'DELIVERING',
    'PICKED',
    'PICKING',
    'READY_TO_PICK',
    'WAITING_PAYMENT',
    'DONE',
  ])

  return query
}

export default useOrders

export const useOrderStatusChange = (
  query: UseQueryResult<Order[], AxiosError<any, any>>,
  cb: (order: Order) => void,
  statusFilter: EmojiStatus[],
) => {
  const queryClient = useQueryClient()

  const [prevOrders, setPrevOrders] = useState<Map<string, Order | undefined>>()

  useEffect(() => {
    if (query.data && query.data.length === 0) {
      return
    }

    if (prevOrders && query.data) {
      const changedOrder = query.data.find(
        order =>
          order.status !== prevOrders.get(order.id)?.status &&
          statusFilter.includes(order.status),
      )

      if (changedOrder) {
        cb(changedOrder)
        queryClient.refetchQueries(ordersKeys.order(changedOrder.id))
      }

      setPrevOrders(new Map(query.data?.map(order => [order.id, order])))
    } else if (query.data) {
      setPrevOrders(new Map(query.data.map(order => [order.id, order])))
    }
  }, [query.data])
}

export const useLastActiveOrder = () => {
  const ordersQuery = useOrders()

  const { setActiveOrderOpened } = useNotifications()

  const activeOrder = ordersQuery?.data?.find(isActiveOrder)

  useEffect(() => {
    if (activeOrder) {
      setActiveOrderOpened(activeOrder)
    } else {
      setActiveOrderOpened(null)
    }
  }, [activeOrder])
}
