import { LightLoadingAnimation, LoadingAnimation } from '@assets/animations'
import type { RefObject } from 'react'
import Lottie from 'react-lottie'

export interface SpinnerProps {
  spinnerRef?: RefObject<HTMLSpanElement>
  size?: number | string
  mode?: 'default' | 'light'
  className?: string
}

const Spinner = ({
  spinnerRef,
  size = 20,
  mode = 'default',
  className,
}: SpinnerProps) => {
  return (
    <span ref={spinnerRef} className={className}>
      <Lottie
        height={size}
        width={size}
        options={{
          loop: true,
          autoplay: true,
          animationData:
            mode === 'light' ? LightLoadingAnimation : LoadingAnimation,
        }}
      />
    </span>
  )
}

export default Spinner
