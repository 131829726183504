import { useAddresses, useUser } from '@api'
import { LocationAddIcon, TrashCanIcon } from '@assets/icons'
import { useAnalytics } from '@hooks'
import type { Address } from '@models/map'
import { classnames } from '@tools/common'
import { CircleButton, PrimaryButton } from '@uikit/molecules'
import { Loading } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { useModals } from '..'
import type { ModalHeaderProps } from '../components'
import BaseModal, { BaseModalProps } from '../components/BaseModal'

export interface AddressesModalProps extends BaseModalProps {}

const AddressesModal = ({ visible, onClose }: AddressesModalProps) => {
  const { t } = useTranslation()

  const analytics = useAnalytics([], [], analytics => {
    analytics.accountAddressClick()
  })

  const { setModal } = useModals()

  const userQuery = useUser()
  const addressesQuery = useAddresses()

  const header: Omit<ModalHeaderProps, 'onClose'> = {
    Icon: <LocationAddIcon className="h-4 w-4 stroke-black stroke-[1.5px]" />,
    title: t('ui.modals.addresses.header'),
  }

  useEffect(() => {
    if (userQuery.isSuccess && userQuery.data.addresses.length === 0) {
      setAddressModal()
    }
  }, [userQuery.data])

  const setAddressModal = () => {
    setModal({ name: 'selectAddress', props: {} })
  }

  const onRemoveAddress = (address: Address) => {
    addressesQuery.mutations.deleteAddress.mutate(address)
    analytics.removeUserAddress()
  }

  const onClickAddress = (addressId: string) => {
    userQuery.mutations.updateAddress.mutate(addressId)
    onClose()
  }

  const AddressTitle = (address: Address) => (
    <div
      key={address.id}
      onClick={() => onClickAddress(address.id!)}
      className="my-2.5 flex flex-1 cursor-pointer items-center rounded-lg p-2.5 text-left hover:bg-light-grey active:bg-grey"
    >
      {address.name}
    </div>
  )

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.addresses.title'),
          description: t('ui.modals.addresses.description'),
        },
        className: 'max-h-[calc(100%-161px)] h-full',
        dialogClassName: 'h-full',
        containerClassName: 'lg:h-[80vh]',
      }}
    >
      <Loading
        type="query"
        query={userQuery}
        loader="animation"
        size={40}
        className="h-full"
      >
        {userQuery => (
          <>
            {userQuery.data.addresses.map(address => (
              <div
                key={address.id}
                className="mx-5 flex justify-between border-b border-b-ultra-light-grey"
              >
                <AddressTitle {...address} />

                <CircleButton
                  Icon={
                    <TrashCanIcon
                      className={classnames('stroke-ultra-dark-grey', {
                        'stroke-light-grey':
                          userQuery.data.addresses.length === 1,
                      })}
                    />
                  }
                  onClick={() => onRemoveAddress(address)}
                  disabled={userQuery.data!.addresses.length === 1}
                  className="my-5 ml-2.5 bg-white hover:bg-light-grey active:bg-grey disabled:bg-transparent"
                />
              </div>
            ))}

            <PrimaryButton
              onClick={setAddressModal}
              title={t('ui.modals.addresses.buttonTitle')}
              size="middle"
              titleClassName="text-white font-medium text-base"
              className="absolute bottom-0 m-5 w-[calc(100%-2.5rem)] rounded-xsmall"
            />
          </>
        )}
      </Loading>
    </BaseModal>
  )
}

export default AddressesModal
