import { classnames } from '@tools/common'
import type { CSSProperties, FC } from 'react'

export interface SectionPopoverProps {
  text: JSX.Element
  hidden: boolean
  arrowPosition?: 'right' | 'left'
  style?: CSSProperties
  className?: string
}

const SectionPopover: FC<SectionPopoverProps> = ({
  children,
  text,
  arrowPosition = 'right',
  hidden,
  style,
  className,
}) => {
  return (
    <div className="relative flex w-full cursor-default flex-row-reverse">
      {children}

      <div
        style={style}
        className={classnames(
          'absolute -top-1/2 z-10 w-[110px] rounded-medium font-normal',
          { hidden },
          className,
        )}
      >
        <div
          className={classnames(
            'absolute top-1/2 block  h-3 w-3 -translate-y-1/2 rotate-45  bg-ultra-light-grey',
            { '-right-1.5 rounded-tr-sm': arrowPosition === 'right' },
            { '-left-1.5 rounded-bl-sm': arrowPosition === 'left' },
          )}
        />

        <div className="relative rounded-md bg-ultra-light-grey ">
          <p className="break-words p-[5px] text-xs font-normal text-ultra-dark-grey">
            {text}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SectionPopover
