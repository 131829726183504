import type { MediaQueryAllQueryable } from 'react-responsive'

export const HEADER_HEIGHT = 99 as const

export const MOBILE_HEADER_HEIGHT = 79 as const

export const SCROLL_TO_TOP_MIN_HEIGHT = 2000 as const

export const MOBILE_MEDIA_SETTINGS: MediaQueryAllQueryable = {
  maxWidth: 1023,
}
