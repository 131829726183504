import { v1 } from '@constants/api'
import { keyToUrl } from '@tools/common'

export const cartKeys = {
  root: [v1, 'cart'] as const,
  checkout: [v1, 'checkout'] as const,
  promocode: (userId?: string) =>
    [...cartKeys.root, 'promocode', userId ?? null] as const,
  recommendations: [v1, 'recommendations'] as const,
  timeslots: (warehouseId?: string) =>
    ['timeslots', warehouseId ?? null] as const,
}

export const cartUrls = {
  root: keyToUrl(cartKeys.root),
  checkout: keyToUrl(cartKeys.checkout),
  promocode: keyToUrl(cartKeys.promocode()),
  remoteConfigRecommendation: 'remoteConfigRecommendation',
  recommendations: keyToUrl(cartKeys.recommendations),
}

export const MAX_PRODUCT_COUNT = 10

export const NO_SELECTED_ADDRESS_ERROR_MESSAGE = 'NO_SELECTED_ADDRESS'

export const MAX_PRODUCT_COUNT_ERROR_MESSAGE = 'MAX_PRODUCT_COUNT'

export const DEFICIT_ERROR_MESSAGE = 'DEFICIT_ERROR_MESSAGE'
