import { v1 } from '@constants/api'
import type { Coordinates } from '@models/map'
import { keyToUrl } from '@tools/common'

export const productsKeys = {
  root: (id: string, coords?: Coordinates) =>
    [v1, 'product', id, coords ?? null] as const,
  categoryProducts: (categoryId?: string, coords?: Coordinates) => [
    v1,
    'products_by_category',
    categoryId ?? '',
    coords ?? null,
  ],
}

export const productsUrls = {
  root: (id: string) => keyToUrl(productsKeys.root(id)),
  categoryProducts: () => keyToUrl(productsKeys.categoryProducts()),
}
