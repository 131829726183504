import { isPointOnWarehousePolygon, useUser } from '@api'
import { queryClient } from '@api/queryClient'
import { useAnalytics } from '@hooks'
import type { Coordinates, Zone, ZoneType } from '@models/map'
import type { User } from '@models/user'
import type { DeliveryFee, ShoppingHours, Warehouse } from '@models/warehouses'
import { ms } from '@tools/common'
import { addSeconds, dateFormatter, parse } from '@tools/date'
import { useNotifications } from '@uikit/organisms/notifications'
import type { AxiosError } from 'axios'
import { TFunction, useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { QueryClient, useQuery } from 'react-query'
import { selectUserData } from '../users'
import { usersKeys } from './../users/constants'
import warehousesApi from './actions'
import { warehousesKeys } from './constants'

export interface UseWarehousesData {
  warehouses: Warehouse[]
  warehousesMap: Map<string, Warehouse>
  activeWarehouse: Warehouse
  deliveryFeeByZone?: DeliveryFee
  deliveryFeePrice?: string
  deliveryTime?: string
  warehouseClosed?: boolean
  zones: Zone[]
}

export const getFormattedWorkingHours = (shoppingHours: ShoppingHours[]) => {
  const parsedStartTime = parse(
    shoppingHours[shoppingHours.length === 1 ? 0 : 1].start_time,
    'HH:mm:ss',
    new Date(),
  )
  const parsedEndTime = parse(shoppingHours[0].end_time, 'HH:mm:ss', new Date())

  return {
    start_time: dateFormatter.time(addSeconds(parsedStartTime, 1)),
    end_time: dateFormatter.time(addSeconds(parsedEndTime, 1)),
  }
}

export const getActiveWarehouse = (
  warehouses: Warehouse[],
  coords?: Coordinates,
) => {
  if (coords) {
    for (const warehouse of warehouses) {
      const response = isPointOnWarehousePolygon(coords, warehouse)

      if (response) {
        return warehouse
      }
    }
  }

  for (const warehouse of warehouses) {
    if (warehouse.is_demo) {
      return warehouse
    }
  }

  return warehouses[0]
}

export const getDeliveryTime = (
  activeWarehouse: Warehouse,
  t: TFunction,
  coords?: Coordinates,
) => {
  if (coords) {
    const warehouseSize = isPointOnWarehousePolygon(coords, activeWarehouse)

    const { warehouse_status_object: warehouseStatus } = activeWarehouse

    if (warehouseStatus.status === 'CLOSED' || !warehouseSize) {
      return t('layout.header.closed')
    }

    return `${
      warehouseStatus[
        warehouseStatus.status === 'OPEN'
          ? 'delivery_time'
          : 'delivery_time_on_high_demand'
      ][warehouseSize.toLowerCase() as Lowercase<ZoneType>]
    } min`
  }
}

export const selectData = (
  warehouses: Warehouse[],
  t: TFunction,
): UseWarehousesData => {
  const user = selectUserData(queryClient.getQueryData<User>(usersKeys.root)!)

  const activeWarehouse = getActiveWarehouse(warehouses, user.coords)

  const deliveryZone =
    user.coords &&
    (isPointOnWarehousePolygon(user.coords, activeWarehouse)?.toLowerCase() as
      | Lowercase<ZoneType>
      | undefined)

  const deliveryFeeByZone =
    deliveryZone && activeWarehouse.delivery_fee_by_zone[deliveryZone]

  const deliveryFeePrice =
    deliveryFeeByZone?.levels.length === 1 &&
    deliveryFeeByZone?.levels[0].sum === 0
      ? (t('layout.header.deliveryFree') as string)
      : deliveryFeeByZone?.levels.length === 1 &&
        deliveryFeeByZone?.levels[0].sum !== 0
      ? `${deliveryFeeByZone?.levels[0].sum} €`
      : `${
          deliveryFeeByZone?.levels[deliveryFeeByZone?.levels.length - 1]?.sum
        }-${deliveryFeeByZone?.levels[0]?.sum} €`

  const zones: Zone[] = []

  for (const warehouse of warehouses!) {
    if (warehouse.zones.small) {
      zones.push({
        type: 'SMALL',
        visible: true,
        points: warehouse.zones.small,
      })
    }

    if (warehouse.zones.large) {
      zones.push({
        type: 'LARGE',
        visible: true,
        points: warehouse.zones.large,
      })
    }

    if (warehouse.zones.custom) {
      zones.push({
        type: 'CUSTOM',
        visible: true,
        points: warehouse.zones.custom,
      })
    }
  }

  return {
    warehouses,
    warehousesMap: new Map(
      warehouses.map(warehouse => [warehouse.id, warehouse]),
    ),
    activeWarehouse,
    deliveryFeeByZone,
    deliveryFeePrice,
    deliveryTime: getDeliveryTime(activeWarehouse, t, user.coords),
    warehouseClosed:
      activeWarehouse.warehouse_status_object.status === 'CLOSED',
    zones,
  }
}

const useWarehouses = () => {
  const { t } = useTranslation()

  const analytics = useAnalytics()

  const { setNotification } = useNotifications()

  const userQuery = useUser()

  const query = useQuery<Warehouse[], AxiosError, UseWarehousesData>(
    warehousesKeys.all,
    warehousesApi.getWarehouses,
    {
      enabled: userQuery.isSuccess,
      staleTime: ms('30m'),
      select: useCallback(data => selectData(data, t), [t, userQuery.data]),
      refetchInterval: data => (data?.warehouseClosed ? ms('3m') : false),
    },
  )

  const setActiveStatus = () => {
    if (!query.data) {
      return
    }

    const { activeWarehouse } = query.data
    const { status, delivery_time_on_high_demand } =
      activeWarehouse.warehouse_status_object

    if (status === 'CLOSED') {
      analytics.sleepingModalViewed()
      setNotification({
        name: 'popup',
        props: {
          emoji: '😴',
          title: t('ui.statuses.sleeping.title'),
          message: t('ui.statuses.sleeping.message'),
        },
      })

      return
    }

    if (status === 'HIGH_DEMAND' && userQuery.data!.coords) {
      const warehouseSize =
        isPointOnWarehousePolygon(userQuery.data!.coords!, activeWarehouse) ||
        'LARGE'

      setNotification({
        name: 'popup',
        props: {
          emoji: '🚴‍♀️',
          title: t('ui.statuses.high_demand.title'),
          message: t('ui.statuses.high_demand.message', {
            time: delivery_time_on_high_demand[
              warehouseSize.toLowerCase() as Lowercase<ZoneType>
            ],
          }),
        },
      })

      return
    }

    if (status === 'OVERLOADED') {
      setNotification({
        name: 'popup',
        props: {
          emoji: '⚙️',
          title: t('ui.statuses.overloaded.title'),
          message: t('ui.statuses.overloaded.message'),
        },
      })

      return
    }

    setNotification(null)
  }

  const pointInPoligon = (point: Coordinates) =>
    !!query.data?.warehouses!.some(warehouse => {
      return isPointOnWarehousePolygon(point, warehouse)
    })

  return {
    ...query,
    tools: { setActiveStatus, pointInPoligon },
  }
}

export default useWarehouses

export const prefetchWareshouses = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery(
    warehousesKeys.all,
    warehousesApi.getWarehouses,
  )
}
