import { categoriesKeys, useCategories } from '@api'
import { selectData as selectCategiesData } from '@api/resources/categories/useCategories'
import type { Category } from '@models/categories'
import type { Product } from '@models/products'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { QueryClient, useQuery } from 'react-query'
import { useUser } from '../users'
import productsApi from './actions'
import { productsKeys } from './constants'

const useCategoryProducts = (categoryId: string) => {
  const userQuery = useUser()

  const categoriesQuery = useCategories()

  const query = useQuery<Map<string, Product[]>, AxiosError>(
    productsKeys.categoryProducts(categoryId, userQuery.data?.coords),
    () =>
      productsApi.getProductsByCategories(
        categoriesQuery.data?.categoriesListMap.get(categoryId)
          ?.subcategoriesIds,
        userQuery.data?.coords,
      ),
    {
      staleTime: ms('20m'),
      enabled: userQuery.isSuccess && categoriesQuery.isSuccess && !!categoryId,
    },
  )

  return query
}

export default useCategoryProducts

export const prefetchCategoryProducts = async (
  queryClient: QueryClient,
  categoryId: string,
) => {
  const categoriesQueryData = selectCategiesData(
    queryClient.getQueryData<Category[]>(categoriesKeys.all())!,
  )

  await queryClient.prefetchQuery(
    productsKeys.categoryProducts(categoryId),
    () =>
      productsApi.getProductsByCategories(
        categoriesQueryData.categoriesListMap.get(categoryId)?.subcategoriesIds,
      ),
  )
}
