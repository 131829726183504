import { v1 } from '@constants/api'
import type { Coordinates } from '@models/map'
import { keyToUrl } from '@tools/common'

export const categoriesKeys = {
  all: (coords?: Coordinates) => [v1, 'categories', coords ?? null] as const,
}

export const categoriesUrls = {
  all: keyToUrl(categoriesKeys.all()),
}
