import { classnames } from '@tools/common'

export interface TextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string
  className?: string
}

const TextButton = ({ title, className, ...props }: TextButtonProps) => {
  return (
    <button {...props}>
      <p
        className={classnames(
          'break-words text-base font-medium text-pink hover:text-dark-pink active:text-ultra-dark-pink',
          { 'text-grey hover:text-grey': props.disabled },
          className,
        )}
      >
        {title}
      </p>
    </button>
  )
}

export default TextButton
