import { createCollection } from '@api/firebase/firestoreClient'
import type { NewsResp } from '@models/news'
import { getDocs, limit, query, where } from 'firebase/firestore'
import { newsKeys } from './constants'

export const newsColection = createCollection<NewsResp>(newsKeys.root)

const getNewsLine = async () => {
  const newsResponse = await getDocs(
    query(newsColection, where('enabled', '==', true), limit(1)),
  )

  return newsResponse.empty ? undefined : newsResponse.docs[0].data()
}

export default { getNewsLine }
