import { useUser } from '@api'
import type {
  CategoriesList,
  Category,
  UseCategoriesData,
} from '@models/categories'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { groupBy } from 'lodash'
import { QueryClient, useQuery } from 'react-query'
import categoriesApi from './actions'
import { categoriesKeys } from './constants'

export const selectData = (data: Category[]): UseCategoriesData => {
  const sections = groupBy(data, category => category.parent_id)
  const { null: categories } = sections

  const categoriesMap = new Map(data.map(category => [category.id, category]))

  const categoriesList: CategoriesList = categories.map(category => ({
    ...category,
    subcategories: sections[category.id],
    subcategoriesIds: sections[category.id]?.map(category => category.id),
  }))

  const categoriesListMap = new Map(
    categoriesList.map(categorySection => [
      categorySection.id,
      categorySection,
    ]),
  )

  return {
    categoriesMap,
    categoriesList,
    categoriesListMap,
  }
}

const useCategories = () => {
  const userQuery = useUser()

  const query = useQuery<Category[], AxiosError, UseCategoriesData>(
    categoriesKeys.all(userQuery.data?.coords),
    () => categoriesApi.getCategories(userQuery.data?.coords),
    {
      select: selectData,
      staleTime: ms('1h'),
      enabled: userQuery.isSuccess,
    },
  )

  return query
}

export default useCategories

export const prefetchCategories = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery(categoriesKeys.all(), () =>
    categoriesApi.getCategories(),
  )
}
