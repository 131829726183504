import { useAnalytics, useIntercom } from '@hooks'
import { useEffect } from 'react'

const useIntercomAnalyticsEvents = () => {
  const analytics = useAnalytics()

  const { available: intercomAvailable, countUnreadMessages } = useIntercom()

  useEffect(() => {
    const getHref = () => {
      if (typeof window !== 'undefined' && window.location.search.length > 0) {
        return window.location.pathname + window.location.search
      }

      if (
        typeof window !== 'undefined' &&
        window.location.search.length === 0
      ) {
        return window.location.pathname
      }
    }

    let startDate: number

    window.Intercom('onShow', () => {
      startDate = Date.now()

      analytics.chatOpen({
        has_unread: countUnreadMessages > 0,
        is_online: intercomAvailable,
        source: getHref()!,
      })
    })

    window.Intercom('onHide', () => {
      const insideSeconds = Math.floor((Date.now() - startDate) / 1000)

      analytics.chatClose({
        seconds_inside: insideSeconds,
        is_online: intercomAvailable,
        source: getHref()!,
      })
    })
  }, [typeof window !== 'undefined' && window.Intercom])
}

export default useIntercomAnalyticsEvents
