import type { NextApiRequest } from 'next'
import type { Locale } from 'react-i18next'

export const getLanguage = (req?: NextApiRequest): Locale => {
  if (typeof window !== 'undefined') {
    return window.location.href.includes('/pt') ? 'pt' : 'en'
  }

  if (req?.headers['accept-language']) {
    return req?.headers['accept-language']?.includes('pt') ? 'pt' : 'en'
  }

  return 'pt'
}
