import httpClient from '@api/httpClient'
import type { Address } from '@models/map'
import { addressesUrls } from './constants'

const getAddresses = async () => {
  const { data } = await httpClient.get<{ addresses: Address[] }>(
    addressesUrls.root,
  )
  return data.addresses
}

const createAddress = async (address: Address) => {
  const { data } = await httpClient.post<{ addresses: Address[] }>(
    addressesUrls.root,
    address,
  )
  return data.addresses
}

const updateAddress = async ({ id, ...address }: Address) => {
  const { data } = await httpClient.put<{ addresses: Address[] }>(
    addressesUrls.address(id as string),
    address,
  )
  return data.addresses
}

const deleteAddress = async ({ id }: Address) => {
  const { data } = await httpClient.delete<{ addresses: Address[] }>(
    addressesUrls.address(id as string),
  )
  return data.addresses
}

export default {
  getAddresses,
  updateAddress,
  createAddress,
  deleteAddress,
}
