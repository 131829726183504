import { categoriesKeys, salesKeys, useCart, usersKeys } from '@api'
import { LISBON_CENTER_COORDS } from '@constants/address'
import type { Address, Coordinates } from '@models/map'
import type { User } from '@models/user'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import usersApi from './actions'

export interface UseUserData extends User {
  anonymous: boolean
  selectedAddress?: Address
  coords?: Coordinates
}

export const selectData = (data: User): UseUserData => {
  const anonymous = !data.phone

  const selectedAddress = data.addresses.find(
    address => address.id === data.selected_address,
  )

  const coords = selectedAddress
    ? { lat: selectedAddress.lat, long: selectedAddress.long }
    : { lat: LISBON_CENTER_COORDS.lat, long: LISBON_CENTER_COORDS.lng }

  return {
    ...data,
    anonymous,
    selectedAddress,
    coords,
  }
}

const useUser = () => {
  const cartQuery = useCart()

  const query = useQuery<User, AxiosError, UseUserData>(
    usersKeys.root,
    usersApi.getUser,
    {
      select: selectData,
      staleTime: ms('30m'),
    },
  )

  const queryClient = useQueryClient()

  const login = useMutation(usersApi.login, {
    onSuccess: async () => {
      await queryClient.invalidateQueries()

      await cartQuery.mutations.updateCart.mutateAsync({})
    },
  })

  const logout = useMutation(usersApi.logout, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(usersKeys.root)
      queryClient.invalidateQueries()
    },
  })

  const updateName = useMutation(usersApi.updateName, {
    onSuccess: user => {
      queryClient.setQueryData(usersKeys.root, user)
    },
  })

  const updateAddress = useMutation(usersApi.updateAddress, {
    onSuccess: user => {
      queryClient.setQueryData(usersKeys.root, user)

      queryClient.invalidateQueries(categoriesKeys.all())
      queryClient.invalidateQueries(salesKeys.root())

      cartQuery.mutations.updateCart.mutate({})
    },
  })

  const updateSetting = useMutation(usersApi.updateSetting, {
    onSuccess: settings => {
      queryClient.setQueryData<User>(usersKeys.root, user => ({
        ...user!,
        settings,
      }))
    },
  })

  const addPromocode = useMutation(usersApi.addPromocode)

  return {
    ...query,
    mutations: {
      updateName,
      updateAddress,
      updateSetting,
      addPromocode,
      logout,
      login,
    },
  }
}

export default useUser
