import { useOrders } from '@api/resources/orders'
import { CartIcon } from '@assets/icons'
import { ACTIVE_ORDER_ID_KEY } from '@constants/storage'
import { useAnalytics } from '@hooks'
import { classnames } from '@tools/common'
import { CartOrderCard, Loading } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { useModals } from '..'
import { BaseModal, BaseModalProps, ModalHeaderProps } from '../components'
import { EmptyOrders } from './components'
import * as ls from 'local-storage'

export interface OrdersModalProps extends BaseModalProps {
  orderId?: string
  isNew?: boolean
}

const OrdersModal = ({ visible, onClose }: OrdersModalProps) => {
  const { t } = useTranslation()

  const { setModal } = useModals()

  const ordersQuery = useOrders()

  const analytics = useAnalytics([ordersQuery])

  useEffect(() => {
    const activeOrderId = ls.get<string>(ACTIVE_ORDER_ID_KEY)

    if (activeOrderId) {
      setModal({ name: 'order', props: { orderId: activeOrderId } })
      ls.remove(ACTIVE_ORDER_ID_KEY)
    }
  }, [])

  useEffect(() => {
    if (ordersQuery.isSuccess) {
      analytics.ordersClick({ orderCount: ordersQuery.data.length })
    }
  }, [ordersQuery.data])

  const header: Omit<ModalHeaderProps, 'onClose'> = {
    Icon: <CartIcon className="h-4 w-4 stroke-black stroke-[1.5px]" />,
    title: t('ui.modals.orders.header'),
  }

  const onClickOrder = (orderId: string) => {
    // @ts-ignore
    setModal({ name: 'order', props: { orderId } })
  }

  if (ordersQuery.data?.length! === 0) {
    return (
      <BaseModal
        {...{
          header,
          visible,
          onClose,
          metadata: {
            title: t('ui.modals.orders.title'),
            description: t('ui.modals.orders.empty.description'),
          },
        }}
      >
        <EmptyOrders onClose={onClose} />
      </BaseModal>
    )
  }

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.orders.title'),
          description: t('ui.modals.orders.description'),
        },
        className: 'lg:w-full h-full lg:max-h-[68vh]',
        dialogClassName: 'h-full lg:h-fit',
      }}
    >
      <Loading
        type="query"
        query={ordersQuery}
        loader="animation"
        size={40}
        className="h-full lg:min-h-[68vh]"
      >
        {ordersQuery => (
          <>
            {ordersQuery.data.map((order, index) => (
              <CartOrderCard
                key={order.id}
                order={order}
                onClick={() => onClickOrder(order.id)}
                className={classnames('cursor-pointer', {
                  'border-b border-b-ultra-light-grey':
                    index !== ordersQuery.data.length - 1,
                })}
              />
            ))}
          </>
        )}
      </Loading>
    </BaseModal>
  )
}
export default OrdersModal
