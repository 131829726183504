import { MAX_PRODUCT_COUNT } from '@api'
import { MinusIcon, PlusIcon } from '@assets/icons'
import { classnames } from '@tools/common'
import PrimaryButton from '@uikit/molecules/buttons/PrimaryButton'
import { useTranslation } from 'next-i18next'

export interface AmountSelectorProps {
  value?: number
  inline?: boolean
  onAdd: () => void
  onSub: () => void
  max?: number
  buttonsClassName?: string
  className?: string
  smallCard?: boolean
}

const AmountSelector = ({
  value = 0,
  inline,
  onAdd: onBaseAdd,
  onSub,
  max = MAX_PRODUCT_COUNT,
  buttonsClassName,
  className,
  smallCard,
}: AmountSelectorProps) => {
  const { t } = useTranslation()

  const onAdd = value < max ? onBaseAdd : undefined

  const isShowDeficit = inline && max <= MAX_PRODUCT_COUNT && max <= value

  if (value === 0 && inline) {
    return (
      <PrimaryButton
        title={t('product.add_to_cart')}
        titleClassName="whitespace-nowrap ml-0"
        onClick={onAdd}
        secondary
        className={classnames(
          'w-[calc(100%-1.25rem)] rounded-xsmall bg-white px-0 lg:w-[calc(100%-2.5rem)]',
          { 'lg:w-[calc(100%-1.25rem)]': smallCard },
          className,
        )}
      />
    )
  }

  return (
    <div
      className={classnames(
        'group w-[calc(100%-1.25rem)] bg-white transition duration-200',
        {
          'ease-out lg:w-[calc(100%-1.25rem)]': value !== 0 && smallCard,
          'ease-out lg:w-[calc(100%-2.5rem)]': value !== 0 && !smallCard,
          'h-9 w-9 ease-in lg:h-9 lg:w-9': value === 0 && smallCard,
          'h-9 w-9 ease-in lg:h-12 lg:w-12': value === 0 && !smallCard,
          'ease-out': value !== 0,
          'rounded-xsmall': inline,
        },
        className,
      )}
    >
      <div className="flex items-center justify-between overflow-hidden">
        {value !== 0 && (
          <>
            <button
              className={classnames(
                'flex h-9 w-9 items-center justify-center rounded-full transition duration-100 lg:h-12 lg:w-12 lg:rounded-none',
                {
                  'lg:h-9 lg:w-9 lg:rounded-full': smallCard,
                  'rounded-full': !inline,
                  'w-24': inline,
                },
                buttonsClassName,
              )}
              onClick={e => {
                e.stopPropagation()
                onSub()
              }}
            >
              <MinusIcon className="h-2.5 w-2.5 animate-fadeIn stroke-ultra-dark-grey stroke-[1.5px]" />
            </button>

            <p
              className={classnames('animate-fadeIn text-sm lg:text-base', {
                'lg:text-sm': smallCard,
              })}
            >
              {isShowDeficit
                ? t('product.deficit_only', { deficit: value })
                : value}
            </p>
          </>
        )}

        <button
          className={classnames(
            'flex h-9 w-9 items-center justify-center rounded-full transition duration-100 lg:h-12 lg:w-12 lg:rounded-none',
            {
              'lg:h-9 lg:w-9 lg:rounded-full': smallCard,
              'rounded-full': !inline,
              'w-24': inline,
              'opacity-50': value >= max,
            },
            buttonsClassName,
          )}
          onClick={e => {
            e.stopPropagation()
            onAdd?.()
          }}
        >
          <PlusIcon className="h-2.5 w-2.5 stroke-ultra-dark-grey stroke-[1.5px]" />
        </button>
      </div>
    </div>
  )
}

export default AmountSelector
