import { useCart, usersKeys, useUser } from '@api'
import { queryClient } from '@api/queryClient'
import { CheckmarkIcon } from '@assets/icons'
import { useMobile, useRouteLoading } from '@hooks'
import { classnames } from '@tools/common'
import { PrimaryButton } from '@uikit/molecules'
import { useModals } from '@uikit/organisms/modals'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

interface CheckoutButtonProps {
  className?: string
}

const CheckoutButton = ({ className }: CheckoutButtonProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const userQuery = useUser()

  const cartQuery = useCart()

  const { path } = useRouteLoading()

  const { setModal } = useModals()

  const isMobile = useMobile()

  const onClickCheckout = async () => {
    await queryClient.invalidateQueries(usersKeys.root)

    if (userQuery.data!.anonymous) {
      setModal({
        name: 'login',
        props: {
          onClose: () => {
            if (isMobile) {
              setModal({ name: 'cart', props: {} })
            }

            if (!isMobile) {
              router.back()
            }
          },
          onSuccess: () => router.replace('/checkout'),
        },
      })

      return
    }

    router.push('/checkout')
  }

  if (router.pathname.includes('checkout')) {
    return null
  }

  return (
    <div className="flex justify-center">
      <PrimaryButton
        onClick={onClickCheckout}
        title={t('pages.cart.buttons.checkout')}
        Icon={<CheckmarkIcon />}
        loading={!!path?.includes('checkout')}
        iconPosition="right"
        titleClassName="text-base font-bold line-clamp-2"
        className={classnames('mb-5 w-[calc(100%-2.5em)] py-5', className)}
        disabled={!cartQuery.data!.checkoutAvailable}
      />
    </div>
  )
}

export default CheckoutButton
