import { CheckmarkIcon, CloseIcon } from '@assets/icons'
import { classnames } from '@tools/common'
import type { LegacyRef } from 'react'
import { useController } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import type { BaseFieldProps } from '../BaseField'
import BaseField from '../BaseField'

export interface InputProps extends BaseFieldProps {
  mask?: (RegExp | string)[]
  success?: boolean
  textArea?: boolean
  rightIcon?: JSX.Element
  Icon?: JSX.Element
  inputClassName?: string
  onClick?: () => void
  onClickRemove?: () => void
}

const Input = ({
  name,
  control,
  label,
  required,
  onClick,
  onClickRemove,
  success,
  rightIcon,
  className,
  textArea,
  inputClassName,
  Icon,
  mask,
  ...props
}: InputProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control })

  return (
    <BaseField {...{ label, error: error?.message, required }}>
      <div
        onClick={onClick}
        className={classnames(
          'flex items-center rounded-xsmall border border-ultra-light-grey bg-white',
          {
            'border-b border-b-red text-red': error,
            'border border-green': success,
          },
          className,
        )}
      >
        {rightIcon && <div className="mr-2">{rightIcon}</div>}

        {mask && (
          <MaskedInput
            {...field}
            {...props}
            ref={data => {
              field.ref(data?.inputElement)
            }}
            mask={mask}
            guide={false}
            className={classnames(
              'caret-inherit form-input w-full grow border-0 bg-transparent p-5 text-ultra-dark-grey placeholder:text-grey focus:outline-none focus:ring-0',
              { 'text-red': error },
              inputClassName,
            )}
          />
        )}

        {!mask && !textArea && (
          <input
            {...field}
            {...props}
            className={classnames(
              'caret-inherit form-input w-full grow border-0 bg-transparent p-5 text-ultra-dark-grey placeholder:text-grey focus:outline-none focus:ring-0',
              { 'text-red': error },
              inputClassName,
            )}
          />
        )}

        {!mask && textArea && (
          <textarea
            {...(field as any)}
            {...props}
            ref={field.ref as LegacyRef<HTMLTextAreaElement>}
            className={classnames(
              'caret-inherit form-textarea h-28 w-full grow resize-none border-0 bg-transparent p-5 text-ultra-dark-grey placeholder:text-grey focus:outline-none focus:ring-0',
              { 'text-red': error },
              inputClassName,
            )}
          />
        )}

        {Icon && !success && <div className="ml-2 mr-5">{Icon}</div>}

        {!success && field.value?.length > 0 && onClickRemove ? (
          <span onClick={onClickRemove}>
            <CloseIcon className="mr-2.5 h-2.5 w-2.5 cursor-pointer fill-ultra-dark-grey stroke-ultra-dark-grey stroke-0" />
          </span>
        ) : null}

        {success && <CheckmarkIcon className="mr-2 h-5 w-5 fill-green" />}
      </div>
    </BaseField>
  )
}

export default Input
