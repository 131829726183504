import { useCategories, useCategoryProducts } from '@api'
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@constants/layout'
import { useMobile } from '@hooks'
import { useModals } from '@uikit/organisms/modals'
import { useEffect } from 'react'
import { scroller } from 'react-scroll'

const useCategoriesScroll = (categoryId: string) => {
  const { modal } = useModals()
  const isMobile = useMobile()

  const categoriesQuery = useCategories()
  const productsQuery = useCategoryProducts(categoryId)

  useEffect(() => {
    if (
      window.scrollY >= HEADER_HEIGHT &&
      !modal?.name &&
      !productsQuery.isLoading
    ) {
      scroller.scrollTo(
        categoriesQuery.data?.categoriesListMap.get(categoryId)
          ?.subcategories?.[0].id!,
        {
          duration: 0,
          offset: 1,
        },
      )

      scroller.scrollTo(
        categoriesQuery.data?.categoriesListMap.get(categoryId)
          ?.subcategories?.[0].id!,
        {
          duration: 0,
          offset: 0,
        },
      )
    }

    if (isMobile && window.scrollY >= MOBILE_HEADER_HEIGHT) {
      scroller.scrollTo('categoryProductsList', {
        offset: -MOBILE_HEADER_HEIGHT,
        duration: 0,
      })
    }
  }, [productsQuery.data, categoryId])
}

export default useCategoriesScroll
