import type { Offer } from '@models/offers'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { dehydrate, QueryClient, useQuery } from 'react-query'
import { useUser } from '../users'
import offersApi from './actions'
import { offersKeys } from './constants'

const useOffer = (id: string) => {
  const userQuery = useUser()

  const query = useQuery<Offer, AxiosError>(
    offersKeys.offer(id, userQuery.data?.coords),
    () => offersApi.getOffer(id, userQuery.data?.coords),
    { enabled: userQuery.isSuccess, staleTime: ms('30m') },
  )

  return query
}

export default useOffer

export const prefetchOffer = async (
  queryClient: QueryClient,
  offerId: string,
) => {
  await queryClient.prefetchQuery(offersKeys.offer(offerId), () =>
    offersApi.getOffer(offerId),
  )

  return { dehydratedState: { dehydratedState: dehydrate(queryClient) } }
}
