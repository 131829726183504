import type { CartProduct } from '@models/cart'
import { Label, Price } from '@uikit/molecules'
import classnames from 'classnames'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { memo } from 'react'

export interface DeficitListProductCardProps {
  product: CartProduct
  className?: string
}

const DeficitListProductCard = ({
  product,
  className,
}: DeficitListProductCardProps) => {
  const { t } = useTranslation()

  const getSale = () => {
    if (product.cartPrice.discount) {
      return product.cartPrice.original
    }

    if (product && product.price.sale) {
      return product.price.full
    }
  }

  return (
    <li className={classnames('flex flex-1 items-center', className)}>
      <div className="min-w-[50px]">
        <Image
          className="rounded-xsmall bg-image-grey"
          width={50}
          height={50}
          objectFit="contain"
          src={product.media.medium}
          alt={product.name}
          loader={({ src }) => src}
        />
      </div>
      <div className="ml-2.5 flex w-full flex-col">
        <Label
          title={product.name}
          subtitleClassName="text-xs self-start"
          className="self-start text-xs"
        />

        <div className="mt-2 flex justify-between">
          <Price
            value={product.cartPrice.with_discount}
            sale={getSale()}
            size="small"
            valueClassName="text-ultra-dark-grey"
            className="text-ultra-dark-grey"
          />

          {product.deficit === 0 ? (
            <p className="text-sm font-medium text-ultra-dark-grey">
              {t('product.out_of_stock')}
            </p>
          ) : (
            <div className="flex items-center text-sm font-medium text-ultra-dark-grey">
              {t('ui.messages.was')}
              <p className="ml-1 mr-2 rounded-xsmall bg-ultra-light-grey px-2">{`x${product.count}`}</p>
              {t('ui.messages.now')}
              <p className="ml-1 rounded-xsmall bg-ultra-light-grey px-2">{`x${product.deficit}`}</p>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

export default memo(
  DeficitListProductCard,
  (prev, next) =>
    prev.product?.count === next.product?.count &&
    prev.product?.deficit === next.product?.deficit,
)
