import {
  categoriesKeys,
  selectCategoriesData,
  selectUserData,
  useCategories,
  usersKeys,
  useUser,
} from '@api'
import { queryClient } from '@api/queryClient'
import type { Category } from '@models/categories'
import type { Product, UseProductData } from '@models/products'
import type { User } from '@models/user'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { dehydrate, QueryClient, useQuery } from 'react-query'
import productsApi from './actions'
import { productsKeys } from './constants'

export const selectData = (data: Product): UseProductData => {
  const user = selectUserData(queryClient.getQueryData<User>(usersKeys.root)!)
  const categoties = selectCategoriesData(
    queryClient.getQueryData<Category[]>(categoriesKeys.all(user.coords))!,
  )

  const category = categoties.categoriesList.find(category =>
    category.subcategories?.find(subcategory =>
      subcategory?.products?.includes(data.id),
    ),
  )

  const subcategory = category?.subcategories.find(subcategory =>
    subcategory.products?.includes(data.id),
  )
  return { ...data, category, subcategory }
}

const useProduct = (productId: string) => {
  const userQuery = useUser()

  const categoriesQuery = useCategories()

  const query = useQuery<Product, AxiosError, UseProductData>(
    productsKeys.root(productId, userQuery.data?.coords),
    () => productsApi.getProduct(productId, userQuery.data?.coords),
    {
      staleTime: ms('30m'),
      select: selectData,
      enabled: userQuery.isSuccess && categoriesQuery.isSuccess,
    },
  )
  return query
}

export const prefetchProduct = async (
  queryClient: QueryClient,
  productId: string,
) => {
  await queryClient.prefetchQuery(productsKeys.root(productId), () =>
    productsApi.getProduct(productId),
  )

  return { dehydratedState: { dehydratedState: dehydrate(queryClient) } }
}

export default useProduct
