import { ms } from '@tools/common'
import {
  getRemoteConfig,
  isSupported as isRemoteConfigSupproted,
  RemoteConfig,
} from 'firebase/remote-config'
import firebaseApp from './firebaseClient'

let remoteConfig: RemoteConfig

const initializeRemoteConfig = () => {
  isRemoteConfigSupproted().then(supported => {
    if (supported) {
      remoteConfig = getRemoteConfig(firebaseApp)

      remoteConfig.settings.minimumFetchIntervalMillis = ms('5m')
    }
  })

  return remoteConfig
}

export default initializeRemoteConfig
