import { classnames, toFixed } from '@tools/common'
import type { RefObject } from 'react'
import Skeleton from 'react-loading-skeleton'

type Size = 'small' | 'large'

export interface PriceProps {
  value?: number | string
  sale?: number
  salePosition?: 'left' | 'right'
  size?: Size
  loading?: boolean
  priceRef?: RefObject<HTMLParagraphElement>
  valueClassName?: string
  saleClassName?: string
  className?: string
  smallCard?: boolean
}

const formatPrice = (value: number) => `${toFixed(value)} €`

const Price = ({
  value,
  sale,
  salePosition = 'right',
  size = 'large',
  loading,
  priceRef,
  valueClassName,
  saleClassName,
  className,
  smallCard,
}: PriceProps) => {
  return (
    <div className={classnames('relative flex items-end', className)}>
      {loading && (
        <Skeleton inline className="absolute inset-0 rounded-medium" />
      )}

      {!!sale && salePosition === 'left' && (
        <p
          className={classnames(
            'mr-1 text-grey line-through',
            {
              'text-base': size === 'large',
              'text-xs': size === 'small',
            },
            saleClassName,
          )}
        >
          {formatPrice(sale)}
        </p>
      )}

      <p
        ref={priceRef}
        className={classnames(
          {
            'text-xs font-semibold lg:text-base':
              size === 'large' && !smallCard,
            'text-xs font-semibold lg:text-xs': size === 'large' && smallCard,
            'text-sm font-semibold': size === 'small',
            'text-pink': sale,
            'text-ultra-dark-grey': !sale,
          },
          valueClassName,
        )}
      >
        {typeof value === 'number' ? formatPrice(value) : value}
      </p>

      {!!sale && salePosition === 'right' && (
        <p
          className={classnames(
            'ml-2 text-grey line-through',
            {
              'lg:font-base text-xs font-normal lg:text-base':
                size === 'large' && !smallCard,
              'text-xs font-normal lg:text-xs lg:font-normal':
                size === 'large' && smallCard,
              'text-xs': size === 'small',
            },
            saleClassName,
          )}
        >
          {formatPrice(sale)}
        </p>
      )}
    </div>
  )
}

export default Price
