import {
  categoriesKeys,
  productsKeys,
  selectCartData,
  selectCategoriesData,
  selectProductData,
  selectRecommendationsData,
  selectUserData,
  selectWarehousesData,
  usersKeys,
} from '@api'
import { queryClient } from '@api/queryClient'
import { cartKeys } from '@api/resources/cart/constants'
import { offersKeys } from '@api/resources/offers/constants'
import { ordersKeys } from '@api/resources/orders/constants'
import { salesKeys } from '@api/resources/sales/constants'
import { searchKeys } from '@api/resources/search/constants'
import { warehousesKeys } from '@api/resources/warehouses/constants'
import { CART_RECOMMENDATIONS } from '@constants/source'
import { FIRST_LAUNCH } from '@constants/storage'
import type { Cart } from '@models/cart'
import type { Category } from '@models/categories'
import type { Offer } from '@models/offers'
import type { OrderInfo } from '@models/orders'
import type { Product } from '@models/products'
import type { SearchResult } from '@models/search'
import type { User } from '@models/user'
import type { Warehouse } from '@models/warehouses'
import * as ls from 'local-storage'
import type { TFunction } from 'next-i18next'
import { CURRENCY } from './constants'
import type {
  AccountAddressClickProps,
  AccountLoginEventProps,
  AccountLoginFacebookProps,
  AccountLoginProps,
  AddPaymentInfoEventProps,
  AddPaymentInfoProps,
  AddProductToCartAlgoliaProps,
  AddProductToCartEventProps,
  AddProductToCartFacebookProps,
  AddProductToCartProps,
  CartUpdateEventProps,
  CartUpdateProps,
  CategoryClickAlgoliaProps,
  CategoryClickEventProps,
  CategoryClickProps,
  ChatCloseProps,
  ChatOpenProps,
  CheckoutClickEventProps,
  CheckoutClickFacebookProps,
  CheckoutClickProps,
  MainPageCompleteProps,
  OpenExternalLinkEventProps,
  OrderClickEventProps,
  OrderClickProps,
  OrdersClickEventProps,
  OrdersClickProps,
  ProductCardClickAlgoliaProps,
  ProductCardClickEventProps,
  ProductCardClickFacebookProps,
  ProductCardClickProps,
  PromocodeAppliedEventProps,
  PromocodeAppliedProps,
  PromocodeEnteredEventProps,
  PromocodeEnteredProps,
  RemoveProductFromCartEventProps,
  RemoveProductFromCartProps,
  SearchAvailableResultsEventProps,
  SearchAvailableResultsFacebookProps,
  SearchAvailableResultsProps,
  SearchEmptyEventProps,
  SearchEmptyProps,
  SessionStartProps,
  ShareReferralProps,
  SubcategoryClickEventProps,
  SubcategoryClickProps,
  UserAddressChangeEventProps,
  UserAddressChangeProps,
  UserLogoutEventProps,
  UserLogoutProps,
} from './models'
import {
  generateAwinUrl,
  getAlgoliaProducts,
  getCartActionName,
  getExternalLinkName,
  getRouteSourceByPath,
  getYesNo,
  handleError,
  track,
} from './tools'

const context = {
  user: () => selectUserData(queryClient.getQueryData<User>(usersKeys.root)!),
  warehouses: (t: TFunction) =>
    selectWarehousesData(
      queryClient.getQueryData<Warehouse[]>(warehousesKeys.all)!,
      t,
    ),
  categories: () =>
    selectCategoriesData(
      queryClient.getQueryData<Category[]>(
        categoriesKeys.all(context.user().coords),
      )!,
    ),
  categoryProducts: (categoryId: string) =>
    queryClient.getQueryData<Map<string, Product[]>>(
      productsKeys.categoryProducts(categoryId, context.user().coords),
    ),
  sales: () =>
    queryClient.getQueryData<Product[]>(salesKeys.root(context.user().coords))!,
  offers: () =>
    queryClient.getQueryData<Offer[]>(offersKeys.all(context.user().coords))!,
  cart: () => selectCartData(queryClient.getQueryData<Cart>(cartKeys.root)!),
  recommendations: () =>
    selectRecommendationsData(
      queryClient.getQueryData<Product[]>(cartKeys.recommendations)!,
    ),
  search: (query: string) =>
    queryClient.getQueryData<SearchResult>(
      searchKeys.root(query, context.user().coords),
    )!,
  order: (orderId: string) =>
    queryClient.getQueryData<OrderInfo>(ordersKeys.order(orderId))!,
  product: (productId: string) =>
    selectProductData(
      queryClient.getQueryData<Product>(
        productsKeys.root(productId, context.user().coords),
      )!,
    ),
}

// #region session
const sessionStart = () => {
  const firstLaunch = ls.get<boolean>(FIRST_LAUNCH) ?? false

  if (!firstLaunch) {
    ls.set<boolean>(FIRST_LAUNCH, true)
  }

  track<SessionStartProps>('Web - Session Start', { firstLaunch }, ['Mixpanel'])
}

const mainPageComplete = () => {
  const salesCount = context.sales().length
  const offersCount = context.offers().length
  const categoryCount = context.categories().categoriesList.length

  track<MainPageCompleteProps>(
    'Web - Main Page Completed',
    {
      CategoryCount: categoryCount,
      SalesSection: getYesNo(salesCount),
      OfferSection: getYesNo(offersCount),
      Error: getYesNo(!categoryCount),
    },
    ['Mixpanel'],
  )
}

const sleepingModalViewed = () => {
  track('Slepping Modal Viewed', {}, ['Mixpanel'])
}

const openExternalLink = ({ link }: OpenExternalLinkEventProps) => {
  track(getExternalLinkName(link), {}, ['Mixpanel'])
}
// #endregion

// #region categories
const categoryClick = ({ categoryId, t }: CategoryClickEventProps) => {
  const { name: CategoryName } = context
    .categories()
    .categoriesMap.get(categoryId)!
  const { length: SubcategoryCount } = context
    .categories()
    .categoriesListMap.get(categoryId)!.subcategories

  const { id: StoreID } = context.warehouses(t).activeWarehouse

  const categoryProduct = [...context.categoryProducts(categoryId)!.values()]

  const SKUCount = categoryProduct.reduce(
    (acc, products) => acc + products.length,
    0,
  )

  track<CategoryClickAlgoliaProps>(
    'Product List Viewed',
    {
      index: 'products',
      list_id: categoryId,
      category: CategoryName,
      products: getAlgoliaProducts(categoryProduct),
    },
    ['Algolia'],
  )

  track<CategoryClickProps>(
    'Catalogue - Category Click',
    {
      CategoryName,
      StoreID,
      SKUCount,
      SubcategoryCount,
    },
    ['Mixpanel'],
  )
}

const subcategoryClick = ({ subcategoryId, t }: SubcategoryClickEventProps) => {
  const { name: SubcategoryName, parent_id: categoryId } = context
    .categories()
    .categoriesMap.get(subcategoryId)!

  const { name: CategoryName } = context
    .categories()
    .categoriesMap.get(categoryId!)!

  const { id: StoreID } = context.warehouses(t).activeWarehouse

  const { length: SKUCount } = context
    .categoryProducts(categoryId!)!
    .get(subcategoryId)!

  track<SubcategoryClickProps>(
    'Catalogue - Subcategory Tab Click',
    {
      CategoryName,
      StoreID,
      SubcategoryName,
      SKUCount,
    },
    ['Mixpanel'],
  )
}
// #endregion

// #region search
const searchAvailableResults = ({
  query,
}: SearchAvailableResultsEventProps) => {
  const searchResult = context.search(query)

  track<SearchAvailableResultsFacebookProps>(
    'Search',
    { search_string: query },
    ['Facebook Pixel'],
  )

  track<SearchAvailableResultsProps>(
    'Search - Available Results',
    {
      CategoryСount: searchResult.categories.length,
      ProductsCount: searchResult.products.length,
      Query: query,
    },
    ['Mixpanel'],
  )
}

const searchEmpty = ({ query }: SearchEmptyEventProps) => {
  track<SearchEmptyProps>('Search - Product Not Found', { Query: query }, [
    'Mixpanel',
  ])
}
// #endregion

// #region cart
const checkoutClick = ({ t }: CheckoutClickEventProps) => {
  const { deliveryTime, activeWarehouse } = context.warehouses(t)
  const { productIds: cartProductIds, products, cart } = context.cart()
  const { productIds: recommendationsProductIds } = context.recommendations()

  track<CheckoutClickFacebookProps>(
    'InitiateCheckout',
    {
      content_ids: cartProductIds,
      contents: products.map(product => ({
        id: product.id,
        quantity: product.count,
      })),
      CURRENCY,
      num_items: products.length,
      value: cart.total.with_discount,
    },
    ['Facebook Pixel'],
  )

  track<CheckoutClickProps>(
    'Checkout- Click',
    {
      StoreID: activeWarehouse.id,
      CartPrice: cart.total.with_discount,
      Discount: cart.total.discount,
      CartProduct: products,
      DeliveryTime: deliveryTime ?? '',
      DeliveryFee: 0,
      HasRecommendations: getYesNo(
        products.some(product => product.source === CART_RECOMMENDATIONS),
      ),
      CartRecommendationsListed: recommendationsProductIds,
      CartRecommendationsAdded: products
        .filter(product => product.source === CART_RECOMMENDATIONS)
        .map(product => product.id),
    },
    ['Mixpanel'],
  )
}

const promocodeEntered = ({ promocode }: PromocodeEnteredEventProps) => {
  track<PromocodeEnteredProps>(
    'Promo code Entered',
    { PromocodeName: promocode },
    ['Mixpanel'],
  )
}

const promocodeApplied = ({ promocode }: PromocodeAppliedEventProps) => {
  track<PromocodeAppliedProps>(
    'Promo code Applied',
    { PromocodeName: promocode },
    ['Mixpanel'],
  )
}

const addProductToCart = ({
  productId,
  source,
}: AddProductToCartEventProps) => {
  const product = context.product(productId)

  const currentRecommendationIndex = context
    .recommendations()
    .productIds.indexOf(product.id)

  track<AddProductToCartAlgoliaProps>(
    'Product Added',
    {
      index: 'products',
      objectID: product.id,
      product_id: product.id,
      sku: product.EAN,
      category: product.category!.name,
      name: product.name,
      brand: product.brand,
      price: product.price.withsale,
      currency: CURRENCY,
      quantity: 1,
      image_url: product.image[0],
    },
    ['Algolia'],
  )

  track<AddProductToCartFacebookProps>(
    'AddToCart',
    {
      content_ids: [productId],
      content_name: product.name,
      content_type: product.brand,
      contents: [{ id: product.EAN, quantity: 1 }],
      currency: CURRENCY,
      value: product.price.withsale,
    },
    ['Facebook Pixel'],
  )

  track<AddProductToCartProps>(
    'Add to cart',
    {
      ItemName: product.name,
      BrandName: product.brand,
      Price: product.price.withsale,
      FullPrice: product.price.full,
      EAN: product.EAN,
      SubcategoryName: product.subcategory!.name,
      SubcategoryId: product.categoryId,
      CategoryName: product.category!.name,
      currency: CURRENCY,
      Source: source,
      items: [
        {
          item_id: productId,
          item_name: product.name,
          discount: product.price.sale
            ? Number((product.price.full - product.price.withsale).toFixed(2))
            : 0,
          item_brand: product.brand,
          item_category: product.category!.name,
          item_category2: product.subcategory!.name,
          item_list_id: product.category!.id,
          item_list_name: product.subcategory!.name,
          price: product.price.full,
          quantity: 1,
          currency: CURRENCY,
        },
      ],
      Discounted: getYesNo(product.price.sale),
      ...(source === CART_RECOMMENDATIONS &&
      currentRecommendationIndex &&
      currentRecommendationIndex >= 0
        ? { OfRecommendation: currentRecommendationIndex }
        : {}),
    },
    ['Mixpanel'],
  )
}

const removeProductFromCart = ({
  productId,
  source,
}: RemoveProductFromCartEventProps) => {
  const product = context.product(productId)

  track<RemoveProductFromCartProps>(
    'Remove from cart',
    {
      ItemName: product.name,
      BrandName: product.brand,
      Price: product.price.withsale,
      FullPrice: product.price.full,
      EAN: product.EAN,
      SubcategoryName: product.subcategory!.name,
      SubcategoryId: product.subcategory!.id,
      CategoryName: product.category!.name,
      currency: CURRENCY,
      items: [
        {
          item_id: productId,
          item_name: product.name,
          discount: product.price.sale
            ? Number((product.price.full - product.price.sale).toFixed(2))
            : 0,
          item_brand: product.brand,
          item_category: product.category!.name,
          item_category2: product.subcategory!.name,
          item_list_id: product.subcategory!.id,
          item_list_name: product.subcategory!.name,
          price: product.price.full,
          quantity: 1,
          currency: CURRENCY,
        },
      ],
      Discounted: getYesNo(product.price.sale),
      Source: getRouteSourceByPath(source),
      count: 1,
    },
    ['Mixpanel'],
  )
}

export const cartUpdated = ({ action }: CartUpdateEventProps) => {
  const { cart } = context.cart()
  const { anonymous } = context.user()

  track<CartUpdateProps>(
    'Cart - Cart Update',
    {
      CartPrice: cart.total.with_discount,
      CartSize: cart.products.length,
      IsAuthorized: getYesNo(!anonymous),
      Action: getCartActionName(action),
    },
    ['Mixpanel'],
  )
}
// #endregion

// #region orders
const ordersClick = ({ orderCount }: OrdersClickEventProps) => {
  track<OrdersClickProps>('Orders - Orders Click', { OrderCount: orderCount }, [
    'Mixpanel',
  ])
}

const orderClick = ({ orderId }: OrderClickEventProps) => {
  const { status, products, price } = context.order(orderId)

  track<OrderClickProps>(
    'Orders - Order Click',
    {
      OrderID: orderId,
      OrderStatus: status,
      OrderSize: products.length,
      OrderPrice: price.with_sale,
    },
    ['Mixpanel'],
  )
}

const reorderClick = () => {
  track('Orders - Reorder Click', {}, ['Mixpanel'])
}
// #endregion

// #region account
const authorizationClick = () => {
  track('Account - Authorization Click', {}, ['Mixpanel'])
}

const referralOpen = () => {
  track('Account - Referral', {}, ['Mixpanel'])
}

const accountLogin = ({
  isSuccess,
  newUser,
  userId,
}: AccountLoginEventProps) => {
  track<AccountLoginFacebookProps>(
    'CompleteRegistration',
    {
      status: isSuccess ? 'COMPLETE_REGISTRATION' : 'FAILED',
    },
    ['Facebook Pixel'],
  )

  track<AccountLoginProps>(
    'Account - Login',
    {
      UserID: userId,
      IsSuccess: getYesNo(isSuccess),
      NewUser: getYesNo(newUser),
    },
    ['Mixpanel'],
  )
}

const userLogout = ({ userId }: UserLogoutEventProps) => {
  track<UserLogoutProps>('Account - Logout', { UserID: userId }, ['Mixpanel'])
}

const accountAddressClick = () => {
  const { addresses } = context.user()

  track<AccountAddressClickProps>(
    'Account - My address Click',
    { AddressCount: addresses.length },
    ['Mixpanel'],
  )
}

const shareReferralCode = () => {
  const {
    id,
    promocode: { code },
  } = context.user()

  track<ShareReferralProps>(
    'Account - Referral share',
    { UserID: id, Promocode: code },
    ['Mixpanel'],
  )
}

const signUp = () => {
  track('Sign up', {}, ['Mixpanel'])
}
// #endregion

// #region support
const openHelp = () => {
  track('Support - Help', {}, ['Mixpanel'])
}

const openContactUs = () => {
  track('Support - Contact to Support', {}, ['Mixpanel'])
}

const chatOpen = ({ source, is_online, has_unread }: ChatOpenProps) => {
  track<ChatOpenProps>(
    'Support - Chat Opened',
    {
      source,
      is_online,
      has_unread,
    },
    ['Mixpanel'],
  )
}
const chatClose = ({ source, is_online, seconds_inside }: ChatCloseProps) => {
  track<ChatCloseProps>(
    'Support - Chat Closed',
    {
      source,
      is_online,
      seconds_inside,
    },
    ['Mixpanel'],
  )
}
// #endregion

// #region map
const mapClick = () => {
  track('Map - Map Click', {}, ['Mixpanel'])
}

const removeUserAddress = () => {
  track('Map - Address Removed', {}, ['Mixpanel'])
}

const userAddressChange = ({
  address,
  latitude,
  longitude,
}: UserAddressChangeEventProps) => {
  track<UserAddressChangeProps>(
    'Map - Address Changed',
    { Address: address, Latitude: latitude, Longitude: longitude },
    ['Mixpanel'],
  )
}
// #endregion

// #region payment
export const addPaymentInfo = ({ added }: AddPaymentInfoEventProps) => {
  track<AddPaymentInfoProps>(
    'AddPaymentInfo',
    {
      status: added ? 'ADDED' : 'FAILED',
    },
    ['Facebook Pixel'],
  )
}
// #endregion

// #region products
const productCardClick = ({
  productId,
  source,
}: ProductCardClickEventProps) => {
  const product = context.product(productId)

  const currentRecommendationIndex = context
    .recommendations()
    .productIds.indexOf(product.id)

  track<ProductCardClickAlgoliaProps>(
    'Product Viewed',
    {
      index: 'products',
      objectID: product.id,
      product_id: product.id,
      sku: product.EAN,
      category: product.category!.name,
      name: product.name,
      brand: product.brand,
      price: product.price.withsale,
      currency: CURRENCY,
      image_url: product.image[0],
    },
    ['Algolia'],
  )

  track<ProductCardClickAlgoliaProps>(
    'Product Clicked',
    {
      index: 'products',
      objectID: product.id,
      product_id: product.id,
      sku: product.EAN,
      category: product.category!.name,
      name: product.name,
      brand: product.brand,
      price: product.price.withsale,
      currency: CURRENCY,
      image_url: product.image[0],
    },
    ['Algolia'],
  )

  track<ProductCardClickFacebookProps>(
    'ViewContent',
    {
      content_ids: [productId],
      content_category: product.category!.name,
      content_name: product.name,
      contents: [product.EAN],
      currency: CURRENCY,
      value: product.price.withsale,
    },
    ['Facebook Pixel'],
  )

  track<ProductCardClickProps>(
    'Catalogue - Item Click',
    {
      ItemName: product.name,
      CategoryName: product.category!.name,
      SubcategoryName: product.subcategory!.name,
      Price: product.price.withsale,
      Discounted: getYesNo(product.price.sale),
      ProductEAN: product.EAN,
      BrandName: product.brand,
      currency: CURRENCY,
      Source: source,
      items: [
        {
          item_id: productId,
          item_name: product.name,
          discount: product.price.sale
            ? Number((product.price.full - product.price.sale).toFixed(2))
            : 0,
          item_brand: product.brand,
          item_category: product.category!.name,
          item_category2: product.subcategory!.name,
          item_list_id: product.subcategory!.id,
          item_list_name: product.subcategory!.name,
          price: product.price.full,
          currency: CURRENCY,
        },
      ],
      ...(source === CART_RECOMMENDATIONS
        ? { OfRecommendation: currentRecommendationIndex }
        : {}),
    },
    ['Mixpanel'],
  )
}
// #endregion

export default {
  sessionStart: handleError(sessionStart),
  mainPageComplete: handleError(mainPageComplete),
  categoryClick: handleError(categoryClick),
  subcategoryClick: handleError(subcategoryClick),
  productCardClick: handleError(productCardClick),
  addProductToCart: handleError(addProductToCart),
  removeProductFromCart: handleError(removeProductFromCart),
  searchAvailableResults: handleError(searchAvailableResults),
  checkoutClick: handleError(checkoutClick),
  ordersClick: handleError(ordersClick),
  orderClick: handleError(orderClick),
  reorderClick: handleError(reorderClick),
  accountAddressClick: handleError(accountAddressClick),
  authorizationClick: handleError(authorizationClick),
  accountLogin: handleError(accountLogin),
  referralOpen: handleError(referralOpen),
  shareReferralCode: handleError(shareReferralCode),
  userLogout: handleError(userLogout),
  mapClick: handleError(mapClick),
  userAddressChange: handleError(userAddressChange),
  removeUserAddress: handleError(removeUserAddress),
  openHelp: handleError(openHelp),
  chatOpen: handleError(chatOpen),
  chatClose: handleError(chatClose),
  openContactUs: handleError(openContactUs),
  searchEmpty: handleError(searchEmpty),
  promocodeEntered: handleError(promocodeEntered),
  promocodeApplied: handleError(promocodeApplied),
  sleepingModalViewed: handleError(sleepingModalViewed),
  openExternalLink: handleError(openExternalLink),
  cartUpdated: handleError(cartUpdated),
  generateAwinUrl: handleError(generateAwinUrl),
  addPaymentInfo: handleError(addPaymentInfo),
  signUp: handleError(signUp),
}
