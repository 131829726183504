import { useUser } from '@api'
import type { Product } from '@models/products'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import salesApi from './actions'
import { salesKeys } from './constants'

const useSales = () => {
  const userQuery = useUser()

  const query = useQuery<Product[], AxiosError>(
    salesKeys.root(userQuery.data?.coords),
    () => salesApi.getSalesProducts(userQuery.data?.coords),
    {
      enabled: userQuery.isSuccess,
      staleTime: ms('20m'),
    },
  )

  return query
}
export default useSales
