import { useCategories } from '@api'
import { classnames } from '@tools/common'
import { Loading } from '@uikit/organisms'
import { useRouter } from 'next/router'
import { ContextType, useEffect, useRef } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import Skeleton from 'react-loading-skeleton'
import { Sticky } from 'react-sticky'
import { CategoryItem, SubcategoryItem } from './components'

export interface CategoriesMobileNavListProps {
  className?: string
}

const CategoriesMobileNavList = ({
  className,
}: CategoriesMobileNavListProps) => {
  const apiRef = useRef({} as ContextType<typeof VisibilityContext>)

  const router = useRouter()

  const { categoryId } = router.query as { categoryId: string }

  const categoriesQuery = useCategories()

  const categorySection =
    categoriesQuery.data?.categoriesListMap.get(categoryId)

  useEffect(() => {
    if (categoriesQuery.isSuccess && apiRef.current) {
      apiRef.current.scrollToItem(
        apiRef.current.getItemById(categoryId),
        'smooth',
        'center',
      )
    }
  }, [categoriesQuery.data, categoryId, apiRef.current])

  return (
    <Sticky>
      {({ style }) => (
        <nav className="z-20 bg-white lg:hidden" style={style}>
          <Loading
            type="query"
            query={categoriesQuery}
            loader="skeleton"
            Skeleton={<CategoriesMobileNavListSkeleton className="mb-5" />}
            className={classnames('flex flex-col', className)}
          >
            {categoriesQuery => (
              <>
                <ScrollMenu
                  apiRef={apiRef}
                  wrapperClassName="z-30 shadow-md relative right-2.5 w-[calc(100%+20px)]"
                  scrollContainerClassName="scrollbar-hide"
                  itemClassName="px-4 first:pl-5 last:pr-5 rounded-medium"
                >
                  {
                    categoriesQuery.data.categoriesList.map(category => (
                      <CategoryItem
                        itemId={category.id}
                        key={category.id}
                        category={category}
                        active={category.id === categoryId}
                      />
                    )) as any
                  }
                </ScrollMenu>

                <ScrollMenu
                  wrapperClassName="py-2.5 z-20 relative right-2.5 w-[calc(100%+20px)] border-b border-ultra-light-grey"
                  scrollContainerClassName="scrollbar-hide px-3"
                  itemClassName="rounded-3xl"
                >
                  {
                    categorySection?.subcategories?.map(category => (
                      <SubcategoryItem
                        key={category.id}
                        itemId={category.id}
                        category={category}
                      />
                    )) as any
                  }
                </ScrollMenu>
              </>
            )}
          </Loading>
        </nav>
      )}
    </Sticky>
  )
}

export default CategoriesMobileNavList

interface CategoriesMobileNavListSkeletonProps {
  className?: string
}

export const CategoriesMobileNavListSkeleton = ({
  className,
}: CategoriesMobileNavListSkeletonProps) => {
  return (
    <div className={className}>
      <Skeleton inline className="h-7" />

      <Skeleton inline className="mt-2.5 h-10" />
    </div>
  )
}
