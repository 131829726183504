import { v1 } from '@constants/api'
import type { Coordinates } from '@models/map'
import { keyToUrl } from '@tools/common'

export const searchKeys = {
  root: (text?: string, coords?: Coordinates) => [
    v1,
    'search',
    text ?? '',
    coords ?? null,
  ],
}

export const searchUrls = {
  root: (text?: string) => keyToUrl(searchKeys.root(text)),
}

export const SEARCH_MIN_SYMBOLS = 2
