import { useCart } from '@api'
import { PrimaryButton } from '@uikit/molecules'
import { DeficitListProductCard, Toast } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import type { BaseModalProps } from '../components/BaseModal'
import BaseModal from '../components/BaseModal'

export interface DeficitModalProps extends BaseModalProps {}

const DeficitModal = ({ visible, onClose: onBaseClose }: DeficitModalProps) => {
  const { t } = useTranslation()

  const cartQuery = useCart()

  useEffect(() => {
    toast.custom(toast => (
      <Toast toast={toast} title={t('ui.messages.no_stock')} />
    ))
  }, [visible])

  const onClose = () => {
    cartQuery.mutations.updateCart.mutate({
      products: cartQuery.data!.filteredProducts,
    })

    onBaseClose()
  }

  return (
    <BaseModal
      {...{
        header: { withBorder: false },
        visible,
        onClose,
      }}
    >
      <div>
        <p className="p-5 pb-2 text-center text-2xl font-semibold text-ultra-dark-grey">
          {t('ui.modals.deficit.title')}
        </p>

        <div className="max-h-[48vh] overflow-y-scroll p-5">
          <ul>
            {cartQuery.data?.productsWithDeficit?.map(product => (
              <div className="border-b border-ultra-light-grey py-5 last:border-b-0">
                <DeficitListProductCard product={product} />
              </div>
            ))}
          </ul>
        </div>

        <div className="px-5 pb-5">
          <PrimaryButton
            title={t('ui.modals.deficit.button.title')}
            onClick={onClose}
            className="w-full"
          />
        </div>
      </div>
    </BaseModal>
  )
}

export default DeficitModal
