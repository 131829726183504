import type { TFunction } from 'next-i18next'
import { z } from 'zod'

export const PHONE_REGEXP: RegExp = /^\+*\({0,1}\d{1,4}\){0,1}[\d(-\s)./]*$/g

export default (t: TFunction) =>
  z.object({
    phone: z
      .string()
      .nonempty(t('ui.modals.login.form.validation.phone'))
      .min(15, { message: t('ui.modals.login.form.validation.phone') })
      .max(20, { message: t('ui.modals.login.form.validation.phone') }),
  })
