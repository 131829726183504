import { useMutation } from 'react-query'
import feebacksApi from './actions'
import { feedbackKeys } from './constants'

const useFeedback = () => {
  const sendOrdersFeedback = useMutation(
    feedbackKeys.ordersFeedback,
    feebacksApi.sendOrdersFeedback,
  )

  const sendZoneFeedback = useMutation(
    feedbackKeys.zonesFeedback,
    feebacksApi.sendZoneFeedback,
  )

  const sendMissingProductsFeedback = useMutation(
    feedbackKeys.missingProductsFeedback,
    feebacksApi.sendMissingProductsFeedback,
  )

  return {
    mutations: {
      sendOrdersFeedback,
      sendZoneFeedback,
      sendMissingProductsFeedback,
    },
  }
}

export default useFeedback
