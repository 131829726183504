import { add, dateFormatter, parseISO } from '@tools/date'
import { useEffect, useState } from 'react'
import useInterval from 'use-interval'
import { useWarehouses } from '../warehouses'
import useOrder from './useOrder'

type Config = {
  origin: google.maps.LatLngLiteral
  destination: google.maps.LatLngLiteral
  path: google.maps.LatLngLiteral[]
  delay: number
  stepsCount: number
  formattedEstimatedTime: string
  bounds: google.maps.LatLngBounds
} | null

const useMapPath = (orderId: string) => {
  const directionsService = new google.maps.DirectionsService()

  const orderQuery = useOrder(orderId)
  const warehousesQuery = useWarehouses()

  const [config, setConfig] = useState<Config>(null)

  const [step, setStep] = useState(0)

  const finished =
    config && (config.stepsCount === 0 || step >= config.stepsCount - 1)

  const direction = config
    ? config.path[step + 1]?.lng - config.path[step]?.lng >= 0
    : true

  useEffect(() => {
    if (!orderQuery.isSuccess || !warehousesQuery.isSuccess) {
      return
    }

    ;(async () => {
      const origin: google.maps.LatLngLiteral = {
        lat: warehousesQuery.data.warehousesMap.get(
          orderQuery.data.warehouse_id,
        )!.lat,
        lng: warehousesQuery.data.warehousesMap.get(
          orderQuery.data.warehouse_id,
        )!.long,
      }

      const destination: google.maps.LatLngLiteral = {
        lat: orderQuery.data.address.lat,
        lng: orderQuery.data.address.long,
      }

      const {
        routes: [route],
      } = await directionsService.route({
        origin,
        destination,
        // @ts-ignore
        travelMode: 'TWO_WHEELER',
      })

      let path = route.overview_path.map<google.maps.LatLngLiteral>(coord => ({
        lat: coord.lat(),
        lng: coord.lng(),
      }))

      const bounds = route.bounds

      const duration = route.legs[0].duration!.value * 1000 * 1.4

      const delay = Math.round(duration / path.length)

      const timeDiff =
        Date.now() - parseISO(orderQuery.data.order_status_updated_at).getTime()

      const finalDuration = duration - timeDiff

      const isOverdue = finalDuration <= 0

      const estimatedTime = isOverdue
        ? new Date()
        : add(parseISO(orderQuery.data.order_status_updated_at), {
            seconds: Math.round(duration / 1000),
          })

      const formattedEstimatedTime = dateFormatter.time(estimatedTime)

      path = isOverdue
        ? path.slice(-1)
        : path.slice(Math.round(timeDiff / delay), path.length)

      setConfig({
        origin,
        destination,
        path,
        delay,
        stepsCount: path.length,
        formattedEstimatedTime,
        bounds,
      })
    })()
  }, [orderQuery.data, warehousesQuery.data])

  useInterval(
    () => {
      setStep(prev => prev + 1)
    },
    !config || finished ? null : config.delay,
  )

  return { config, step, finished, direction }
}

export default useMapPath
