import type { FAQ } from '@models/faq'
import { ms } from '@tools/common'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import faqApi from './actions'
import { faqKeys } from './constants'

const useFAQ = () =>
  useQuery<FAQ[], AxiosError>(faqKeys.root, faqApi.getFAQs, {
    staleTime: ms('1h'),
  })

export default useFAQ
