import { useCategories } from '@api'
import { classnames } from '@tools/common'
import { Loading } from '@uikit/organisms'
import CategoryMenuCard from '@uikit/organisms/cards/CategoryMenuCard'
import { range } from 'lodash'
import { useTranslation } from 'next-i18next'
import Skeleton from 'react-loading-skeleton'

export interface CategoriesNavListProps {}

// eslint-disable-next-line no-empty-pattern
const CategoriesNavList = ({}: CategoriesNavListProps) => {
  const { t } = useTranslation()

  const categoriesQuery = useCategories()

  return (
    <aside className="top-0 mr-2 hidden max-h-screen overflow-x-hidden pb-5 scrollbar-hide hover:mr-1 hover:scrollbar-default lg:sticky lg:block">
      <nav>
        <ul>
          <Loading
            type="query"
            loader="skeleton"
            query={categoriesQuery}
            Skeleton={<CategoriesNavListSkeleton className="mt-2.5 lg:mt-5" />}
          >
            {categoriesQuery => (
              <>
                <h2 className="pt-5 pb-2.5 text-2xl font-bold text-ultra-dark-grey">
                  {t('pages.home.sections.categories')}
                </h2>

                {categoriesQuery.data.categoriesList.map(category => (
                  <CategoryMenuCard
                    key={category.id}
                    category={category}
                    className="pt-[7px] pb-[7px] last:pb-0"
                  />
                ))}
              </>
            )}
          </Loading>
        </ul>
      </nav>
    </aside>
  )
}

export default CategoriesNavList

interface CategoriesNavListSkeletonProps {
  className?: string
}

const CategoriesNavListSkeleton = ({
  className,
}: CategoriesNavListSkeletonProps) => {
  return (
    <div className={className}>
      <Skeleton inline className="mb-2.5 h-7 w-1/2 rounded-medium lg:mb-5" />

      {range(8).map((n, _, { length }) => (
        <div
          key={n}
          className={classnames('mb-5 flex items-center', {
            'mb-0': n === length - 1,
          })}
        >
          <Skeleton
            inline
            height={60}
            width={60}
            className="mr-5 rounded-medium"
          />

          <div className="flex flex-col">
            <Skeleton
              inline
              width={80}
              height={16}
              className="h-3 rounded-medium"
            />

            <Skeleton
              inline
              width={100}
              height={16}
              className="h-3 rounded-medium"
            />
          </div>
        </div>
      ))}
    </div>
  )
}
