import { classnames, range } from '@tools/common'
import { Spinner } from '@uikit/atoms'
import { createContext, FC, ReactElement, useContext, useState } from 'react'
import type { QueryObserverSuccessResult, UseQueryResult } from 'react-query'

interface QueryLoadingProps<TData, TError> {
  type: 'query'
  query: UseQueryResult<TData, TError>
  onTryAgain?: boolean
  children:
    | ((query: QueryObserverSuccessResult<TData, TError>) => any)
    | JSX.Element
}

interface CommonLoadingProps {
  type: 'common'
  children: ReactElement
}

interface AnimationLoadingProps {
  loader: 'animation'
  loading?: boolean
  loadingMessage?: string
  hideLoadingMessage?: boolean
  messageClassName?: string
  size?: string | number
  className?: string
}

interface SkeletonLoadingProps {
  loader: 'skeleton'
  loading?: boolean
  Skeleton: JSX.Element
  numberOfSkeletonItems?: number
  className?: string
}

type QuerySkeletonLoadingProps<TData, TError> = SkeletonLoadingProps &
  QueryLoadingProps<TData, TError>

interface CommonSkeletonLoadingProps
  extends SkeletonLoadingProps,
    CommonLoadingProps {
  loader: 'skeleton'
  loading?: boolean
  Skeleton: JSX.Element
  numberOfSkeletonItems?: number
  className?: string
}

type QueryAnimationLoadingProps<TData, TError> = AnimationLoadingProps &
  QueryLoadingProps<TData, TError>

interface CommonAnimationLoadingProps
  extends AnimationLoadingProps,
    CommonLoadingProps {}

export type LoadingProps<TData, TError> =
  | QuerySkeletonLoadingProps<TData, TError>
  | QueryAnimationLoadingProps<TData, TError>
  | CommonSkeletonLoadingProps
  | CommonAnimationLoadingProps

export interface LoadingContextProps {
  showLoading: boolean
  setShowLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const LoadingContext = createContext<LoadingContextProps>({
  showLoading: false,
  setShowLoading: () => {},
})

export const LoadingContextProvider: FC = ({ children }) => {
  const [showLoading, setShowLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{ showLoading, setShowLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export const useShowLoading = () => useContext(LoadingContext)

const SkeletonLoading = ({
  Skeleton,
  numberOfSkeletonItems,
}: SkeletonLoadingProps) => {
  return (
    <>
      {range(numberOfSkeletonItems ?? 1).map(n => (
        <div key={n}>{Skeleton}</div>
      ))}
    </>
  )
}

const AnimationLoading = ({
  size,
  hideLoadingMessage,
  loadingMessage,
  messageClassName,
  className,
}: AnimationLoadingProps) => {
  return (
    <div className={classnames('flex items-center justify-center', className)}>
      <Spinner size={size} />

      {!hideLoadingMessage && (
        <p className={messageClassName}>{loadingMessage}</p>
      )}
    </div>
  )
}

const Loading = <TData, TError>(props: LoadingProps<TData, TError>) => {
  const { showLoading } = useShowLoading()

  if (props.type === 'query') {
    if (props.loader === 'animation') {
      return props.query.isSuccess && !showLoading ? (
        typeof props.children === 'function' ? (
          props.children(props.query)
        ) : (
          props.children
        )
      ) : (
        <div className={props.className}>
          <AnimationLoading {...props} />
        </div>
      )
    }

    if (props.loader === 'skeleton') {
      return props.query.isSuccess && !showLoading ? (
        typeof props.children === 'function' ? (
          props.children(props.query)
        ) : (
          props.children
        )
      ) : (
        <div className={props.className}>
          <SkeletonLoading {...props} />
        </div>
      )
    }
  }

  if (props.type === 'common') {
    if (props.loader === 'animation') {
      return props.loading || showLoading ? (
        <div className={props.className}>
          <AnimationLoading {...props} />
        </div>
      ) : (
        props.children
      )
    }

    if (props.loader === 'skeleton') {
      return props.loading || showLoading ? (
        <div className={props.className}>
          <SkeletonLoading {...props} />
        </div>
      ) : (
        props.children
      )
    }
  }

  return null
}

export default Loading
