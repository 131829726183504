import type { SearchResult } from '@models/search'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { useUser } from '../users'
import searchApi from './actions'
import { searchKeys, SEARCH_MIN_SYMBOLS } from './constants'

export interface UseSearchData extends SearchResult {
  empty: boolean
}

const selectData = (data: SearchResult): UseSearchData => ({
  ...data,
  empty: data.categories.length === 0 && data.products.length === 0,
})

const useSearch = (text: string) => {
  const userQuery = useUser()

  const query = useQuery<SearchResult, AxiosError, UseSearchData>(
    searchKeys.root(text, userQuery.data?.coords),
    () => searchApi.getSearchResults(text, userQuery.data?.coords),
    {
      enabled:
        userQuery.isSuccess &&
        !!text.trim() &&
        text.trim().length > SEARCH_MIN_SYMBOLS,
      select: selectData,
      refetchOnWindowFocus: false,
    },
  )

  return query
}

export default useSearch
