import { useFAQ } from '@api'
import { InfoIcon } from '@assets/icons'
import { useAnalytics } from '@hooks'
import { classnames } from '@tools/common'
import { Disclosure } from '@uikit/molecules'
import { Loading } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import type { ModalHeaderProps } from '../components'
import BaseModal, { BaseModalProps } from '../components/BaseModal'

export interface FaqModalProps extends BaseModalProps {
  containerClassName?: string
}

const FaqModal = ({ visible, onClose }: FaqModalProps) => {
  const { t } = useTranslation()

  useAnalytics([], [], analytics => analytics.openHelp())

  const faqQuery = useFAQ()

  const header: Omit<ModalHeaderProps, 'onClose'> = {
    Icon: (
      <InfoIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
    ),
    title: t('ui.modals.faq.header'),
  }

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.faq.title'),
          description: t('ui.modals.faq.description'),
        },
        className: 'h-full lg:max-h-[68vh]',
        dialogClassName: 'h-full',
        containerClassName: 'md:max-w-4xl',
      }}
    >
      <Loading
        type="query"
        query={faqQuery}
        loader="animation"
        size={40}
        className="h-full lg:min-h-[68vh]"
      >
        {faqQuery => (
          <>
            {faqQuery.data.map((faq, index, { length }) => (
              <Disclosure
                key={faq.question}
                title={faq.question}
                subtitle={faq.answer}
                className={classnames({
                  'border-b border-b-ultra-light-grey': index !== length - 1,
                })}
              />
            ))}
          </>
        )}
      </Loading>
    </BaseModal>
  )
}

export default FaqModal
