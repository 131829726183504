import { LoadingAnimation } from '@assets/animations'
import { ArrowDownIcon } from '@assets/icons'
import { Menu, Transition } from '@headlessui/react'
import { default as classnames } from 'classnames'
import { FC, Fragment } from 'react'
import { useController } from 'react-hook-form'
import Lottie from 'react-lottie'
import type { BaseFieldProps } from '../BaseField'
import BaseField from '../BaseField'

const DropdownAnimation: FC = ({ children }) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {children}
    </Transition>
  )
}

export interface DropdownItemProp {
  Icon?: JSX.Element
  loading?: boolean
  value: string
  label: string
}

export interface DropdownProps extends BaseFieldProps {
  placeholder?: string
  Icon?: JSX.Element
  items: DropdownItemProp[]
  menuButtonClassName?: string
}

export interface DropdownItemProps extends Pick<DropdownProps, 'onChange'> {
  item: DropdownItemProp
}

export const DropdownItem = ({ item, onChange }: DropdownItemProps) => {
  const onClick = () => {
    onChange!(item.value)
  }

  return (
    <Menu.Item>
      <div
        onClick={onClick}
        className="flex flex-1 cursor-pointer items-center border-t border-white bg-ultra-light-grey p-5 text-ultra-dark-grey hover:bg-ultra-light-grey lg:border-ultra-light-grey lg:bg-white"
      >
        {item.loading && (
          <div className="mr-2.5 h-[20px] w-[20px]">
            <Lottie
              height={20}
              width={20}
              options={{
                loop: true,
                autoplay: true,
                animationData: LoadingAnimation,
              }}
            />
          </div>
        )}
        {item.Icon}

        <p
          className={classnames('text-base font-medium text-ultra-dark-grey', {
            'ml-2.5': item.Icon,
          })}
        >
          {item.label}
        </p>
      </div>
    </Menu.Item>
  )
}

const Dropdown = ({
  control,
  name,
  items,
  className,
  Icon,
  placeholder,
  menuButtonClassName,
  ...props
}: DropdownProps) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  })

  return (
    <BaseField {...props}>
      <Menu
        as="div"
        className={classnames('relative block text-left', className)}
      >
        {({ open }) => (
          <>
            <Menu.Button
              className={classnames(
                'dropdown w-full rounded-small border border-ultra-light-grey bg-white p-5 focus:outline-none',
                {
                  'rounded-b-none border-b-transparent': open,
                  'border-white': !open,
                  // 'border-red': !value && props.required,
                },
                menuButtonClassName,
              )}
            >
              {({ open }) => (
                <div className="flex items-center">
                  {value && items.find(item => item.value === value)?.loading && (
                    <div className="mr-2.5 h-[20px] w-[20px]">
                      <Lottie
                        height={20}
                        width={20}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: LoadingAnimation,
                        }}
                      />
                    </div>
                  )}
                  {value
                    ? items.find(item => item.value === value)?.Icon
                    : Icon}
                  <p
                    className={classnames(
                      'flex flex-1 text-base font-normal text-ultra-dark-grey  line-clamp-1',
                      { 'ml-2.5': Icon },
                    )}
                  >
                    {value
                      ? items.find(item => item.value === value)?.label
                      : placeholder}
                  </p>
                  <div
                    className={classnames(
                      'ml-2 duration-500',
                      open ? 'rotate-180' : 'rotate-0',
                    )}
                  >
                    <ArrowDownIcon className="h-1.5 w-3 stroke-ultra-dark-grey stroke-[1.5px]" />
                  </div>
                </div>
              )}
            </Menu.Button>

            <DropdownAnimation>
              <Menu.Items className="absolute right-0 max-h-[300px] w-full origin-top-right overflow-y-auto overscroll-contain rounded-b-small border border-t-0 border-ultra-light-grey  bg-white pb-0 shadow-xl  focus:outline-none">
                {items.map((item, index) => (
                  <DropdownItem key={index} item={item} onChange={onChange} />
                ))}
              </Menu.Items>
            </DropdownAnimation>
          </>
        )}
      </Menu>
    </BaseField>
  )
}

export default Dropdown
