import { useUser, useWarehouses } from '@api'
import { CartIcon, LocationIcon } from '@assets/icons'
import { Menu as BaseMenu } from '@headlessui/react'
import { classnames } from '@tools/common'
import { Label } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import {
  DropdownMenuItem,
  DropdownMenuItemProps,
  MenuAnimation,
} from '../DropdownMenu'
import { useModals } from '../modals'

interface DropdownAddressProps {
  className?: string
}

const DropdownAddress = ({ className }: DropdownAddressProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const { setModal } = useModals()

  const userQuery = useUser()
  const warehousesQuery = useWarehouses()

  const SELECT_ADDRESS_ITEMS: DropdownMenuItemProps[] = [
    {
      id: 'addresses',
      title: t('ui.modals.addresses.header'),
      icon: <LocationIcon className="h-4 w-4 stroke-ultra-dark-grey" />,
    },
    {
      id: 'deliveryTerms',
      title: t('ui.modals.delivery_terms.header'),
      icon: (
        <CartIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
      ),
    },
  ]

  const onClickItem = (name: string) => {
    // @ts-ignore
    setModal({ name, props: {} })
  }

  return (
    <BaseMenu as="div" className={classnames('h-full w-full ', className)}>
      {({ open }) => (
        <>
          <BaseMenu.Button as="div" className="h-full w-full">
            <Label
              {...{
                title: warehousesQuery.data!.deliveryTime,
                titleClassName: 'truncate',
                className: classnames(
                  'w-full h-full items-center justify-center rounded-medium border border-ultra-light-grey text-center lg:hidden max-h-[58px]',
                  { 'bg-ultra-light-grey': open },
                ),
                subtitle: userQuery.data!.selectedAddress!.name,
                secondaryTitle: warehousesQuery.data?.deliveryFeePrice,
                subtitleClassName:
                  'px-2 pb-0 w-full max-h-4 overflow-hidden text-xs break-all',
                secondaryTitleClassName: classnames('truncate', {
                  'hidden xm:inline': router.pathname !== '/',
                }),
              }}
            />
          </BaseMenu.Button>

          <MenuAnimation>
            <BaseMenu.Items
              as="ul"
              className={classnames(
                'absolute right-0 mt-2 origin-top-right overflow-hidden rounded-medium bg-ultra-light-grey shadow-lg focus:outline-none xs:w-[calc(100vw-88px)] xm:w-[calc(100vw-156px)] lg:w-56',
                {
                  'w-[calc(100vw-224px)] sm:w-[calc(100vw-224px)]':
                    router.asPath !== '/',
                },
              )}
            >
              {SELECT_ADDRESS_ITEMS.map((item, index) => (
                <DropdownMenuItem
                  key={item.id}
                  last={index === SELECT_ADDRESS_ITEMS.length - 1}
                  onClickItem={() => onClickItem(item.id)}
                  {...item}
                />
              ))}
            </BaseMenu.Items>
          </MenuAnimation>
        </>
      )}
    </BaseMenu>
  )
}

export default DropdownAddress
