import { useCart, usePromocodes } from '@api'
import { InfoIcon } from '@assets/icons'
import { classnames, toFixed } from '@tools/common'
import { Price } from '@uikit/molecules'
import { SectionPopover } from '@uikit/organisms'
import { useModals } from '@uikit/organisms/modals'
import classNames from 'classnames'
import { Trans, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useRef } from 'react'

interface PriceSectionProps {
  className?: string
}

const PriceSection = ({ className }: PriceSectionProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const { setModal } = useModals()
  const promocodesQuery = usePromocodes()
  const cartQuery = useCart()

  const getPromocodeSumDiffWithTotalSum = () => {
    const activePromocode = promocodesQuery.data?.activePromocode
    const cartSumWithoutDelivery = cartQuery.data?.cart.total.with_discount!
    const promocodeMinSum = activePromocode?.min_sum

    if (
      cartSumWithoutDelivery &&
      promocodeMinSum &&
      promocodeMinSum > cartSumWithoutDelivery
    ) {
      const diff = promocodeMinSum - cartSumWithoutDelivery
      return diff.toFixed(2).split('.').includes('00')
        ? diff.toFixed(0)
        : diff.toFixed(2)
    }
  }

  const getDiscount = () => {
    const activePromocode = promocodesQuery.data?.activePromocode
    let discount: string | undefined

    if (activePromocode?.discount_type === 'PERCENT') {
      discount = `${activePromocode.discount} %`
    }

    if (activePromocode?.discount_type === 'CASH') {
      discount = `${activePromocode.discount} €`
    }

    return discount
  }

  const priceRef = useRef<HTMLParagraphElement>(null)
  const saleLabelRef = useRef<HTMLParagraphElement>(null)

  const onClickInfo = () =>
    setModal({
      name: 'deliveryTerms',
      props: {},
    })

  console.log('cart', cartQuery.data!.cart.delivery_fee)

  return (
    <div className={classnames('my-5', className)}>
      <SectionPopover
        text={
          <Trans
            i18nKey="pages.cart.promocode.state"
            values={{
              difference: getPromocodeSumDiffWithTotalSum(),
              discount: getDiscount(),
            }}
            components={[
              <span className="font-bold" />,

              <span
                className={classNames('font-bold', {
                  'text-pink': true,
                })}
              />,
            ]}
          />
        }
        hidden={!getPromocodeSumDiffWithTotalSum()}
        className={classnames('w-[120px]', {
          'w-[150px]': router.locale?.includes('pt'),
        })}
        style={{ right: (saleLabelRef.current?.offsetWidth! ?? 30) + 20 }}
      >
        <div className="flex w-full items-center justify-between text-sm font-medium text-pink">
          <p>{t('pages.cart.labels.sale')}</p>

          <p ref={saleLabelRef}>{`${toFixed(
            cartQuery.data!.cart.total.discount,
          )} €`}</p>
        </div>
      </SectionPopover>

      <div className="my-5 flex items-center justify-between">
        <span className="flex items-center">
          <p className="mr-1 text-sm font-medium text-ultra-dark-grey">
            {t('pages.cart.labels.delivery')}
          </p>

          <InfoIcon
            className="cursor-pointer stroke-ultra-dark-grey"
            onClick={onClickInfo}
          />
        </span>

        <SectionPopover
          text={
            <Trans
              i18nKey="ui.modals.delivery_terms.next_step"
              values={{
                sum_to_next_level: `${
                  cartQuery.data!.cart.delivery_fee.level_conditions
                    ?.sum_to_next_level
                } €`,
                next_level_sum: `${
                  cartQuery.data!.cart.delivery_fee.level_conditions
                    ?.next_level_sum
                } €`,
              }}
              components={[
                <span className="font-bold" />,

                <span
                  className={classNames('font-bold', {
                    'text-pink': true,
                  })}
                />,
              ]}
            />
          }
          hidden={
            cartQuery.data!.cart.delivery_fee.level_conditions
              ?.next_level_sum === null
          }
          style={{
            right:
              priceRef.current && priceRef.current?.offsetWidth
                ? priceRef.current?.offsetWidth +
                  (cartQuery.data!.cart.delivery_fee.level_conditions
                    ?.previous_level_sum
                    ? 50
                    : 20)
                : 'auto',
          }}
        >
          <Price
            priceRef={priceRef}
            sale={
              cartQuery.data!.cart.delivery_fee.level_conditions
                ?.previous_level_sum
            }
            value={
              cartQuery.data!.cart.delivery_fee.sum ||
              cartQuery.data!.cart.delivery_fee.sum > 0
                ? `${cartQuery.data!.cart.delivery_fee.sum} €`
                : t('pages.cart.labels.free')
            }
            size="small"
            salePosition="left"
            className="flex justify-end"
            valueClassName="text-ultra-dark-grey font-medium"
            saleClassName="text-sm text-grey"
          />
        </SectionPopover>
      </div>

      <div className="flex items-center justify-between">
        <p className="text-sm font-medium text-ultra-dark-grey">
          {t('pages.cart.labels.total')}
        </p>

        <Price
          value={cartQuery.data!.cart.total.final}
          sale={
            cartQuery.data!.cart.total.discount > 0
              ? cartQuery.data!.cart.total.original
              : undefined
          }
          salePosition="left"
          size="small"
          saleClassName="text-sm text-grey"
          valueClassName="font-medium"
        />
      </div>
    </div>
  )
}

export default PriceSection
