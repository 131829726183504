import {
  useCart,
  usePayments,
  useRecommendations,
  usersKeys,
  useTimeslots,
  useUser,
  useWarehouses,
} from '@api'
import { queryClient } from '@api/queryClient'
import { formatComment } from '@api/resources/cart/tools'
import { CheckmarkIcon, LocationAddIcon } from '@assets/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAnalytics } from '@hooks'
import { classnames, getBaseUrl } from '@tools/common'
import {
  Alert,
  Dropdown,
  InlineOptionsSelector,
  Input,
  InputProps,
  PrimaryButton,
} from '@uikit/molecules'
import { DeliveryTimeCarousel } from '@uikit/organisms'
import { useModals } from '@uikit/organisms/modals'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useInterval from 'use-interval'
import CardForm from '../CardForm/index'
import MBWayForm from '../MBWayForm'
import getValidationScheme, { NIF_REGEX } from './validation'

export interface CheckoutFormValues {
  address: string
  apt: string
  floor: string
  entrance: string
  selectedAnswers: string[]
  comment: string
  deliveryTime: string
  nif: string
  paymentMethod: string
}

export interface CheckoutFormProps {
  className?: string
}

export const MAX_COMMENT_LENGTH = 200
export const MAX_ADDRESS_INFO_LENGTH = 10
export const MAX_ADDRESS_INFO_LENGTH_MOBILE = 30

const CheckoutForm = ({ className }: CheckoutFormProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const paymentsQuery = usePayments()

  const { setModal } = useModals()

  const userQuery = useUser()
  const cartQuery = useCart('checkout-form')
  const timeslotsQuery = useTimeslots()
  const warehousesQuery = useWarehouses()

  const recommendationsQuery = useRecommendations()

  const analytics = useAnalytics(
    [recommendationsQuery, cartQuery, warehousesQuery, userQuery],
    ['checkoutClick'],
  )

  useEffect(() => {
    setValue('address', userQuery.data!.selectedAddress?.name ?? '')
  }, [userQuery.data!.selected_address])

  const autoAnswers = [
    t('pages.checkout.autoAnswers.leaveAtDoor'),
    t('pages.checkout.autoAnswers.dontRing'),
    t('pages.checkout.autoAnswers.call'),
  ]

  useEffect(() => {
    if (cartQuery.data?.isEmpty && !userQuery.data?.anonymous) {
      router.replace('/')
    }
  }, [cartQuery.data, userQuery.data])

  const onCloseLoginModal = () => {
    router.replace('/')
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onSuccessLogin = () => {
    queryClient.invalidateQueries(usersKeys.root)
  }

  useEffect(() => {
    if (userQuery.data!.anonymous) {
      setModal({
        name: 'login',
        props: {
          onClose: onCloseLoginModal,
          onSuccess: onSuccessLogin,
        },
      })
    }
  }, [])

  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<CheckoutFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(getValidationScheme(t)),
    defaultValues: {
      address: userQuery.data?.selectedAddress?.name,
      apt: userQuery.data?.selectedAddress?.apartment ?? '',
      floor: userQuery.data?.selectedAddress?.floor ?? '',
      entrance: userQuery.data?.selectedAddress?.entrance ?? '',
      selectedAnswers: [],
      nif: userQuery.data?.nif ?? '',
      comment: userQuery.data?.selectedAddress?.comment ?? '',
      paymentMethod: '',
    },
  })

  const watchPaymentMethod = watch('paymentMethod')

  const watchDeliveryTime = watch('deliveryTime')

  useEffect(() => {
    if (watchPaymentMethod) {
      paymentsQuery.mutations.selectPaymentMethod.mutate({
        id: watchPaymentMethod,
        paymentsMethod: paymentsQuery.data?.paymentMethods,
      })
      trigger()
    }
  }, [watchPaymentMethod])

  useEffect(() => {
    if (timeslotsQuery.isSuccess && warehousesQuery.data?.warehouseClosed) {
      setValue('deliveryTime', timeslotsQuery.data.timeslots[0].id)
    }
  }, [timeslotsQuery.data])

  useEffect(() => {
    if (paymentsQuery.isSuccess && paymentsQuery.data.selectedPayment) {
      setValue('paymentMethod', paymentsQuery.data.selectedPayment)
    }
  }, [paymentsQuery.data])

  useInterval(
    () => {
      if (paymentsQuery.data && paymentsQuery.data.pendingPayment) {
        paymentsQuery.refetch()
      }
    },
    2000,
    !!paymentsQuery.data?.pendingPayment,
  )

  const onClickAddressInput = () => {
    setModal({ name: 'addresses', props: {} })
  }

  const onSubmit = handleSubmit(async data => {
    let paymentId = ''

    if (paymentsQuery.data && paymentsQuery.data.selectedPayment) {
      paymentId = paymentsQuery.data.selectedPayment
    }

    if (timeslotsQuery.isSuccess && warehousesQuery.data?.warehouseClosed) {
      await cartQuery.mutations.setTimeslot.mutateAsync(watchDeliveryTime)
    }

    await cartQuery.mutations.updateCart.mutateAsync({
      address: {
        id: userQuery.data!.selectedAddress?.id!,
        apt: data.apt,
        entrance: data.entrance,
        floor: data.floor,
      },
      nif: data.nif,
      comment: formatComment(
        timeslotsQuery.data?.timeslotsMap.get(watchDeliveryTime)?.time,
        [...data.selectedAnswers, data.comment],
      ),
      payment: paymentId,
    })

    analytics.checkoutClick({ t })

    cartQuery.mutations.checkout.mutate({
      redirect_url: `${getBaseUrl(router)}/orders`,
    })
  })

  const addressInfoFields: InputProps[] = [
    {
      name: 'apt',
      control,
      placeholder: t('pages.checkout.placeholders.apt'),
      maxLength: MAX_ADDRESS_INFO_LENGTH,
      className: 'lg:w-1/3 w-full',
    },
    {
      name: 'floor',
      control,
      placeholder: t('pages.checkout.placeholders.floor'),
      maxLength: MAX_ADDRESS_INFO_LENGTH,
      className: 'lg:w-1/3 w-full',
    },
    {
      name: 'entrance',
      control,
      placeholder: t('pages.checkout.placeholders.ent'),
      maxLength: MAX_ADDRESS_INFO_LENGTH,
      className: 'lg:w-1/3 w-full',
    },
  ]

  return (
    <div className="pb-10 lg:pt-5">
      <h2 className="mb-2.5 hidden text-lg font-semibold text-ultra-dark-grey lg:mb-5 lg:block lg:text-2xl lg:font-bold">
        {t('pages.checkout.title')}
      </h2>
      <div className="mx-5 border-t border-ultra-light-grey lg:border-0" />
      <div
        className={classnames(
          'relative rounded-b-medium border border-t-0 border-ultra-light-grey bg-white p-5 lg:rounded-medium lg:border-none lg:bg-ultra-light-grey lg:shadow-xl',
          className,
        )}
      >
        <form id="checkout-form">
          <Input
            name="address"
            control={control}
            label={t('pages.checkout.labels.address')}
            required
            placeholder={t('pages.checkout.placeholders.address')}
            Icon={
              <LocationAddIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
            }
            disabled
            onClick={onClickAddressInput}
            inputClassName="cursor-pointer"
            className="mt-7 mb-2.5 cursor-pointer rounded-xsmall bg-ultra-light-grey sm:mt-2.5 lg:bg-white"
          />

          <div className="hidden sm:mb-5 sm:flex lg:mb-10">
            {addressInfoFields.map((field, index) => (
              <div key={index} className={field.className}>
                <Input
                  {...field}
                  className={classnames(
                    'rounded-xsmall bg-ultra-light-grey lg:bg-white',
                    {
                      'ml-2.5': index !== 0,
                    },
                  )}
                />
              </div>
            ))}
          </div>

          <div className="mb-5 flex sm:hidden">
            <div className="w-full">
              <Input
                control={control}
                name="apt"
                placeholder={t('pages.checkout.placeholders.address_mob')}
                maxLength={MAX_ADDRESS_INFO_LENGTH_MOBILE}
                className="rounded-xsmall bg-ultra-light-grey lg:bg-white"
              />
            </div>
          </div>

          <div>
            <p className="mb-2.5 text-base font-bold lg:mb-5">
              {t('pages.checkout.labels.comment')}
            </p>

            <div className="mb-2.5 overflow-x-auto scrollbar-hide lg:scrollbar-default">
              <InlineOptionsSelector
                name="selectedAnswers"
                control={control}
                options={autoAnswers}
              />
            </div>
          </div>

          <Input
            name="comment"
            control={control}
            placeholder={t('pages.checkout.placeholders.comment')}
            maxLength={MAX_COMMENT_LENGTH}
            className="rounded-xsmall bg-ultra-light-grey lg:bg-white"
          />

          <div className="my-5 lg:my-10">
            <Input
              name="nif"
              control={control}
              label={t('pages.checkout.labels.nif')}
              placeholder={t('pages.checkout.placeholders.nif')}
              mask={NIF_REGEX}
              className="rounded-xsmall bg-ultra-light-grey lg:bg-white"
            />
          </div>

          {warehousesQuery.data?.warehouseClosed && (
            <>
              <div className="mb-5 lg:mb-10">
                <DeliveryTimeCarousel
                  label={t('pages.checkout.labels.delivery_time')}
                  required
                  control={control}
                />
              </div>

              <Alert
                title={t('pages.checkout.alert.title')}
                description={t('pages.checkout.alert.description')}
                titleClassName="text-2xl font-bold"
                className="mb-5"
              />
            </>
          )}

          <Dropdown
            name="paymentMethod"
            control={control}
            required
            label={t('pages.checkout.choose_payment_method.header')}
            placeholder={t('pages.checkout.placeholders.payment')}
            items={
              paymentsQuery.data?.paymentsOptions.filter(option => option) ?? []
            }
            className="z-10"
            menuButtonClassName="bg-ultra-light-grey lg:bg-white text-left"
          />
        </form>

        {watchPaymentMethod === 'Add new card' && <CardForm />}

        {watchPaymentMethod === 'Add MB WAY' && <MBWayForm />}

        {watchPaymentMethod !== 'Add MB WAY' &&
        watchPaymentMethod !== 'Add new card' ? (
          <PrimaryButton
            type="submit"
            form="checkout-form"
            title={t('pages.checkout.buttons.main')}
            Icon={<CheckmarkIcon />}
            iconPosition="right"
            disabled={
              cartQuery.data?.cart.products.length === 0 ||
              !isValid ||
              !cartQuery.data!.checkoutAvailable ||
              cartQuery.mutations.checkout.isLoading ||
              (!!paymentsQuery.data?.pendingPayment &&
                paymentsQuery.data?.selectedPayment ===
                  paymentsQuery.data?.pendingPayment)
            }
            loading={cartQuery.mutations.checkout.isLoading}
            onClick={onSubmit}
            className="mt-5 w-full bg-pink disabled:bg-light-pink"
          />
        ) : null}

        <p className="absolute top-10 left-5 font-medium sm:left-auto sm:right-5 sm:top-5">
          {t('pages.checkout.delivery_time', {
            minutes: warehousesQuery.data!.deliveryTime,
          })}
        </p>
      </div>
    </div>
  )
}

export default CheckoutForm
