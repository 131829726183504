import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import datetimeApi from './actions'
import { datetimeKeys } from './constants'

const useDatetime = () =>
  useQuery<{ now: string }, AxiosError>(
    datetimeKeys.now(),
    datetimeApi.getDateNow,
  )

export default useDatetime
