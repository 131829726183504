import { useWarehouses } from '@api'
import { CartIcon } from '@assets/icons'
import { classnames } from '@tools/common'
import { PrimaryButton } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import BaseModal, { BaseModalProps } from '../components/BaseModal'
import type { ModalHeaderProps } from '../components/Header'

export interface DeliveryTermsModalProps
  extends Omit<BaseModalProps, 'header'> {}

const DeliveryTermsModal = ({ visible, onClose }: DeliveryTermsModalProps) => {
  const { t } = useTranslation()

  const warehousesQuery = useWarehouses()

  const header: Omit<ModalHeaderProps, 'onClose'> = {
    Icon: (
      <CartIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
    ),
    title: t('ui.modals.delivery_terms.header'),
  }

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.delivery_terms.header'),
          description: t('ui.modals.delivery_terms.description'),
        },
      }}
    >
      <section className="border-b border-ultra-light-grey p-5">
        <h4 className="mb-2.5 text-sm text-ultra-dark-grey">
          {t('ui.modals.delivery_terms.sections.working_hours')}

          <span className="text-sm font-bold text-ultra-dark-grey">
            {`${
              warehousesQuery.data!.activeWarehouse
                .shopping_hours_start_time_title
            } - ${
              warehousesQuery.data!.activeWarehouse
                .shopping_hours_end_time_title
            }`}
          </span>
        </h4>

        <h4 className="text-sm text-ultra-dark-grey">
          {t('ui.modals.delivery_terms.sections.delivery_time.title')}

          <span className="text-sm font-bold text-ultra-dark-grey">
            {warehousesQuery.data!.deliveryTime?.includes('Closed')
              ? warehousesQuery.data!.deliveryTime
              : t('ui.modals.delivery_terms.sections.delivery_time.minutes', {
                  time: warehousesQuery.data!.deliveryTime?.replace('min', ''),
                })}
          </span>
        </h4>
      </section>

      <section className="border-b border-ultra-light-grey p-5">
        <h4 className="mb-5 text-sm text-ultra-dark-grey">
          {t('ui.modals.delivery_terms.sections.delivery_fee')}
        </h4>

        <>
          {warehousesQuery.data?.deliveryFeeByZone!.levels.length === 1 ? (
            <span className="flex justify-between">
              <p
                className={classnames('text-sm text-ultra-dark-grey', {
                  'font-bold text-pink':
                    warehousesQuery.data?.deliveryFeeByZone!.levels[0].sum ===
                    0,
                })}
              >
                {warehousesQuery.data?.deliveryFeeByZone!.levels[0].sum === 0
                  ? `${
                      warehousesQuery.data?.deliveryFeeByZone!.levels[0].sum
                    } € - ${t('ui.modals.delivery_terms.free')}`
                  : `${
                      warehousesQuery.data?.deliveryFeeByZone!.levels[0].sum
                    } €`}
              </p>
            </span>
          ) : (
            warehousesQuery.data?.deliveryFeeByZone!.levels.map(
              (level, index, { length }) => (
                <span
                  key={index}
                  className={classnames('flex justify-between', {
                    'mb-2.5': index !== length - 1,
                  })}
                >
                  <p className="text-sm font-bold text-ultra-dark-grey">
                    {level.label}
                  </p>

                  <p
                    className={classnames('text-sm text-ultra-dark-grey', {
                      'font-bold text-pink': level.sum === 0,
                    })}
                  >
                    {level.sum === 0
                      ? `${level.sum} € - ${t('ui.modals.delivery_terms.free')}`
                      : `${level.sum} €`}
                  </p>
                </span>
              ),
            )
          )}
        </>
      </section>

      {warehousesQuery.data?.deliveryFeeByZone!.description && (
        <section className="border-b border-ultra-light-grey p-5">
          <p className="text-sm text-ultra-dark-grey">
            {warehousesQuery.data?.deliveryFeeByZone!.description}
          </p>
        </section>
      )}

      <section className="p-5">
        <PrimaryButton
          title={t('ui.modals.delivery_terms.buttons.ok')}
          onClick={onClose}
          className="w-full"
        />
      </section>
    </BaseModal>
  )
}

export default DeliveryTermsModal
