import { EmptyBoxIllustration } from '@assets/illustrations'
import { TextButton } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

interface emptyOrdersProps {
  onClose: () => void
}

const EmptyOrders = ({ onClose }: emptyOrdersProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const onClickRedirectSales = () => {
    router.push('/sales')
    onClose()
  }

  return (
    <div className="flex flex-col items-center justify-center p-10">
      <EmptyBoxIllustration className="h-[113px] w-[100px]" />

      <p className="mt-5 mb-2.5 text-base font-semibold text-ultra-dark-grey">
        {t('ui.modals.orders.empty.title')}
      </p>

      <p className="mb-5 text-xs font-medium text-ultra-dark-grey">
        {t('ui.modals.orders.empty.subtitle')}
      </p>

      <TextButton
        className="font-bold hover:bg-transparent"
        title={t('ui.modals.orders.empty.buttonTitle')}
        onClick={onClickRedirectSales}
      />
    </div>
  )
}

export default EmptyOrders
