import type { TFunction } from 'next-i18next'
import { z } from 'zod'

export const MIN_LENGTH_CARD_NUMBER = 16
export const MAX_LENGTH_CARD_NUMBER = 20
export const MIN_CVV_LENGTH = 3
export const CVV_LENGTH = 4
export const DATE_LENGTH = 4

const trimString = (u: unknown) =>
  typeof u === 'string' ? u.trim().replace(/[^\d.]\s*/g, '') : u

export default (t: TFunction) =>
  z.object({
    number: z.preprocess(
      trimString,
      z
        .string()
        .max(MAX_LENGTH_CARD_NUMBER)
        .refine(val => val.length >= MIN_LENGTH_CARD_NUMBER, {
          message: t('pages.checkout.errors.valid_card', {
            number: MIN_LENGTH_CARD_NUMBER,
          }),
        }),
    ),
    expireMonthAndYear: z.preprocess(
      trimString,
      z.string().refine(val => val.length >= DATE_LENGTH, {
        message: t('pages.checkout.errors.valid_date'),
      }),
    ),
    cvv: z
      .string()
      .max(CVV_LENGTH)
      .refine(val => val.length >= MIN_CVV_LENGTH, {
        message: t('pages.checkout.errors.valid_cvv', {
          number: MIN_CVV_LENGTH,
        }),
      }),
  })
