import { v1 } from '@constants/api'
import { keyToUrl } from '@tools/common'

export const warehousesKeys = {
  all: [v1, 'warehouses'] as const,
  timeslot: (warehouseId: string) =>
    [...warehousesKeys.all, warehouseId, 'timeslots'] as const,
}

export const warehousesUrls = {
  all: keyToUrl(warehousesKeys.all),
  timeslot: (warehouseId: string) =>
    keyToUrl(warehousesKeys.timeslot(warehouseId)),
}
