import type { Order } from '@models/orders'
import { classnames } from '@tools/common'
import { dateFormatter } from '@tools/date'
import { EmojiStatus, Price } from '@uikit/molecules'
import { useTranslation } from 'next-i18next'

export interface CartOrderCardProps {
  order: Order
  onClick?: () => void
  className?: string
}

const CartOrderCard = ({
  order: { ordernumber, date, price, status },
  onClick,
  className,
}: CartOrderCardProps) => {
  const { t } = useTranslation()

  return (
    <div
      onClick={onClick}
      className={classnames('flex items-start justify-between p-5', className)}
    >
      <div className="flex min-w-fit flex-col pr-2.5">
        <p className="text-sm font-normal text-grey">
          {dateFormatter.fullDate(date)}
        </p>

        <p className="mt-2.5 mb-2.5 self-start text-base font-medium text-ultra-dark-grey">
          {t('ui.modals.order.title', {
            ordernumber,
          })}
        </p>

        <Price
          size="small"
          value={price}
          valueClassName="font-normal text-ultra-dark-grey"
        />
      </div>

      <div className="w-[100px] text-center">
        <EmojiStatus
          type="status"
          emojiType={status}
          animated={
            status === 'READY_TO_PICK' ||
            status === 'PICKING' ||
            status === 'PICKED' ||
            status === 'DELIVERING'
          }
        />
      </div>
    </div>
  )
}

export default CartOrderCard
