import {
  getAuthCredentials,
  setAuthCredentials,
} from '@api/resources/users/tools'
import { getLanguage } from '@i18n'
import type { AxiosInstance } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import usersApi from '../resources/users/actions'

export default (axios: AxiosInstance) => {
  axios.interceptors.request.use(config => {
    const { accessToken, isNewToken } = getAuthCredentials()

    if (accessToken && isNewToken) {
      config.headers!['X-Auth-Key'] = accessToken
    }

    config.headers!['Accept-Language'] = getLanguage()

    return config
  })

  createAuthRefreshInterceptor(
    axios,
    async failedRequest => {
      try {
        const { data } = await usersApi.getNewAccessToken()

        setAuthCredentials(data)

        failedRequest.response.config.headers['X-Auth-Key'] = data.access
      } catch {
        usersApi.logout()
      }
    },
    {
      statusCodes: [401, 403],
    },
  )
}
