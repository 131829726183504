import { usersApi } from '@api'
import { RequestError } from '@api/queryClient'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { useRouter } from 'next/router'
import { useQueryClient } from 'react-query'

const useQueryErrors = () => {
  const router = useRouter()

  const queryClient = useQueryClient()

  queryClient.setDefaultOptions({
    queries: {
      onError: error => {
        Sentry.captureException(error)

        if (axios.isAxiosError(error)) {
          if (
            error.response?.status === 403 ||
            error.response?.status === 401
          ) {
            usersApi.logout()

            return
          }

          router.replace(
            error.response?.status === 400 ? '/404' : '/500',
            router.asPath,
          )

          return
        }

        if (
          axios.isAxiosError(error) &&
          (error.response?.status === 403 || error.response?.status === 401)
        ) {
          return
        }

        if (error instanceof RequestError) {
          if (error.message.includes('IDBDatabase')) {
            return
          }

          router.replace(`/${error.code}`, router.asPath)
        }
      },
    },
  })
}

export default useQueryErrors
