import { useUser } from '@api'
import useFeedback from '@api/resources/feedback/useFeedback'
import { CantFindIllustration } from '@assets/illustrations'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAnalytics } from '@hooks'
import { Input, PrimaryButton } from '@uikit/molecules'
import { Toast } from '@uikit/organisms'
import { serverTimestamp } from 'firebase/firestore'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import getValidationScheme from './validation'

export interface EmptySearchResultProps {
  onSuccess?: () => void
}

const EmptySearchResult = ({ onSuccess }: EmptySearchResultProps) => {
  const { t } = useTranslation()

  const analytics = useAnalytics()

  const userQuery = useUser()

  const feebackQuery = useFeedback()

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ searchValue: string }>({
    mode: 'onChange',
    resolver: zodResolver(getValidationScheme()),
  })

  const onSubmit = handleSubmit(async data => {
    analytics.searchEmpty({ query: data.searchValue })

    if (userQuery.data) {
      await feebackQuery.mutations.sendMissingProductsFeedback.mutateAsync({
        date: serverTimestamp(),
        name: data.searchValue,
        phone: userQuery.data.phone!,
      })

      toast.custom(toast => (
        <Toast
          toast={toast}
          title={t('ui.search.missing_product_toast_title')}
          variant="success"
        />
      ))
    }

    if (onSuccess) {
      onSuccess()
    }
  })

  return (
    <div className="flex flex-col text-center">
      <div className="flex flex-col items-center">
        <CantFindIllustration className="h-36 w-40" />

        <p className="mb-2.5 mt-5 text-2xl font-semibold text-ultra-dark-grey">
          {t('ui.empty.title')}
        </p>

        <p className="text-base font-medium text-ultra-dark-grey">
          {t('ui.empty.subtitle')}
        </p>
      </div>

      <form id="empty-search-form">
        <Input
          name="searchValue"
          control={control}
          placeholder={t('ui.empty.input_title')}
          className="mb-2.5 mt-5 w-full border border-ultra-light-grey"
        />

        <PrimaryButton
          type="submit"
          title={t('ui.empty.button_title')}
          onClick={onSubmit}
          disabled={
            !isValid ||
            (isValid &&
              feebackQuery.mutations.sendMissingProductsFeedback.isLoading)
          }
          loading={feebackQuery.mutations.sendMissingProductsFeedback.isLoading}
          className="w-full"
        />
      </form>
    </div>
  )
}

export default EmptySearchResult
