import type { AuthData } from '@models/user'
import * as ls from 'local-storage'
import { v4 as uuid } from 'uuid'

const ACCESS_TOKEN_KEY = '@accesstoken'

const REFRESH_TOKEN_KEY = '@refreshtoken'

const CLIENT_TOKEN_KEY = '@clienttoken'

const IS_NEW_CLIENT_TOKEN = '@is_new_token'

export const getClientToken = () => {
  let clientToken = getAuthCredentials().clientToken

  if (!clientToken) {
    clientToken = uuid()
    ls.set<string>(CLIENT_TOKEN_KEY, clientToken)
  }

  return clientToken
}

export const getAuthCredentials = () => {
  const accessToken = ls.get<string>(ACCESS_TOKEN_KEY)
  const refreshToken = ls.get<string>(REFRESH_TOKEN_KEY)
  const clientToken = ls.get<string>(CLIENT_TOKEN_KEY)
  const isNewToken = ls.get<boolean>(IS_NEW_CLIENT_TOKEN)

  return { accessToken, refreshToken, clientToken, isNewToken }
}

export const setAuthCredentials = (data: AuthData) => {
  ls.set<string>(ACCESS_TOKEN_KEY, data.access)
  ls.set<string>(REFRESH_TOKEN_KEY, data.refresh)
  ls.set<boolean>(IS_NEW_CLIENT_TOKEN, true)
}

export const clearAuthCredentials = () => {
  ls.remove(ACCESS_TOKEN_KEY)
  ls.remove(REFRESH_TOKEN_KEY)
  ls.remove(CLIENT_TOKEN_KEY)
}

export const getAuthCredentialsExist = () => {
  return Object.values(getAuthCredentials()).every(Boolean)
}
