import { useCart, useProduct } from '@api'
import { getMaxCount } from '@api/resources/cart/tools'
import { CalendarIcon, CloseIcon } from '@assets/icons'
import { CART_RECOMMENDATIONS } from '@constants/source'
import { useAnalytics } from '@hooks'
import type { Product } from '@models/products'
import { getBaseUrlForBots } from '@tools/common'
import {
  AmountSelector,
  CircleButton,
  Label,
  Price,
  SaleTag,
} from '@uikit/molecules'
import { Loading } from '@uikit/organisms'
import ImageCarousel from '@uikit/organisms/carousels/ImageCarousel'
import { safeMetadata } from '@uikit/organisms/Metadata'
import { useTranslation } from 'next-i18next'
import { useContextualRouting } from 'next-use-contextual-routing'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'
import type { BaseModalProps } from '../components'
import { BaseModal } from '../components'
import { getFirstDayOfMonth, getLastDayOfMonth } from '@tools/date'

export interface ProductInfoModalProps extends BaseModalProps {
  id: string
  recommendations?: boolean
}

const ProductInfoModal = ({
  visible,
  onClose,
  id,
  recommendations = false,
}: ProductInfoModalProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const cartQuery = useCart()
  const productQuery = useProduct(id)

  useAnalytics([productQuery], [], analytics =>
    analytics.productCardClick({
      productId: productQuery.data!.id,
      source: recommendations ? CART_RECOMMENDATIONS : returnHref,
    }),
  )

  const { returnHref } = useContextualRouting()

  const [imageLoaded, setImageLoaded] = useState(false)

  const onImageLoad = () => setImageLoaded(true)

  const getImageSize = (product: Product) => {
    if (imageLoaded) {
      return product.media.medium
    }

    return product.media.small
  }

  const onAdd = () => {
    cartQuery.mutations.updateProduct.mutate({
      id: productQuery.data!.id,
      action: 'add',
      source: recommendations ? CART_RECOMMENDATIONS : returnHref,
      recommendations,
    })
  }

  const onSub = () => {
    cartQuery.mutations.updateProduct.mutate({
      id: productQuery.data!.id,
      action: 'sub',
    })
  }

  const cartProduct =
    productQuery.isSuccess && cartQuery.isSuccess
      ? cartQuery.data.productsMap.get(productQuery.data.id)
      : undefined

  return (
    <BaseModal
      {...{
        visible,
        onClose,
        metadata: safeMetadata(
          productQuery,
          productQuery => ({
            title: t('ui.modals.product.header', {
              product: productQuery.data.name,
              price: productQuery.data.price.withsale,
            }),
            description: t('ui.modals.product.description', {
              product: productQuery.data.name,
              price: productQuery.data.price.withsale,
            }),
            image: productQuery.data.media.small,
            alt: productQuery.data.name,
            url: getBaseUrlForBots(router),
          }),
          t,
        ),
        className: 'lg:w-full overflow-y-auto max-h-[84vh]',
        containerClassName: 'md:max-w-md overflow-hidden',
      }}
    >
      <Loading
        type="query"
        query={productQuery}
        loader="animation"
        size={40}
        className="min-h-[65vh]"
      >
        {productQuery => (
          <>
            <div className="relative flex justify-center overflow-hidden rounded-medium bg-image-grey">
              {productQuery.data.image.length === 1 ? (
                <Image
                  height={290}
                  width={290}
                  objectFit="contain"
                  src={getImageSize(productQuery.data)}
                  alt={productQuery.data.name}
                  loader={({ src }) => src}
                  onLoad={onImageLoad}
                  priority
                />
              ) : (
                <ImageCarousel
                  isMultipleProductImages
                  slides={[
                    {
                      name: productQuery.data.name,
                      images: productQuery.data.image,
                    },
                  ]}
                  onImageLoad={onImageLoad}
                />
              )}
            </div>

            {productQuery.data.price.sale !== 0 && (
              <SaleTag
                title={`-${productQuery.data.price.sale}%`}
                size="large"
                className="absolute left-0 top-0"
              />
            )}

            <CircleButton
              onClick={onClose}
              Icon={<CloseIcon className="h-2.5 w-2.5 stroke-white" />}
              className="absolute right-2 top-2 focus:outline-none"
            />

            <div className="flex flex-col px-5 pb-24 pt-5">
              <Price
                value={productQuery.data.price.withsale}
                sale={
                  productQuery.data.price.sale
                    ? productQuery.data.price.full
                    : undefined
                }
                valueClassName="text-2xl"
                saleClassName="text-base"
              />

              {productQuery.data.price.sale !== 0 && (
                <div className="mt-1 mb-1 flex flex-row items-center">
                  <CalendarIcon className="fill-grey stroke-grey" />
                  <Label
                    subtitle={t('ui.modals.product.discount_effect', {
                      startDate: getFirstDayOfMonth(),
                      endDate: getLastDayOfMonth(),
                    })}
                    subtitleClassName="ml-2 mb-0 text-left"
                  />
                </div>
              )}

              <Label
                title={productQuery.data.name}
                subtitleClassName="mt-1 text-left"
                titleClassName="mt-1 text-left"
              />

              <div className="absolute bottom-0 left-0 right-0 h-24 bg-white" />

              <AmountSelector
                value={cartProduct?.count}
                inline
                onAdd={onAdd}
                onSub={onSub}
                max={getMaxCount(productQuery.data, cartProduct)}
                className="absolute bottom-5 mt-5 h-14 w-[calc(100%-2.5rem)] shadow"
                buttonsClassName="h-14"
              />

              <div className="mt-5 rounded-medium bg-white px-5">
                {productQuery.data.description?.trim().length! > 0 && (
                  <Label
                    title={t('ui.modals.product.titles.description')}
                    subtitle={productQuery.data.description}
                    subtitleClassName="mt-1 font-normal"
                    className="pb-2.5 text-left text-sm md:text-base"
                  />
                )}

                {productQuery.data.composition?.trim().length! > 0 && (
                  <Label
                    title={t('ui.modals.product.titles.composition')}
                    subtitle={productQuery.data.composition}
                    subtitleClassName="mt-1 font-normal"
                    className="border-x border-b-ultra-light-grey py-2.5 text-left text-sm md:text-base"
                  />
                )}

                {productQuery.data.calories && (
                  <section className="mb-5">
                    <Label
                      title={t('ui.modals.product.titles.calories')}
                      titleClassName="mb-1 pt-[10px] text-left text-sm md:text-base"
                    />

                    <div className="mb-[10px] flex border border-ultra-light-grey">
                      <div className="w-1/4 border-r border-r-ultra-light-grey p-2.5">
                        <p className="text-sm font-normal text-grey">
                          {t('ui.modals.product.titles.kcal')}
                        </p>

                        <p className="text-xs font-semibold text-ultra-dark-grey md:text-sm">
                          {productQuery.data.calories?.kcal}
                        </p>
                      </div>

                      <div className="w-1/4 border-r border-r-ultra-light-grey p-2.5">
                        <p className="text-sm font-normal text-grey">
                          {t('ui.modals.product.titles.prot')}
                        </p>

                        <p className="text-xs font-semibold text-ultra-dark-grey md:text-sm">
                          {productQuery.data.calories?.prot}
                        </p>
                      </div>

                      <div className="w-1/4 border-r border-r-ultra-light-grey p-2.5">
                        <p className="text-sm font-normal text-grey">
                          {t('ui.modals.product.titles.fat')}
                        </p>

                        <p className="text-xs font-semibold text-ultra-dark-grey md:text-sm">
                          {productQuery.data.calories?.fat}
                        </p>
                      </div>

                      <div className="w-1/4 p-2.5">
                        <p className="text-sm font-normal text-grey">
                          {t('ui.modals.product.titles.carb')}
                        </p>

                        <p className="text-xs font-semibold text-ultra-dark-grey md:text-sm">
                          {productQuery.data.calories?.carb}
                        </p>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </>
        )}
      </Loading>
    </BaseModal>
  )
}

export default ProductInfoModal
