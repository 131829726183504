import { useUser } from '@api'
import { CheckmarkIcon, CopyIcon, TicketIcon } from '@assets/icons'
import { PromoIllustration } from '@assets/illustrations'
import { useAnalytics } from '@hooks'
import { PrimaryButton } from '@uikit/molecules'
import { Loading } from '@uikit/organisms'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import type { ModalHeaderProps } from '../components'
import type { BaseModalProps } from '../components/BaseModal'
import BaseModal from '../components/BaseModal'

export interface PromoCodeModalProps extends BaseModalProps {}

const PromoCodeModal = ({ visible, onClose }: PromoCodeModalProps) => {
  const { t } = useTranslation()

  const [copied, setCopied] = useState<boolean>(false)

  const userQuery = useUser()

  useAnalytics([], [], analytics => {
    analytics.referralOpen()
  })

  const analytics = useAnalytics([userQuery], ['shareReferralCode'])

  const header: Omit<ModalHeaderProps, 'onClose'> = {
    Icon: (
      <TicketIcon className="h-4 w-4 stroke-ultra-dark-grey stroke-[1.5px]" />
    ),
    title: t('ui.modals.promocode.header'),
  }

  const onCopyToClipboard = () => {
    setCopied(true)
    analytics.shareReferralCode()
    setTimeout(() => setCopied(false), 5000)
  }

  const getIcon = () => {
    if (copied) {
      return <CheckmarkIcon className="h-4 w-4 fill-white" />
    }

    return <CopyIcon className="h-4 w-4 fill-white stroke-2" />
  }

  return (
    <BaseModal
      {...{
        header,
        visible,
        onClose,
        metadata: {
          title: t('ui.modals.promocode.title'),
          description: userQuery.isSuccess
            ? t('ui.modals.promocode.description', {
                promocode: userQuery.data.promocode.code,
              })
            : t('ui.messages.loading'),
        },
      }}
    >
      <Loading
        type="query"
        query={userQuery}
        loader="animation"
        size={40}
        className="min-h-[59vh]"
      >
        {userQuery => (
          <div className="flex flex-col items-center p-10">
            <PromoIllustration className="h-[105px] w-[100px]" />

            <p className="pt-5 pb-2.5 text-base font-semibold text-ultra-dark-grey">
              {t('ui.modals.promocode.title')}
            </p>

            <p className="text-center text-xs font-medium text-ultra-dark-grey">
              {t('ui.modals.promocode.about_promo')}
            </p>

            <p className="py-5 text-2xl font-bold text-ultra-dark-grey">
              {userQuery.data!.promocode.code}
            </p>

            <CopyToClipboard
              onCopy={onCopyToClipboard}
              text={userQuery.data!.promocode.sharemessage}
            >
              <PrimaryButton
                title={t('ui.modals.promocode.button')}
                size="large"
                disabled={copied}
                Icon={getIcon()}
                iconPosition="right"
                titleClassName="text-white font-semibold text-base"
                className="w-full transition duration-300"
              />
            </CopyToClipboard>
          </div>
        )}
      </Loading>
    </BaseModal>
  )
}

export default PromoCodeModal
