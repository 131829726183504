import type { TFunction } from 'next-i18next'
import { z } from 'zod'
import {
  threeNumbersMask,
  twoNumbersMask,
} from './../../forms/CheckoutForm/validation'

export const PHONE_REGEXP: RegExp =
  /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{3}(\s|-?))(\s?(([Ee|]xt[.:|]?)|x|X)(\s?\d+))?/g

export const PHONE_MASK_REGEXP: (RegExp | string)[] = [
  '+',
  ...threeNumbersMask,
  ' ',
  ...threeNumbersMask,
  ' ',
  ...threeNumbersMask,
  ' ',
  ...threeNumbersMask,
  ' ',
  ...threeNumbersMask,
  ' ',
  ...twoNumbersMask,
]

export default (t: TFunction, currentStep: number) =>
  z.object({
    phone: z.string({
      required_error: t('ui.modals.login.form.validation.phone'),
    }),

    ...(currentStep === 0 && {
      confirm: z.boolean(),
    }),

    ...(currentStep === 1 && {
      code: z
        .string({
          required_error: t('ui.modals.login.form.validation.code'),
        })
        .min(5, { message: t('ui.modals.login.form.validation.code') }),
    }),
  })
