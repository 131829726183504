import { useOrders, useRemoteConfig, useUser, useWarehouses } from '@api'
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

export interface IntercomContextProps {
  countUnreadMessages: number
  savedSettings: Intercom_.IntercomSettings
  enabled: boolean
  available: boolean
  showed: boolean
  setShowed: Dispatch<SetStateAction<boolean>>
}

const IntercomContext = createContext<IntercomContextProps>({
  countUnreadMessages: 0,
  savedSettings: {},
  enabled: false,
  available: false,
  showed: false,
  setShowed: () => {},
})

const MAX_ORDERS_ON_ATTRIBUTES: Readonly<number> = 5

export const IntercomContextProvider: FC = ({ children }) => {
  const userQuery = useUser()
  const warehousesQuery = useWarehouses()
  const ordersQuery = useOrders()
  const remoteConfigQuery = useRemoteConfig()

  const [available, setAvailable] = useState(false)
  const [countUnreadMessages, setCountUnreadMessages] = useState(0)
  const [savedSettings, setSavedSettings] =
    useState<Intercom_.IntercomSettings>({})
  const [showed, setShowed] = useState(false)

  useEffect(() => {
    window.Intercom('onUnreadCountChange', count =>
      setCountUnreadMessages(count),
    )
  }, [])

  useEffect(() => {
    if (userQuery.isSuccess) {
      const settings: Intercom_.IntercomSettings = {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        user_id: userQuery.data.phone ?? userQuery.data.id,
        name: userQuery.data.phone ?? userQuery.data.name,
        phone: userQuery.data.phone ?? undefined,
        address: userQuery.data.selectedAddress?.name,
        warehouse: warehousesQuery.data?.activeWarehouse?.name,
        orders: (ordersQuery.data ?? [])
          .slice(0, MAX_ORDERS_ON_ATTRIBUTES)
          .map(
            order =>
              `№${order.ordernumber} - ${order.status} - ${order.price}€`,
          )
          .join(' | '),
      }

      setSavedSettings(settings)
    }
  }, [userQuery.data])

  useEffect(() => {
    window.Intercom('shutdown')

    setShowed(false)
  }, [userQuery.data?.phone])

  useEffect(
    () =>
      setAvailable(!!warehousesQuery.data?.activeWarehouse.use_support_chat),
    [warehousesQuery.data?.activeWarehouse],
  )

  return (
    <IntercomContext.Provider
      value={{
        countUnreadMessages,
        savedSettings,
        enabled: !!remoteConfigQuery.data?.chatSupportEnabled,
        available,
        showed,
        setShowed,
      }}
    >
      {children}
    </IntercomContext.Provider>
  )
}

const hide = () => window.Intercom('hide')

const useIntercom = () => {
  const {
    countUnreadMessages,
    savedSettings,
    enabled,
    available,
    showed,
    setShowed,
  } = useContext(IntercomContext)

  const show = (customSettings?: Intercom_.IntercomSettings) => {
    if (customSettings) {
      window.Intercom('boot', {
        ...savedSettings,
        ...customSettings,
      })
    }

    if (!showed) {
      window.Intercom('boot', savedSettings)

      setShowed(true)
    }

    window.Intercom('show')
    window.Intercom('update')
  }

  const actions = {
    show,
    hide,
  }

  return {
    countUnreadMessages,
    enabled,
    available,
    actions,
    showed,
  }
}

export default useIntercom
